// Third party libraries
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useRouteMatch
} from 'react-router-dom';
// Components
/* import { DepartmentEdit } from '../Components/department-edit/Department-edit';
import { DepartmentForm } from '../Components/department-form/Department-form';
import { DepartmentsMain } from '../Components/departments-main/Departments-main'; */
import { Footer } from '../Components/footer/Footer';
import { Header } from '../Components/header/Header';
import { PageHeader } from '../Components/page-header/Page-header';
/* import { PageMenu } from '../Components/page-menu/Page-menu'; */
import { SecondaryMenu } from '../Components/secondary-menu/Secondary-menu';
// Functions for work with state
import { getUserRole, checkAuth } from '../clientStore/authSlice/auth-slice';
//Utils functions
import { IconNames } from '../util/const';
/* import { PrivateRoute } from '../util/private-route'; */
import { getSectionNames } from '../util/utils';
import { useLocation } from 'react-router-dom';
import { DepartmentsTitle } from '../Components/departments-title/Departments-title';


export const InventorySettings = ({ userName }) => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const userRole = useSelector(getUserRole);
  const SectionNames = getSectionNames(userRole);

  const location = useLocation();
  const { type } = location.state || 'hourly';

  const SecondarySectionNames = {
    roomsAreas: 'ROOMS & AREAS',
    furniture: 'FURNITURE',
  };

  const settingsHeaderBtn = [
    /*  {
       name: 'Change password'
     } */
  ];

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(checkAuth());
    }
  }, [dispatch]);

  return (
    <div className='container'>
      <Header
        isLoginBtnShow={false}
      />
      <main className='main'>
        <PageHeader
          titleIcon={IconNames.USER}
          title={userName}
          btns={settingsHeaderBtn}
          menuItems={SectionNames}
        />

        {/* <PageMenu
          menuItems={SectionNames}
        /> */}
        <SecondaryMenu
          menuItems={SecondarySectionNames}
          type={type}
        />

      </main>
      {/* <Footer /> */}
    </div>
  );
};