// Third party libraries
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch
} from 'react-router-dom';
// Components
import { TemplateForm } from '../Components/template-form/Template-form';
import { TemplateEdit } from '../Components/template-edit/Template-edit';
import { TemplatesMain } from '../Components/templates-main/Templates-main';
import { Footer } from '../Components/footer/Footer';
import { Header } from '../Components/header/Header';
import { PageHeader } from '../Components/page-header/Page-header';
import { PageMenu } from '../Components/page-menu/Page-menu';
// Functions for work with state
import { getUserRole, checkAuth } from '../clientStore/authSlice/auth-slice';
//Utils functions
import { IconNames } from '../util/const';
import { PrivateRoute } from '../util/private-route';
import { getSectionNames } from '../util/utils';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { setDepartmentsLoadingStatus } from '../clientStore/departmentsSlice/departments-slice';


export const Templates = ({ userName }) => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const userRole = useSelector(getUserRole);
  const SectionNames = getSectionNames(userRole);
  const [pageTitle, setPageTitle] = useState('Template');
  const location = useLocation();

  const { type } = location.state || { type: 'hourly' };
  const settingsHeaderBtn = [
    /*     {
          name: 'Save'
        },
        {
          name: 'Reset form'
        } */
  ];

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(checkAuth());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(setDepartmentsLoadingStatus({ status: 'idle' }));
  })

  return (
    <div className='container'>
      <Header
        isLoginBtnShow={false}
      />
      <main className='main'>
        <PageHeader
          titleIcon={IconNames.CALCULATIONS}
          title={pageTitle}
          menuItems={SectionNames}
        />
        <Switch>
          <Route exact path={path}>
            <TemplatesMain type={type} />
          </Route>
          <PrivateRoute path={`${path}/:templateId`}>
            <TemplateEdit updatePageTitle={setPageTitle} type={type} />
          </PrivateRoute>
        </Switch>
      </main>
      {/*  <Footer /> */}
    </div>
  );
};