export const roomsAreasDataModel = {
  authorId: '',
  companyId: '',
  rooms: [/* {
      roomName0: ''
    }*/],
  bold: false,
  italic: false,
  underline: false,
  insertLineBreakAN: false,
};  
