import { getSocket } from "../context/socket";

export const isFurnitureFormValid = (formValue) => {
    let isFurnitureFormValid = true;
    const uniqueItemNames = new Set();
    let hasDuplicates = false;

    formValue.items.forEach((item, index) => {
        if (uniqueItemNames.has(item[`itemName${index}`])) {
            // Duplicate itemName found
            alert(`Duplicate itemName found at index ${index}: ${item[`itemName${index}`]}`);
            hasDuplicates = true;
            // Handle the duplicate itemName case here (e.g., throw an error, remove the duplicate, etc.)
        } else {
            uniqueItemNames.add(item.itemName);
        }
    });

    if (hasDuplicates) {
        isFurnitureFormValid = false;
    }

    formValue.items.forEach((item, index) => {
        if (!item[`itemName${index}`]) {
            isFurnitureFormValid = false
        }
        if (!item[`rangeMin${index}`]) {
            isFurnitureFormValid = false
        }
        if (!item[`rangeMax${index}`]) {
            isFurnitureFormValid = false
        }
        if (!item[`mostCommonCuFt${index}`]) {
            isFurnitureFormValid = false
        }
    });

    return isFurnitureFormValid;
}

export const isRoomsAreasFormValid = (formValue) => {
    let isFurnitureFormValid = true;
    return isFurnitureFormValid;
}

export const isCalculationFormValid = async (formValue) => {
    const socket = getSocket();
    let isCalculationFormValid = true;
    if (!formValue.calculationNumber) {
        isCalculationFormValid = false
        alert("Please fill 'Calculation number' field")
    }

    if (formValue.commonValues.packing !== 'No') {
        /* if (formValue.commonValues.extraStops.length) {
            let extraStopsFilled = false
            formValue.commonValues.extraStops.forEach((extraStop, exStIndex) => {
                if (extraStop[(`smallBoxes${exStIndex}`)] ||
                    extraStop[(`mediumBoxes${exStIndex}`)] ||
                    extraStop[(`fragileBoxes${exStIndex}`)]) {
                    extraStopsFilled = true;
                }
            });
            if (!extraStopsFilled && !formValue.commonValues.smallBoxes && !formValue.commonValues.mediumBoxes && !formValue.commonValues.fragileBoxes) {
                isCalculationFormValid = false;
                alert("Please fill 'Packing boxes' fields")
            }
        } else {
            if (!formValue.commonValues.smallBoxes && !formValue.commonValues.mediumBoxes && !formValue.commonValues.fragileBoxes && !formValue.commonValues.extraStops.length) {
                isCalculationFormValid = false;
                alert("Please fill 'Packing boxes' fields")
            }
        } */
        if (formValue.commonValues.extraStops.length) {
            let extraStopsFilled = false;
            formValue.commonValues.extraStops.forEach((extraStop, exStIndex) => {
                if (extraStop[(`smallBoxes${exStIndex}`)] && extraStop[(`smallBoxes${exStIndex}`)] !== '0' && extraStop[(`smallBoxes${exStIndex}`)] !== 0 && extraStop[(`smallBoxes${exStIndex}`)] !== '' ||
                    extraStop[(`mediumBoxes${exStIndex}`)] && extraStop[(`mediumBoxes${exStIndex}`)] !== '0' && extraStop[(`mediumBoxes${exStIndex}`)] !== 0 && extraStop[(`mediumBoxes${exStIndex}`)] !== '' ||
                    extraStop[(`fragileBoxes${exStIndex}`)] && extraStop[(`fragileBoxes${exStIndex}`)] !== '0' && extraStop[(`fragileBoxes${exStIndex}`)] !== 0 && extraStop[(`fragileBoxes${exStIndex}`)] !== '') {
                    extraStopsFilled = true;
                } else {
                    extraStopsFilled = false;
                }
            });
            if (!extraStopsFilled && (!formValue.commonValues.smallBoxes || formValue.commonValues.smallBoxes === '0' || formValue.commonValues.smallBoxes === 0 || formValue.commonValues.smallBoxes === '') &&
                (!formValue.commonValues.mediumBoxes || formValue.commonValues.mediumBoxes === '0' || formValue.commonValues.mediumBoxes === 0 || formValue.commonValues.mediumBoxes === '') &&
                (!formValue.commonValues.fragileBoxes || formValue.commonValues.fragileBoxes === '0' || formValue.commonValues.fragileBoxes === 0 || formValue.commonValues.fragileBoxes === '')) {
                isCalculationFormValid = false;
                alert("Please fill 'Packing boxes' fields");
            }
        } else {
            if ((!formValue.commonValues.smallBoxes || formValue.commonValues.smallBoxes === '0' || formValue.commonValues.smallBoxes === 0 || formValue.commonValues.smallBoxes === '') &&
                (!formValue.commonValues.mediumBoxes || formValue.commonValues.mediumBoxes === '0' || formValue.commonValues.mediumBoxes === 0 || formValue.commonValues.mediumBoxes === '') &&
                (!formValue.commonValues.fragileBoxes || formValue.commonValues.fragileBoxes === '0' || formValue.commonValues.fragileBoxes === 0 || formValue.commonValues.fragileBoxes === '') &&
                !formValue.commonValues.extraStops.length) {
                isCalculationFormValid = false;
                alert("Please fill 'Packing boxes' fields");
            }
        }
    }

    switch (formValue.calculationType) {
        case "oneDayPacking":
            if (Number(formValue.oneDayPacking.moversToLoad) && (Number(formValue.oneDayPacking.moversToDrivesAndUnload) >= Number(formValue.oneDayPacking.moversToLoad))) {
                isCalculationFormValid = false;
                alert("Movers to drive and unload must be less than Movers to Load(1 Day - Packing / No packing)")
            }
            break;

        case "twoDaysPacking":
            if (Number(formValue.twoDaysPacking.moversToLoad) && (Number(formValue.twoDaysPacking.moversToDrivesAndUnload) >= Number(formValue.twoDaysPacking.moversToLoad))) {
                isCalculationFormValid = false;
                alert("Movers to drive and unload must be less than Movers to Load(2 days - Packing)")
            }
            break;

        default:
            break;
    }

    socket.emit('getTotal_box_cubes', formValue.commonValues)
    const Total_box_cubes = await new Promise((resolve) => {
        socket.emit('getTotal_box_cubes', formValue.commonValues);
        socket.on('Total_box_cubes', (data) => resolve(data));
    });
    if (Total_box_cubes > 0 && formValue.commonValues.packing === 'No') {
        if (!formValue.commonValues.isNoPackingConfirmed) {
            isCalculationFormValid = false;
            alert("Please confirm No Packing option")
        }
    }

    return isCalculationFormValid;
}
export const isDepartmentFormValid = (formValue) => {
    let isDepartmentFormValid = true;

    if (!formValue.authorId) {
        console.log("1")
        isDepartmentFormValid = false
    }
    if (!formValue.companyId) {
        console.log("2")
        isDepartmentFormValid = false
    }
    if (!formValue.departmentName) {
        console.log("3")
        isDepartmentFormValid = false
    }


    //rates
    if (formValue.rates.ratesList.length) {
        formValue.rates.ratesList.forEach((rateItem, index) => {
            if (formValue.rates.calcMethod === 'discount') {
                if (!rateItem[`cashDiscount${index}`]) {
                    console.log("5")
                    isDepartmentFormValid = false
                }
            }
            else if (formValue.rates.calcMethod === 'deduct') {
                if (!rateItem[`deductPayment${index}`]) {
                    console.log("6")
                    isDepartmentFormValid = false
                }
            }

            if (formValue.rates.rateType === 'calculated') {
                if (!rateItem[`hourlyRate${index}`]) {
                    console.log("7")
                    isDepartmentFormValid = false
                }
            }
            else if (formValue.rates.rateType === 'custom') {
                rateItem[`customRatesList${index}`].forEach((customRateItem, cRateItemIndex) => {
                    if (!customRateItem[`customMovers${cRateItemIndex}`]) {
                        console.log("8")
                        isDepartmentFormValid = false
                    }
                    if (!customRateItem[`customTrucks${cRateItemIndex}`]) {
                        console.log("9")
                        isDepartmentFormValid = false
                    }
                    if (!customRateItem[`customHourlyRate${cRateItemIndex}`]) {
                        console.log("10")
                        isDepartmentFormValid = false
                    }
                });
            }
            else {
                isDepartmentFormValid = false
                console.log("11")
            }

            if (!rateItem[`rateName${index}`]) {
                isDepartmentFormValid = false
                console.log("12")
            }
            /*    if (rateItem[`isArchived${index}`] !== true && rateItem[`isArchived${index}`] !== false) {
                   isDepartmentFormValid = false
                   console.log("13")
               } */
            if (!rateItem[`extraMover${index}`]) {
                isDepartmentFormValid = false
                console.log("14")
            }
            if (!rateItem[`extraTruck${index}`]) {
                isDepartmentFormValid = false
                console.log("15")
            }
            if (!rateItem[`longDistanceExtra${index}`]) {
                isDepartmentFormValid = false
                console.log("16")
            }
            if (rateItem[`longDistanceExtraType${index}`] !== 'perJob' && rateItem[`longDistanceExtraType${index}`] !== 'perTruck') {
                isDepartmentFormValid = false
                console.log("17")
            }
        });
    }
    else {
        isDepartmentFormValid = false
    }
    //EOF rates

    //truckFee
    if (formValue.truckFee.calcMethod === '' || formValue.truckFee.calcMethod === 'none') {

    }
    else if (formValue.truckFee.calcMethod === 'simple') {
        if (!formValue.truckFee.truckReservation) {
            isDepartmentFormValid = false
            console.log("18")
        }
        if (!formValue.truckFee.truckDestionation.fee) {
            isDepartmentFormValid = false
            console.log("19")
        }
        if (!formValue.truckFee.truckDestionation.miles) {
            isDepartmentFormValid = false
            console.log("20")
        }
        if (formValue.truckFee.tfdfCombined !== true && formValue.truckFee.tfdfCombined !== false) {
            isDepartmentFormValid = false
            console.log("21")
        }
    }
    else if (formValue.truckFee.calcMethod === 'different') {
        if (formValue.truckFee.diffTruckReservations.length) {
            formValue.truckFee.diffTruckReservations.forEach(diffTruckReservation => {
                if (!diffTruckReservation.fee) {
                    console.log("30")
                    isDepartmentFormValid = false
                }
                if (!diffTruckReservation.miles) {
                    isDepartmentFormValid = false
                    console.log("31")
                }
            });
        }
        else {
            isDepartmentFormValid = false
            console.log("32")
        }
    }
    else {
        isDepartmentFormValid = false
        console.log("4")
    }
    //EOF truckFee

    //doubleDrive
    if (formValue.doubleDrive.apply !== true && formValue.doubleDrive.apply !== false) {
        isDepartmentFormValid = false
        console.log("22")
    }
    if (!formValue.doubleDrive.miles) {
        isDepartmentFormValid = false
        console.log("24")
    }
    if (formValue.doubleDrive.isActiveChargePerMile === true) {
        if (!formValue.doubleDrive.chargePerMile) {
            isDepartmentFormValid = false
            console.log("23")
        }
    }
    else if (formValue.doubleDrive.isActiveChargePerMile === false) {

    }
    else {
        isDepartmentFormValid = false
        console.log("25")
    }
    //EOF doubleDrive

    //extraOptions
    if (!formValue.extraOptions.heavyItem) {
        isDepartmentFormValid = false
        console.log("26")
    }
    if (!formValue.extraOptions.shuttle.charge) {
        isDepartmentFormValid = false
        console.log("27")
    }
    if (formValue.extraOptions.shuttle.period !== 'hourly' && formValue.extraOptions.shuttle.period !== 'daily') {
        isDepartmentFormValid = false
        console.log("33")
    }
    if (formValue.extraOptions.charges.length) {
        formValue.extraOptions.charges.forEach((charge, chargeIndex) => {
            if (!charge[`chargeName${chargeIndex}`]) {
                isDepartmentFormValid = false
                console.log("28", formValue)
            }
            if (!charge[`amount${chargeIndex}`]) {
                isDepartmentFormValid = false
                console.log("29")
            }
        });
    } else {
        //   isDepartmentFormValid = false
    }
    //EOF extraOptions

    //packing
    if (!formValue.packing.packingKits.firstBedroom) {
        isDepartmentFormValid = false
        console.log("40")
    }
    if (!formValue.packing.packingKits.firstCuFt) {
        isDepartmentFormValid = false
        console.log("41")
    }
    if (!formValue.packing.packingKits.secondBedroom) {
        isDepartmentFormValid = false
        console.log("42")
    }
    if (!formValue.packing.packingKits.secondCuFt) {
        isDepartmentFormValid = false
        console.log("43")
    }
    if (!formValue.packing.packingKits.thirdBedroom) {
        isDepartmentFormValid = false
        console.log("44")
    }
    if (!formValue.packing.packingKits.thirdCuFt) {
        isDepartmentFormValid = false
        console.log("45")
    }
    if (!formValue.packing.packingKits.fourthBedroom) {
        isDepartmentFormValid = false
        console.log("46")
    }
    if (!formValue.packing.packingKits.fourthCuFt) {
        isDepartmentFormValid = false
        console.log("47")
    }
    if (!formValue.packing.packingKits.fifthBedroom) {
        isDepartmentFormValid = false
        console.log("48")
    }
    if (!formValue.packing.packingKits.fifthCuFt) {
        isDepartmentFormValid = false
        console.log("49")
    }
    if (!formValue.packing.packingKits.sixthBedroom) {
        isDepartmentFormValid = false
        console.log("50")
    }
    if (!formValue.packing.packingKits.sixthCuFt) {
        isDepartmentFormValid = false
        console.log("51")
    }
    if (!formValue.packing.packingKits.seventhBedroom) {
        isDepartmentFormValid = false
        console.log("52")
    }
    if (!formValue.packing.packingKits.seventhCuFt) {
        isDepartmentFormValid = false
        console.log("53")
    }
    if (!formValue.packing.packingKits.eighthBedroom) {
        isDepartmentFormValid = false
        console.log("54")
    }
    if (!formValue.packing.packingKits.eighthCuFt) {
        isDepartmentFormValid = false
        console.log("55")
    }
    if (!formValue.packing.packingKits.ninethBedroom) {
        isDepartmentFormValid = false
        console.log("56")
    }
    if (!formValue.packing.packingKits.ninethCuFt) {
        isDepartmentFormValid = false
        console.log("57")
    }
    if (!formValue.packing.packingKits.tenthBedroom) {
        isDepartmentFormValid = false
        console.log("58")
    }
    if (!formValue.packing.packingKits.tenthCuFt) {
        isDepartmentFormValid = false
        console.log("59")
    }
    if (!formValue.packing.packingKits.kitchen) {
        isDepartmentFormValid = false
        console.log("60")
    }
    if (!formValue.packing.packingKits.kitchenCuFt) {
        isDepartmentFormValid = false
        console.log("61")
    }
    if (!formValue.packing.packingKits.salesTax) {
        isDepartmentFormValid = false
        console.log("62")
    }

    if (!formValue.packing.supplies.smallBox) {
        isDepartmentFormValid = false
        console.log("63")
    }
    if (!formValue.packing.supplies.mediumBox) {
        isDepartmentFormValid = false
        console.log("64")
    }
    if (!formValue.packing.supplies.largeBox) {
        isDepartmentFormValid = false
        console.log("65")
    }
    if (!formValue.packing.supplies.packingPapper) {
        isDepartmentFormValid = false
        console.log("66")
    }
    if (!formValue.packing.supplies.bubbleWrap) {
        isDepartmentFormValid = false
        console.log("67")
    }
    if (formValue.packing.cratings.length) {
        formValue.packing.cratings.forEach((crating, cratingIndex) => {
            if (!crating[`crateName${cratingIndex}`]) {
                isDepartmentFormValid = false
                console.log("68")
            }
            if (!crating[`crateCost${cratingIndex}`]) {
                isDepartmentFormValid = false
                console.log("69")
            }
            if (!crating[`timeToExecuteCrate${cratingIndex}`]) {
                isDepartmentFormValid = false
                console.log("70")
            }
        });
    }
    else {
        //   isDepartmentFormValid = false
    }

    if (formValue.miscCheckMarks.length) {
        formValue.miscCheckMarks.forEach((checkMark, checkMarkIndex) => {
            if (!checkMark[`checkMarkName${checkMarkIndex}`]) {
                isDepartmentFormValid = false
                console.log("71")
            }
        });
    }
    //EOF packing

    return isDepartmentFormValid;
}


export const isLongDistanceFormValid = (formValue) => {
    let isLongDistanceFormValid = true;

    if (!formValue.authorId) {
        isLongDistanceFormValid = false
        console.log('1')
    }
    if (!formValue.companyId) {
        isLongDistanceFormValid = false
        console.log('2')
    }
    if (!formValue.directionName) {
        isLongDistanceFormValid = false
        console.log('3')
    }

    if (formValue.calcMethod === 'discount') {//discount, deduct
        if (!formValue.cashPaymentDiscount) {
            isLongDistanceFormValid = false
            console.log('4')
        }
    }
    else if (formValue.calcMethod === 'deduct') {
        if (!formValue.cashPaymentDeduct) {
            isLongDistanceFormValid = false
            console.log('5')
        }
    }
    else {
        isLongDistanceFormValid = false
        console.log('6')
    }



    if (formValue.directionType === 'expences') {//expences, roomDistance
        //salaryExpenses
        if (!formValue.salaryExpenses.foreman) {
            isLongDistanceFormValid = false
            console.log('7')
        }
        if (!formValue.salaryExpenses.helperAtLoading) {
            isLongDistanceFormValid = false
            console.log('8')
        }
        if (!formValue.salaryExpenses.helperAtDriving) {
            isLongDistanceFormValid = false
            console.log('9')
        }
        if (!formValue.salaryExpenses.helperAtUnloading) {
            isLongDistanceFormValid = false
            console.log('10')
        }
        //EOF salaryExpenses

        //fuelExpenses
        if (!formValue.fuelExpenses.fuelPerGallon) {
            isLongDistanceFormValid = false
            console.log('11')
        }
        if (!formValue.fuelExpenses.milesPerGallon) {
            isLongDistanceFormValid = false
            console.log('12')
        }
        if (!formValue.fuelExpenses.wrappingMaterials) {
            isLongDistanceFormValid = false
            console.log('13')
        }
        if (!formValue.fuelExpenses.oosLogBook) {
            isLongDistanceFormValid = false
            console.log('14')
        }
        if (!formValue.fuelExpenses.hotel) {
            isLongDistanceFormValid = false
            console.log('15')
        }
        if (formValue.fuelExpenses.extraAmounts.length) {
            formValue.fuelExpenses.extraAmounts.forEach((extraAmount, extraAmountIndex) => {
                if (!extraAmount[`amountName${extraAmountIndex}`]) {
                    isLongDistanceFormValid = false
                    console.log('16')
                }
                if (!extraAmount[`extraAmount${extraAmountIndex}`]) {
                    isLongDistanceFormValid = false
                    console.log('17')
                }
                if (extraAmount[`type${extraAmountIndex}`] !== 'perJob' && extraAmount[`type${extraAmountIndex}`] !== 'perTruck') {
                    isLongDistanceFormValid = false
                    console.log('18')
                }
            });
        }
        else {
            isLongDistanceFormValid = false
            console.log('19')
        }
        //EOF fuelExpenses

        //driveTimes
        if (formValue.driveTimes.length) {
            formValue.driveTimes.forEach((driveTime, dtIndex) => {
                if (!driveTime[`rangeStart${dtIndex}`]) {
                    isLongDistanceFormValid = false
                    console.log('20')
                }
                if (!driveTime[`rangeEnd${dtIndex}`]) {
                    isLongDistanceFormValid = false
                    console.log('21')
                }
                if (!driveTime[`hours${dtIndex}`]) {
                    isLongDistanceFormValid = false
                    console.log('22')
                }
            });
        }
        //EOF driveTimes

        if (formValue.roundToFull !== true && formValue.roundToFull !== false) {
            isLongDistanceFormValid = false
            console.log('23')
        }
    }
    else if (formValue.directionType === 'roomDistance') {
        if (!formValue.amountRD) {
            isLongDistanceFormValid = false
            console.log('24')
        }
        if (!formValue.flatRateRD) {
            isLongDistanceFormValid = false
            console.log('25')
        }
        if (!formValue.packingRD) {
            isLongDistanceFormValid = false
            console.log('26')
        }
        if (!formValue.extraPackingRD) {
            isLongDistanceFormValid = false
            console.log('27')
        }
        if (!formValue.extraTruckDriverRD) {
            isLongDistanceFormValid = false
            console.log('28')
        }

        if (formValue.extraAmountsRD.length) {
            formValue.extraAmountsRD.forEach((extraAmount, extraAmountIndex) => {
                if (!extraAmount[`amountNameRD${extraAmountIndex}`]) {
                    isLongDistanceFormValid = false
                    console.log('29')
                }
                if (!extraAmount[`extraAmountRD${extraAmountIndex}`]) {
                    isLongDistanceFormValid = false
                    console.log('30')
                }
                if (extraAmount[`typeRD${extraAmountIndex}`] !== 'perJob' && extraAmount[`typeRD${extraAmountIndex}`] !== 'perTruck') {
                    isLongDistanceFormValid = false
                    console.log('31')
                }
            });
        }
        if (!formValue.arrivalTimeDaysRD) {
            isLongDistanceFormValid = false
            console.log('32')
        }
    }
    else {
        isLongDistanceFormValid = false
        console.log('33')
    }

    return isLongDistanceFormValid;
}

export const isOutOfStateFormValid = (formValue) => {
    let isOutOfStateFormValid = true;

    if (!formValue.authorId) {
        isOutOfStateFormValid = false
    }
    if (!formValue.companyId) {
        isOutOfStateFormValid = false
    }
    if (!formValue.directionName) {
        isOutOfStateFormValid = false
    }
    if (formValue.calcMethod === 'discount') {
        if (!formValue.cashPaymentDiscount) {
            isOutOfStateFormValid = false
        }
    }
    else if (formValue.calcMethod === 'deduct') {
        if (!formValue.cashPaymentDeduct) {
            isOutOfStateFormValid = false
        }
    }
    else {
        isOutOfStateFormValid = false
    }

    //parameters
    if (formValue.parameters.length) {
        formValue.parameters.forEach((parameter, index) => {
            if (!parameter[`pName${index}`]) {
                isOutOfStateFormValid = false
            }
            if (!parameter[`pAmount${index}`]) {
                isOutOfStateFormValid = false
            }
        });
    }
    //EOF parameters

    //extraAmounts -- Charges
    if (formValue.extraAmounts.length) {
        formValue.extraAmounts.forEach((extraAmount, extraAmountIndex) => {
            if (!extraAmount[`amountName${extraAmountIndex}`]) {
                isOutOfStateFormValid = false
            }
            if (!extraAmount[`extraAmount${extraAmountIndex}`]) {
                isOutOfStateFormValid = false
            }
            if (extraAmount[`type${extraAmountIndex}`] !== 'perJob' && extraAmount[`type${extraAmountIndex}`] !== 'perTruck') {
                isOutOfStateFormValid = false
            }
        });
    }
    //EOF extraAmounts -- Charges

    //driveTimes
    if (formValue.driveTimes.length) {
        formValue.driveTimes.forEach((driveTime, dtIndex) => {
            if (!driveTime[`rangeStart${dtIndex}`]) {
                isOutOfStateFormValid = false
            }
            if (!driveTime[`rangeEnd${dtIndex}`]) {
                isOutOfStateFormValid = false
            }
            if (!driveTime[`days${dtIndex}`]) {
                isOutOfStateFormValid = false
            }
        });
    }
    //EOF driveTimes
    if (formValue.extraMultNumOfTrucks === false) {
        if (!formValue.extraPerTruckAmount) {
            isOutOfStateFormValid = false
        }
    }
    else if (formValue.extraMultNumOfTrucks === true) { }
    else {
        isOutOfStateFormValid = false
    }
    return isOutOfStateFormValid;
}


export const isTemplateFormValid = (formValue) => {

    let isOutOfStateFormValid = true;
    const tagNames = formValue.tags.map((tag, index) => {
        return tag[`tagName`]
    });
    let duplicateTagNameExists = false;
    duplicateTagNameExists = tagNames.some((element, index) => {
        return tagNames.indexOf(element) !== index
    });
    if (duplicateTagNameExists) {
        isOutOfStateFormValid = false;
    }
    return isOutOfStateFormValid;
}