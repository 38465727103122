// Components
import { CalculationOneDayParams } from '../calculation-one-day-params/Calculation-one-day-params';
import { CalculationOneDayPayment } from '../calculation-one-day-payment/Calculation-one-day-payment';

// Utils
/* import {
  calculateOfMovers,
  totalCubicFt,
  numberOfTrucks,
  totalTrucks
} from '../../util/calculation-formulas'; */
// import { boxesKey } from '../../util/const';
// Styles
import styles from './CalculationOneDayResult.module.css';
import { BtnApplyNewRate } from '../btn-apply-new-rate/Btn-apply-new-rate';
import { BtnApplyNewTemplate } from '../btn-apply-new-template/Btn-apply-new-template';

export const CalculationOneDayResult = (props) => {
  const { calculationData, sameDepartmentSettings, applyNewRates, setPreviewText, setOptionsPreviewText, template, editorState, tags, formChange, resetLastTarget, checkboxValueChange, popUpShown, oldTemplateWasUsed, applyNewTemplate, setShowGenerateEmail, furniture, roomsAreas, setTagsValuesDefault, customTagsValues, showCustomTags } = props;
  const commonValues = { ...calculationData.commonValues };
  const oneDayPacking = { ...calculationData.oneDayPacking };
  const department = { ...calculationData.department };
  const selectedMiscCheckMarks = calculationData.selectedMiscCheckMarks;
  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Calculation</h2>
      {!sameDepartmentSettings && <div className={styles.oldApplyNew}><h2 className={styles.title} style={{ color: "red" }}>Old department settings were used</h2>
        <BtnApplyNewRate
          isActive={true}
          action={applyNewRates}
          name={'Apply new rates'}
        />
      </div>}

      {oldTemplateWasUsed && <div className={styles.oldApplyNew}><h2 className={styles.title} style={{ color: "#0000ff" }}>Old template settings were used</h2>
        <BtnApplyNewTemplate
          isActive={true}
          action={applyNewTemplate}
          name={'Apply new Template'}
        />
      </div>}

      {calculationData &&
        <div className={styles.selectedInfo}>
          {calculationData.calculationNumber && <p className={styles.calculationNumber}>{calculationData.calculationNumber}</p>}
          {calculationData.department && <p className={styles.departmentName}>{calculationData.department.departmentName}</p>}
          {calculationData.oneDayPacking.rate && <p className={styles.rateName}>{calculationData.oneDayPacking.rate}</p>}
        </div>
      }

      <div className={styles.selectedInfo}>
        {oneDayPacking.firstDayDate && <p className={styles.calculationDate}>{new Date(oneDayPacking.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' })}</p>}
      </div>

      <CalculationOneDayParams commonValues={commonValues} oneDayPacking={oneDayPacking} department={department} selectedMiscCheckMarks={selectedMiscCheckMarks}
        setPreviewText={setPreviewText}
        setOptionsPreviewText={setOptionsPreviewText}
        template={template}
        editorState={editorState}
        tags={tags}
        calculationData={calculationData}
        formChange={formChange}
        resetLastTarget={resetLastTarget}
        checkboxValueChange={checkboxValueChange}
        popUpShown={popUpShown}
        setShowGenerateEmail={setShowGenerateEmail}
        furniture={furniture}
        roomsAreas={roomsAreas}
        setTagsValuesDefault={setTagsValuesDefault}
        customTagsValues={customTagsValues}
        showCustomTags={showCustomTags}
      />
      {/*       <CalculationOneDayPayment calculationData={calculationData} /> */}
    </section>
  );
};
