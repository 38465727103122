export const furnitureDataModel = {
  authorId: '',
  companyId: '',
  items: [/* {
      itemName0: '',
      rangeMin0: 0,
      rangeMax0: 0, 
      mostCommonCuFt0: 0, 
    }*/],
  includeCuFtPerItem: false,
  useSymbolBeforeItems: false,
  furnitureItemsSymbol: '',
  bold: false,
  italic: false,
  underline: false,
  inventoryListOptions: {
    default: {
      cuftText: '',
      includeBoxesOnInv: false,
      boxesText: '',
      convertToMediumBoxes: false,
      includeWBBoxesOnInv: false,
      WBBoxesText: '',
    }
  }
};  
