// Third party libraries
import PropTypes from 'prop-types';
// Components

import styles from './AdditionalServicesResults.module.css'
import { performComparison } from '../../util/utils';


export const AdditionalServicesResults = (props) => {
  const {
    selectedAdditionalServices,
    department,
    additionalServicesPlaceType,
    Total_cubes,
    trucksCount,
    scrollRefs,
    highLightItem
  } = props;

  return (
    <>
      {(additionalServicesPlaceType && selectedAdditionalServices && selectedAdditionalServices.length) ? (
        <div>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Additional Services:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}></p>
            <ul>

              {department.additionalServices.services.map((service) => {
                let showAS = false;
                showAS = selectedAdditionalServices.some(item => item.key.includes(service.serviceName));
                if (showAS) {
                  if (service.serviceType === 'cuft') {
                    for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
                      let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
                      if (performComparison(pricePerCuFt[`operator${pcftIndex}`], Number(Total_cubes), Number(pricePerCuFt[`cubicFtRange${pcftIndex}`]))) {
                        let totalCubes = service.fixedAmount ? 1 : Total_cubes;
                        return <li key={service.serviceName}>
                          <span className={`${(highLightItem === `aSIdName_${service.serviceName}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`aSIdName_${service.serviceName}`]}>{service.serviceName}</span>  <span>{`($${Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`])}/cuft)`}</span>
                          {(service.isCountable) ? <span className={`${(highLightItem === `aSIdQty_${service.serviceName}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`aSIdQty_${service.serviceName}`]}> x {selectedAdditionalServices.find(item => item.key === service.serviceName).quantity} </span> : ''}
                          : ${(service.isCountable) ? <span className={`${(highLightItem === `aSIdAmount_${service.serviceName}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`aSIdAmount_${service.serviceName}`]}>{selectedAdditionalServices.find(item => item.key === service.serviceName).quantity * Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`] * totalCubes)}</span> : <span className={`${(highLightItem === `aSIdAmount_${service.serviceName}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`aSIdAmount_${service.serviceName}`]}>{Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`]) * totalCubes}</span>}
                          {((service.perTruck) ? <> x {trucksCount} truck(s)=<span className={`${(highLightItem === `aSIdAmount_${service.serviceName}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`aSIdAmount_${service.serviceName}`]}>${(Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`])) * trucksCount * selectedAdditionalServices.find(item => item.key === service.serviceName).quantity * totalCubes}</span></> : '')
                          }
                        </li>;
                      }
                    }
                  } else if (service.serviceType === 'factor') {
                    for (let pcftIndex = 0; pcftIndex < service.pricesPerCuFt.length; pcftIndex++) {
                      let pricePerCuFt = service.pricesPerCuFt[pcftIndex];
                      if (performComparison(pricePerCuFt[`operator${pcftIndex}`], additionalServicesPlaceType, pricePerCuFt[`factorName${pcftIndex}`])) {
                        return <li key={service.serviceName}>
                          <span className={`${(highLightItem === `aSIdName_${service.serviceName}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`aSIdName_${service.serviceName}`]}>{service.serviceName}</span>  <span>{`($${Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`])}/cuft)`}</span>
                          {((service.isCountable) ? <span className={`${(highLightItem === `aSIdQty_${service.serviceName}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`aSIdQty_${service.serviceName}`]}> x {selectedAdditionalServices.find(item => item.key === service.serviceName).quantity}</span> : '')}
                          : ${((service.isCountable) ? <span className={`${(highLightItem === `aSIdAmount_${service.serviceName}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`aSIdAmount_${service.serviceName}`]}>{selectedAdditionalServices.find(item => item.key === service.serviceName).quantity * Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`])}</span> : <span className={`${(highLightItem === 'cuftToMove') ? styles.highLighted : ""}`} ref={scrollRefs?.current['cuftToMove']}>{Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`])}</span>)}
                          {((service.perTruck) ? <> x {trucksCount} truck(s)=<span className={`${(highLightItem === `aSIdAmount_${service.serviceName}`) ? styles.highLighted : ""}`} ref={scrollRefs?.current[`aSIdAmount_${service.serviceName}`]}>${(Number(pricePerCuFt[`pricePerCuFt${pcftIndex}`])) * trucksCount * selectedAdditionalServices.find(item => item.key === service.serviceName).quantity}</span> </> : '')}
                        </li>;
                      }
                    }
                  }
                }
              })}
            </ul>
          </li>
        </div>
      ) : ''}
    </>
  );
}

