import { createSlice } from '@reduxjs/toolkit';
import {
  loadRoomsAreas,
  saveRoomsAreas,
  updateRoomsAreas,
} from './roomsAreas-async-thunk';


const initialState = {
  savingStatus: 'idle',
  loadingStatus: 'idle',
  roomsAreas: null,
  errors: null,
};

const roomsAreasSlice = createSlice({
  name: 'roomsAreas',
  initialState,
  reducers: {
    setRoomsAreasSavingStatus(state, action) {
      const { status } = action.payload;
      state.savingStatus = status;
    },
    setRoomsAreasLoadingStatus(state, action) {
      const { status } = action.payload;
      state.loadingStatus = status;
    },
  },
  extraReducers: {
    // saveRoomsAreas extra reducer
    [saveRoomsAreas.pending]: (state, action) => {
      state.savingStatus = 'loading';
    },
    [saveRoomsAreas.rejected]: (state, action) => {
      state.savingStatus = 'failed';
      state.errors = action.error.message;
    },
    [saveRoomsAreas.fulfilled]: (state, action) => {
      state.savingStatus = 'successed';
      state.roomsAreas = action.payload.data;
    },

    // updateDepartmen extra reducer
    [updateRoomsAreas.pending]: (state, action) => {
      state.savingStatus = 'loading';
    },
    [updateRoomsAreas.rejected]: (state, action) => {
      state.savingStatus = 'failed';
      state.errors = action.error.message;
    },
    [updateRoomsAreas.fulfilled]: (state, action) => {
      state.savingStatus = 'successed';
      state.roomsAreas = action.payload.data;
    },

    // loadRoomsAreas extra reducer
    [loadRoomsAreas.pending]: (state, action) => {
      state.loadingStatus = 'loading';
    },
    [loadRoomsAreas.rejected]: (state, action) => {
      state.loadingStatus = 'failed';
      state.errors = action.error.message;
    },
    [loadRoomsAreas.fulfilled]: (state, action) => {
      state.loadingStatus = 'successed';
      state.roomsAreas = action.payload.data;
    }
  },
});

export const { setRoomsAreasSavingStatus, setRoomsAreasLoadingStatus, setRoomsAreasRemovingStatus } = roomsAreasSlice.actions;

export default roomsAreasSlice.reducer;

export const getRoomsAreasSavingStatus = (state) => state.roomsAreas.savingStatus;

export const getRoomsAreasLoadingStatus = (state) => state.roomsAreas.loadingStatus;

export const getRoomsAreas = (state) => state.roomsAreas.roomsAreas;
