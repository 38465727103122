// InventoryContainer.jsx
import React, { useEffect, useRef, useState } from 'react';
import styles from './AddItemButton.module.css';
import { IconNames } from '../../util/const';
import { Icon } from '../Icon';


const AddItemButton = ({ isPassive, addItemButtonRef, addItem, type }) => {
    return (
        <button
            ref={addItemButtonRef}
            className={`${styles.extraStopBtn} ${isPassive ? styles.disabledButton : ''}`}
            onClick={
                (evt) => {
                    evt.preventDefault();
                    addItem(type);
                }
            }
            disabled={isPassive}
            tabIndex={isPassive ? -1 : 0}
        >
            <span style={{ marginRight: '5px' }}>Add item</span>
            <Icon name={IconNames.PLUS} />
        </button>
    );
};
export default AddItemButton;