// Components
import { CalculationLongTwoDayParams } from '../calculation-long-two-day-params/Calculation-long-two-day-params';
import { BtnApplyNewRate } from '../btn-apply-new-rate/Btn-apply-new-rate';
import { CalculationTwoDaysPayment } from '../calculation-two-days-payment/Calculation-two-days-payment';
// Utils

// import { boxesKey } from '../../util/const';
// Styles
import styles from './CalculationLongTwoDayResult.module.css';
import { BtnApplyNewTemplate } from '../btn-apply-new-template/Btn-apply-new-template';

export const CalculationLongTwoDayResult = (props) => {
  const { calculationData, sameDepartmentSettings, sameLongDirectionSettings, applyNewRates, sameUnpDepartmentSettings, formChange, resetLastTarget, checkboxValueChange, editorState,
    tags,
    setPreviewText, setOptionsPreviewText,
    popUpShown, oldTemplateWasUsed, applyNewTemplate,setShowGenerateEmail, furniture, roomsAreas, setTagsValuesDefault, customTagsValues } = props;
  const commonValues = { ...calculationData.commonValues };
  const longTwoDaysPacking = { ...calculationData.longTwoDaysPacking };
  const department = { ...calculationData.department };
  const unpDepartment = { ...calculationData.unpDepartment };
  const longDistance = { ...calculationData.longDistance };
  const selectedMiscCheckMarks = calculationData.selectedMiscCheckMarks
  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Calculation - LONG - 2 days - Packing</h2>
      {(!sameDepartmentSettings || !sameLongDirectionSettings || !sameUnpDepartmentSettings) && <div className={styles.oldApplyNew}><h2 className={styles.title} style={{ color: "red" }}>Old department or destination settings were used</h2>
        <BtnApplyNewRate
          isActive={true}
          action={applyNewRates}
          name={'Apply new rates'}
        />
      </div>}
      {oldTemplateWasUsed && <div className={styles.oldApplyNew}><h2 className={styles.title} style={{ color: "#0000ff" }}>Old template settings were used</h2>
        <BtnApplyNewTemplate
          isActive={true}
          action={applyNewTemplate}
          name={'Apply new Template'}
        />
      </div>}
      {calculationData &&
        <div className={styles.selectedInfo}>
          {calculationData.calculationNumber && <p className={styles.calculationNumber}>{calculationData.calculationNumber}</p>}
          {calculationData.department && <p className={styles.departmentName}>{calculationData.department.departmentName}</p>}
          {calculationData.longTwoDaysPacking.firstDayRate && <p className={styles.rateName}>{calculationData.longTwoDaysPacking.firstDayRate}</p>}
          {calculationData.longTwoDaysPacking.secondDayRate && <p className={styles.secondRateName}>{calculationData.longTwoDaysPacking.secondDayRate}</p>}
        </div>
      }
      <div className={styles.selectedInfo}>
        {longTwoDaysPacking.firstDayDate && <p className={styles.calculationDate} style={{ marginRight: '4px' }}>{new Date(longTwoDaysPacking.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' })}</p>}
        {longTwoDaysPacking.secondDayDate && <p className={styles.calculationDate} style={{ marginRight: '4px' }}>{(longTwoDaysPacking.firstDayDate && !longTwoDaysPacking.thirdDayDate) ? '& ' : ', '} {new Date(longTwoDaysPacking.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' })}</p>}
        {longTwoDaysPacking.thirdDayDate && <p className={styles.calculationDate}>{longTwoDaysPacking.secondDayDate && '& '} {new Date(longTwoDaysPacking.thirdDayDate).toLocaleDateString('en-us', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' })}</p>}
      </div>
      <CalculationLongTwoDayParams commonValues={commonValues} longTwoDaysPacking={longTwoDaysPacking}
        department={department} longDistance={longDistance} unpDepartment={unpDepartment} selectedMiscCheckMarks={selectedMiscCheckMarks}
        formChange={formChange}
        resetLastTarget={resetLastTarget}
        checkboxValueChange={checkboxValueChange}
        editorState={editorState}
        calculationData={calculationData}
        tags={tags}
        popUpShown={popUpShown}
        setPreviewText={setPreviewText}
        setOptionsPreviewText={setOptionsPreviewText}
        setShowGenerateEmail={setShowGenerateEmail}
        furniture={furniture}
        roomsAreas={roomsAreas}
        setTagsValuesDefault={setTagsValuesDefault}
        customTagsValues={customTagsValues}
      />
      {/*       <CalculationTwoDaysPayment calculationData={calculationData} /> */}
    </section>
  );
};
