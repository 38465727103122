import React, { useState, useContext, useCallback, useEffect } from 'react';
import { SocketContext } from '../../context/socket';
// Components
import { CalculationOOSOneDayParams } from '../calculation-oos-one-day-params/Calculation-oos-one-day-params';
import { BtnApplyNewRate } from '../btn-apply-new-rate/Btn-apply-new-rate';
import { CalculationOneDayPayment } from '../calculation-one-day-payment/Calculation-one-day-payment';

// Utils
/* import {
  calculateOfMovers,
  totalCubicFt,
  numberOfTrucks,
  totalTrucks
} from '../../util/calculation-formulas'; */
// import { boxesKey } from '../../util/const';
// Styles
import styles from './CalculationOOSOneDayResult.module.css';
import { BtnApplyNewTemplate } from '../btn-apply-new-template/Btn-apply-new-template';

export const CalculationOOSOneDayResult = (props) => {
  const { calculationData, sameDepartmentSettings, sameOOSDirectionSettings, applyNewRates, sameUnpDepartmentSettings, formChange, resetLastTarget, checkboxValueChange, editorState,
    tags,
    setPreviewText, setOptionsPreviewText,
    popUpShown, oldTemplateWasUsed, applyNewTemplate,setShowGenerateEmail, furniture, roomsAreas, setTagsValuesDefault, customTagsValues } = props;
  const commonValues = { ...calculationData.commonValues };


  const oos1day = { ...calculationData.oos1day };
  const department = { ...calculationData.department };
  const unpDepartment = { ...calculationData.unpDepartment };
  const outOfState = { ...calculationData.outOfState };
  const selectedMiscCheckMarks = calculationData.selectedMiscCheckMarks
  const isExpanded = false;
  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Calculation - OOS - 1 day - Packing/No packing</h2>
      {(!sameDepartmentSettings || !sameOOSDirectionSettings || !sameUnpDepartmentSettings) && <div className={styles.oldApplyNew}><h2 className={styles.title} style={{ color: "red" }}>Old department or destination settings were used</h2>
        <BtnApplyNewRate
          isActive={true}
          action={applyNewRates}
          name={'Apply new rates'}
        />
      </div>}
      {oldTemplateWasUsed && <div className={styles.oldApplyNew}><h2 className={styles.title} style={{ color: "#0000ff" }}>Old template settings were used</h2>
        <BtnApplyNewTemplate
          isActive={true}
          action={applyNewTemplate}
          name={'Apply new Template'}
        />
      </div>}
      {calculationData &&
        <div className={styles.selectedInfo}>
          {calculationData.calculationNumber && <p className={styles.calculationNumber}>{calculationData.calculationNumber}</p>}
          {calculationData.department && <p className={styles.departmentName}>{calculationData.department.departmentName}</p>}
          {calculationData.oos1day.firstDayRate && <p className={styles.rateName}>{calculationData.oos1day.firstDayRate}</p>}
        </div>
      }
      <div className={styles.selectedInfo}>
        {oos1day.firstDayDate && <p className={styles.calculationDate} style={{ marginRight: '4px' }}>{new Date(oos1day.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' })}</p>}
        {oos1day.secondDayDate && <p className={styles.calculationDate}>{oos1day.firstDayDate && '& '} {new Date(oos1day.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' })}</p>}
      </div>

      <CalculationOOSOneDayParams commonValues={commonValues} oos1day={oos1day} department={department}
        outOfState={outOfState} unpDepartment={unpDepartment}
        selectedMiscCheckMarks={selectedMiscCheckMarks}
        formChange={formChange}
        resetLastTarget={resetLastTarget}
        checkboxValueChange={checkboxValueChange}
        editorState={editorState}
        calculationData={calculationData}
        tags={tags}
        popUpShown={popUpShown}
        setPreviewText={setPreviewText}
        setOptionsPreviewText={setOptionsPreviewText}
        setShowGenerateEmail={setShowGenerateEmail}
        furniture={furniture}
        roomsAreas={roomsAreas}
      />
      {/*       <CalculationOneDayPayment calculationData={calculationData} /> */}
    </section>
  );
};
