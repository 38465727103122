import { createSlice } from '@reduxjs/toolkit';
import {
  loadFurniture,
  saveFurniture,
  updateFurniture,
} from './furniture-async-thunk';


const initialState = {
  savingStatus: 'idle',
  loadingStatus: 'idle',
  furniture: null,
  errors: null,
};

const furnitureSlice = createSlice({
  name: 'furniture',
  initialState,
  reducers: {
    setFurnitureSavingStatus(state, action) {
      const { status } = action.payload;
      state.savingStatus = status;
    },
    setFurnitureLoadingStatus(state, action) {
      const { status } = action.payload;
      state.loadingStatus = status;
    },
  },
  extraReducers: {
    // saveFurniture extra reducer
    [saveFurniture.pending]: (state, action) => {
      state.savingStatus = 'loading';
    },
    [saveFurniture.rejected]: (state, action) => {
      state.savingStatus = 'failed';
      state.errors = action.error.message;
    },
    [saveFurniture.fulfilled]: (state, action) => {
      state.savingStatus = 'successed';
      state.furniture = action.payload.data;
    },

    // updateDepartmen extra reducer
    [updateFurniture.pending]: (state, action) => {
      state.savingStatus = 'loading';
    },
    [updateFurniture.rejected]: (state, action) => {
      state.savingStatus = 'failed';
      state.errors = action.error.message;
    },
    [updateFurniture.fulfilled]: (state, action) => {
      state.savingStatus = 'successed';
      state.furniture = action.payload.data;
    },

    // loadFurniture extra reducer
    [loadFurniture.pending]: (state, action) => {
      state.loadingStatus = 'loading';
    },
    [loadFurniture.rejected]: (state, action) => {
      state.loadingStatus = 'failed';
      state.errors = action.error.message;
    },
    [loadFurniture.fulfilled]: (state, action) => {
      state.loadingStatus = 'successed';
      state.furniture = action.payload.data;
    }
  },
});

export const { setFurnitureSavingStatus, setFurnitureLoadingStatus, setFurnitureRemovingStatus } = furnitureSlice.actions;

export default furnitureSlice.reducer;

export const getFurnitureSavingStatus = (state) => state.furniture.savingStatus;

export const getFurnitureLoadingStatus = (state) => state.furniture.loadingStatus;

export const getFurniture = (state) => state.furniture.furniture;
