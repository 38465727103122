// Third party libraries
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useState } from 'react';
// Components
import { DepartmentRadiobuttons } from '../department-radiobuttons/Department-radiobuttons';
import { DepartmentRadiobutons } from '../department-radiobutons/Department-radiobutons';
import { RateTypeRadiobuttons } from '../rateType-radiobuttons/RateType-radiobuttons';
import { DepartmentTitle } from '../department-title/Department-title';
import { Icon } from '../Icon';
import { RateFieldset } from '../rate-fieldset/Rate-fieldset';
import { MinHoursLabel } from '../min-hours-label/Min-hours-label';
// Utils
import { IconNames } from '../../util/const';
//Styles
import styles from './CuFtDepartmentRates.module.css';
import { DepartmentLabel } from '../department-label/Department-label';
import { DepartmentLabelsec } from '../department-labelsec/Department-labelsec';
import { removeByIndex } from '../../util/utils';


export const CuFtDepartmentRates = (props) => {
  const {
    rates,
    setDepartmentFormValue,
    lastTarget,
    setLastTarget,
    setCursorPosition,
    cursorPosition,
    minimumHours,
    minimumCuFt
  } = props;

  const [isSameRate, setSameRate] = useState(false);
  const [isSameTruck, setSameTruck] = useState(false);
  const [isSameDiscount, setSameDiscount] = useState(false);

  const addRateBtnClick = (index, typeRate, isSameRate, isSameTruck, isSameDiscount) => {


    /* 
          
            deductPayment0: '',
            extraMover0: '',
            extraTruck0: '',
            longDistanceExtra0: '',
            longDistanceExtraType0: 'perJob',//perJob, perTruck
          
        
            pricesPerCuFt0: [{
              cubicFtRange0: '',
              operator0: '===',// '===', '<', '>' '<=', '>='
              pricePerCuFt0: '',
            }
            ],
        
    */

    const newRate = typeRate !== 'deduct' ? {
      [`extraAmountType${index}`]: 'perJob',
      [`rateName${index}`]: '',
      [`cardPayment${index}`]: isSameRate ? rates.ratesList[0].cardPayment0 : '',

      [`cashDiscount${index}`]: '',
      [`deductAmount${index}`]: '',
      [`extraAmount${index}`]: '',


      [`pricesPerCuFt${index}`]: isSameRate ? rates.ratesList[0].pricesPerCuFt0 : [{
        cubicFtRange0: '',
        operator0: '===',// '===', '<', '>' '<=', '>='
        pricePerCuFt0: '',
      }
      ],


    } :
      {
        [`extraAmountType${index}`]: 'perJob',
        [`rateName${index}`]: '',
        [`hourlyRate${index}`]: isSameRate ? rates.ratesList[0].hourlyRate0 : '',
        [`cashDiscount${index}`]: isSameDiscount ? rates.ratesList[0].cashDiscount0 : '',
        [`extraMover${index}`]: isSameRate ? rates.ratesList[0].extraMover0 : '',
        [`extraTruck${index}`]: isSameTruck ? rates.ratesList[0].extraTruck0 : '',

      };
    setDepartmentFormValue('rates.ratesList', 'push', newRate);
  };

  const removeRateBtnClick = (index) => {
    setDepartmentFormValue(`rates.ratesList.${rates.ratesList.length - 1}`, 'del') //{rates.ratesList.splice(index, 1)}
  }

  const onChangeCalcMethod = (evt) => {
    const typeValue = evt.target.value === 'true' ? 'discount' : 'deduct';
    setDepartmentFormValue('rates.calcMethod', 'set', typeValue);
    if (lastTarget) {
      setLastTarget(undefined);
    }
  };

  const onChangeRateType = (evt) => {
    const typeValue = evt.target.value === 'true' ? 'calculated' : 'custom';
    setDepartmentFormValue('rates.rateType', 'set', typeValue);
    if (lastTarget) {
      setLastTarget(undefined);
    }
  };

  const setRatesValue = (evt, index) => {
    setLastTarget(evt.target.name);
    setCursorPosition(evt.target.selectionStart)
    setDepartmentFormValue(`rates.ratesList.${index}.${evt.target.name}`, 'set', evt.target.value);
  };

  const copyCustomRateBtnClick = (evt, rateIndex, increaseCustomRatesBy) => {
    evt.preventDefault();
    if (lastTarget) {
      setLastTarget(undefined);
    }

    let newCustomRatesList = rates.ratesList[rateIndex][`customRatesList${rateIndex}`].map((customRate, index) => {
      let currentCustomRate = customRate[`customHourlyRate${index}`];
      if (currentCustomRate) {
        return { ...customRate, [`customHourlyRate${index}`]: Number(currentCustomRate) + Number(increaseCustomRatesBy) }
      }
      else {
        return { ...customRate, [`customHourlyRate${index}`]: 0 }
      }
    })

    let newRateIndex = rateIndex + 1;
    let newRate = {
      [`rateName${newRateIndex}`]: '',
      [`isArchived${newRateIndex}`]: false,
      [`cardPayment${newRateIndex}`]: '',
      [`deductPayment${newRateIndex}`]: '',
      [`extraMover${newRateIndex}`]: '',
      [`extraTruck${newRateIndex}`]: '',
      [`cashDiscount${newRateIndex}`]: '',
      [`hourlyRate${newRateIndex}`]: "",
      [`customRatesList${newRateIndex}`]: newCustomRatesList,
    }
    setDepartmentFormValue('rates.ratesList', 'push', newRate);
  }

  const onChangeSameRate = (evt) => {
    switch (evt.target.value) {
      case 'true':
        setSameRate(true);
        break;
      case 'false':
        setSameRate(false);
        break;
      default:
        break
    }
  };

  const onIsArchivedChange = (evt, index) => {
    setRatesValue({ target: { value: evt.target.checked, name: evt.target.name } }, index)
  }


  return (
    <section >
      <div className={styles.titleContainer}>
        <h3 className={styles.title1}>Rate/ Cu Ft</h3>
        <div style={{ display: 'flex' }}>

          <div className={styles.vl}></div>
          <MinHoursLabel
            style={{ width: '300px' }}
            inputId={'minimumHours'}
            name={'minimumHours'}
            placeholder={'ex. 3'}
            value={minimumHours}
            title={'Minimum hours'}
            changeValue={(evt) => setDepartmentFormValue(`minimumHours`, 'set', evt.target.value)}
            inputType='number'
            required
          />
          <MinHoursLabel
            style={{ width: '300px' }}
            inputId={'minimumCuFt'}
            name={'minimumCuFt'}
            placeholder={'ex. 3'}
            value={minimumCuFt}
            title={'Minimum cu ft'}
            changeValue={(evt) => setDepartmentFormValue(`minimumCuFt`, 'set', evt.target.value)}
            inputType='number'
            required
          />
        </div>
      </div>
      <DepartmentRadiobutons
        title={'Calculation method'}
        style={{ marginLeft: "0" }}
        name={'calcMethod'}
        isChecked={rates.calcMethod === 'discount'}
        onChangeValue={onChangeCalcMethod}
        firstValue={'cash discount % from total'}
        secondValue={'deduct $/hr for cash payment'}
      />
      {
        rates.ratesList.map((rateCuFt, index) => {
          return <div className={styles.rateContainer}>
            <legend className={styles.rateName}>Rate #{index + 1}</legend>
            <div className={styles.flexDiv}>
              <DepartmentLabelsec
                inputId={`rate_${index + 1}_name`}
                index={index}
                name={`rateName${index}`}
                title={'Name'}
                placeholder={'Name of the rate'}
                value={rateCuFt[`rateName${index}`]}
                changeValue={(evt) => {
                  setRatesValue(evt, index)
                }}
                isFocused={lastTarget === `rateName${index}`}
                cursorPosition={cursorPosition}
              />
              <div>
                <input type='checkbox' checked={rateCuFt[`isArchived${index}`]} name={`isArchived${index}`} onChange={(evt) => onIsArchivedChange(evt, index)} /> Archive rate
              </div>
            </div>
            {
              rates.calcMethod === 'deduct' &&
              <DepartmentLabel
                inputId={`${index + 1}_card_payment`}
                index={index}
                name={`deductPayment${index}`}
                title={'Deduct amount for CASH'}
                placeholder={'ex. 10'}
                value={rateCuFt[`deductPayment${index}`]}
                changeValue={(evt) => setRatesValue(evt, index)}
                valueType={'$'}
                isFocused={lastTarget === `deductPayment${index}`}
                pattern="[0-9]*"
                cursorPosition={cursorPosition}
              />
            }
            {
              rates.calcMethod === 'discount' &&
              <DepartmentLabel
                inputId={`${index + 1}_cash_discount`}
                index={index}
                name={`cashDiscount${index}`}
                title={'Discount for CASH PAYMENT (%)'}
                placeholder={'ex. 10'}
                value={rateCuFt[`cashDiscount${index}`]}
                changeValue={(evt) => setRatesValue(evt, index)}
                isFocused={lastTarget === `cashDiscount${index}`}
                pattern="[0-9]*"
                cursorPosition={cursorPosition}
              />
            }
            <section className={styles.rateSection}>
              <div style={{ display: 'flex' }}>
                <DepartmentLabel
                  inputId={`${index + 1}_extra_amount`}
                  index={index}
                  name={`extraAmount${index}`}
                  title={'Extra amount'}
                  placeholder={'ex. 40'}
                  value={rateCuFt[`extraAmount${index}`]}
                  changeValue={(evt) => setRatesValue(evt, index)}
                  valueType={'$'}
                  isFocused={lastTarget === `extraAmount${index}`}
                  pattern="[0-9]*"
                  cursorPosition={cursorPosition}
                />
                <label className={styles.optionsContainer}>
                  <p className={styles.radiobtnContainer}>
                    <input
                      style={{ marginLeft: "35px" }}
                      type='radio'
                      value='perJob'
                      name={`extraAmountType${index}`}
                      checked={rateCuFt[`extraAmountType${index}`] === 'perJob'}
                      onChange={(evt) => setRatesValue(evt, index)}
                    />
                    Per Job
                    <input
                      type='radio'
                      value='perTruck'
                      name={`extraAmountType${index}`}
                      checked={rateCuFt[`extraAmountType${index}`] === 'perTruck'}
                      onChange={(evt) => setRatesValue(evt, index)}
                    />
                    Per Truck
                  </p>
                </label>
              </div>

              <span style={{ fontSize: '1.1em' }}>Price per cu ft</span>
              {
                rateCuFt[`pricesPerCuFt${index}`].map((pricePerCuFt, pcftIndex) => {
                  return (
                    <div className={styles.distanceContainer} key={`pcft${pcftIndex}`}>
                      <div style={{ display: 'flex' }}>


                        $<input
                          className={styles.input}
                          type='number'
                          placeholder='1'
                          name={`pricePerCuFt${pcftIndex}`}
                          value={pricePerCuFt[`pricePerCuFt${pcftIndex}`]}
                          onChange={(evt) => setDepartmentFormValue(`rates.ratesList.${index}.pricesPerCuFt${index}.${pcftIndex}.${evt.target.name}`, 'set', evt.target.value)}
                        ></input> /cu ft IF
                        <select
                          name={`operator${pcftIndex}`}
                          value={pricePerCuFt[`operator${pcftIndex}`]}
                          onChange={(evt) => setDepartmentFormValue(`rates.ratesList.${index}.pricesPerCuFt${index}.${pcftIndex}.${evt.target.name}`, 'set', evt.target.value)}
                          className={styles.custom_select}
                        >
                          <option value='==='>=</option>
                          <option >{'>'}</option>
                          <option>{'<'}</option>
                          <option>{'<='}</option>
                          <option>{'>='}</option>
                        </select>
                        <input
                          type='number'
                          className={styles.input}
                          placeholder='1400'
                          name={`cubicFtRange${pcftIndex}`}
                          value={pricePerCuFt[`cubicFtRange${pcftIndex}`]}
                          onChange={(evt) => setDepartmentFormValue(`rates.ratesList.${index}.pricesPerCuFt${index}.${pcftIndex}.${evt.target.name}`, 'set', evt.target.value)}
                        ></input>
                        cu ft
                        <button
                          className={styles.removeExtraBtnList}
                          onClick={
                            (evt) => {
                              evt.preventDefault();
                              let newPricesPerCuFt = removeByIndex([...rateCuFt[`pricesPerCuFt${index}`]], pcftIndex)
                              setDepartmentFormValue(`rates.ratesList.${index}.pricesPerCuFt${index}`, 'set', newPricesPerCuFt)
                            }
                          }
                        >
                          <Icon name={IconNames.MINUS} />
                        </button>
                      </div>

                    </div>
                  )
                })}


              <div className={styles.buttonContainer} style={{ left: "10px", position: "relative" }}>
                <button
                  className={styles.extraBtn}
                  onClick={
                    (evt) => {
                      evt.preventDefault();
                      const rateIndex = rateCuFt[`pricesPerCuFt${index}`].length;
                      setDepartmentFormValue(`rates.ratesList.${index}.pricesPerCuFt${index}`, 'push',
                        { [`cubicFtRange${rateIndex}`]: '', [`operator${rateIndex}`]: '===', [`pricePerCuFt${rateIndex}`]: '', }
                      );
                    }
                  }
                  style={{ marginTop: '5px' }}
                >
                  Add price per cu ft
                  <Icon name={IconNames.PLUS} />
                </button>
              </div>


            </section>
          </div>
        })
      }

      {rates.ratesList.length > 1 &&
        <button
          className={styles.removeRateBtn}
          onClick={(evt) => {
            evt.preventDefault();
            removeRateBtnClick()
          }}
        >
          <Icon name={IconNames.MINUS} />
          Remove rate
        </button>
      }
      <br />
      <button
        className={styles.addRateBtn}
        onClick={
          (evt) => {
            evt.preventDefault();
            addRateBtnClick(rates.ratesList.length, rates.calcMethod, isSameRate, isSameTruck, isSameDiscount);
          }
        }
      >
        <Icon name={IconNames.PLUS} />
        Add rate
      </button>


    </section>
  );
};

CuFtDepartmentRates.propTypes = {
  rates: PropTypes.object.isRequired,
  setDepartmentFormValue: PropTypes.func.isRequired,
  lastTarget: PropTypes.string,
  setLastTarget: PropTypes.func.isRequired,
  setCursorPosition: PropTypes.func,
  cursorPosition: PropTypes.number
};
