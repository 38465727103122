//Third party libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Components
import { TemplateForm } from '../template-form/Template-form';
import { Loading } from '../loading/Loading';
// Functions for work with store
import {
  getTemplate,
  getTemplateLoadingStatus,
} from '../../clientStore/templateSlice/template-slice';
import { loadTemplate } from '../../clientStore/templateSlice/template-async-thunk';

export const TemplateEdit = ({ updatePageTitle, type }) => {
  const dispatch = useDispatch();
  const loadingStatus = useSelector(getTemplateLoadingStatus);
  const template = useSelector(getTemplate);
  const params = useParams();

  const id = params.templateId;

  useEffect(() => {
    if (loadingStatus === 'idle') {
      dispatch(loadTemplate(id));
    }
  }, [dispatch, id, loadingStatus]);

  updatePageTitle(('Template' + ((template?.templateName) ? ' - ' + template?.templateName : '').toString()))

  return (
    <React.Fragment>
      {loadingStatus !== 'successed' && <Loading />}
      {loadingStatus === 'successed' && <TemplateForm templateData={template} formType={'edit'} templateId={id} type={type} />}
    </React.Fragment>
  );
}