
// Components
import { Icon } from '../Icon';
import useCollapse from 'react-collapsed'
// Utils
import { IconNames } from '../../util/const';

import styles from './CustomTagItem.module.css';
import { useRef } from 'react';



export const CustomTagItem = ({ tag, defaultValue, customValue, onFormValueChange, calculationType }) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({})
  const handleReset = (evt) => {
    evt.preventDefault();
    onFormValueChange(`customTagsValues.${calculationType}.${tag.tagName}`, 'set', defaultValue);
  }
  const textareaRef = useRef(null);


  const setTAValue = (evt, conditionIndex, textareaIndex) => {
    if (evt.key === 'Enter') {
      const textarea = textareaRef.current;
      const { selectionStart, selectionEnd, value } = textarea;
      // Get the text before and after the current selection
      const textBeforeSelection = value.substring(0, selectionStart);
      const textAfterSelection = value.substring(selectionEnd, value.length);
      // Create the updated value with the line break at the cursor position
      const updatedValue = `${textBeforeSelection}<br/>${textAfterSelection}`;
      // Update the textarea value and restore the cursor position
      textarea.value = updatedValue;
      textarea.selectionStart = textarea.selectionEnd = selectionStart + 5; // Set cursor after the inserted line break
      onFormValueChange(`customTagsValues.${calculationType}.${tag.tagName}`, 'set', evt.target.value);
    }
  };

  return (
    <div style={{ borderBottom: '1px solid #ccc', }}>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '5px' }}>
        <span style={{ fontSize: '16px', fontWeight: 'bold' }}>{tag.tagName}</span>
        <button {...getToggleProps()} className={styles.btnSubmit}>
          ㅤ
          <i className={isExpanded ? styles.up : styles.down}></i>
        </button>
      </div>
      <div {...getCollapseProps()} >
        <div style={{ padding: '5px' }}>
          <button onClick={handleReset} className={styles.btnReset}>Reset</button>
          <textarea
            ref={textareaRef}
            value={customValue}
             onChange={(evt) => {
               onFormValueChange(`customTagsValues.${calculationType}.${tag.tagName}`, 'set', evt.target.value);
             }}
            style={{ display: 'block', width: '100%', height: '100px' }}
            onKeyDown={(evt) => setTAValue(evt)}
          />
        </div>
      </div>
    </div>
  );
};
