// Third party libraries
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch
} from 'react-router-dom';
// Components
import { Footer } from '../Components/footer/Footer';
import { Header } from '../Components/header/Header';
import { PageHeader } from '../Components/page-header/Page-header';
import { PageMenu } from '../Components/page-menu/Page-menu';
// Functions for work with state
import { getUserRole, checkAuth, getCompanyId } from '../clientStore/authSlice/auth-slice';

//Utils functions
import { IconNames } from '../util/const';
import { PrivateRoute } from '../util/private-route';
import { getSectionNames } from '../util/utils';
import { useLocation } from 'react-router-dom';
import { FurnitureForm } from '../Components/furniture-form/Furniture-form';
import { loadFurniture } from '../clientStore/furnitureSlice/furniture-async-thunk';
import {
  getFurniture,
  getFurnitureLoadingStatus,
} from '../clientStore/furnitureSlice/furniture-slice';
import { getCompany, getCompanyLoadingStatus } from '../clientStore/companySlice/company-slice';
import { loadCompany } from '../clientStore/companySlice/company-async-thunk';
import { Loading } from '../Components/loading/Loading';

export const Furniture = ({ userName }) => {
  const dispatch = useDispatch();
  const userRole = useSelector(getUserRole);
  const SectionNames = getSectionNames(userRole);
  const loadingStatus = useSelector(getFurnitureLoadingStatus);
  const furniture = JSON.parse(JSON.stringify(useSelector(getFurniture)));
  const companyLoadingStatus = useSelector(getCompanyLoadingStatus);
  let formType = furniture === null ? 'new' : 'edit';
  const companyId = useSelector(getCompanyId);
  const company = useSelector(getCompany);
  const furnitureId = company ? company.furnitureId : null;

  useEffect(() => {
    if (companyLoadingStatus === 'idle') {
      dispatch(loadCompany(companyId));
    }
  }, [dispatch, companyId, companyLoadingStatus]);


  const settingsHeaderBtn = [
    /*   {
        name: 'Change password'
      } */
  ];

  useEffect(() => {
    if (loadingStatus === 'idle') {
      if (furnitureId) {
        dispatch(loadFurniture(furnitureId));
      }
    }
  }, [dispatch, furnitureId, loadingStatus]);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(checkAuth());
    }
  }, [dispatch]);


  return (
    <div className='container'>
      <Header
        isLoginBtnShow={false}
      />
      <main className='main'>
        <PageHeader
          titleIcon={IconNames.USER}
          title={userName}
          btns={settingsHeaderBtn}
          menuItems={SectionNames}
        />
        {loadingStatus !== 'successed' && <Loading />}
        {loadingStatus === 'successed' && <FurnitureForm formType={formType} furnitureData={furniture} />}
      </main>
      {/* <Footer /> */}
    </div>
  );
};