// Third party libraries
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
// Styles
import styles from './NumberInputEC.module.css';

export const NumberInputEC = (props) => {
  const {
    inputId,
    className,
    inputType,
    placeholder,
    name,
    value,
    onChange,
    autoFocus,
    required,
    disabled,
    onWheel,
    cursorPosition,
    
  } = props;

  const inputRef = useRef(null);

  const handleFocus = () => {
    try {
      if (inputRef?.current) {
        inputRef.current.select();
      }
    } catch (err) { console.log(err) }
  };
  return (

    <input
      ref={inputRef}
      id={inputId}
      className={className}
      type={inputType}
      placeholder={placeholder}
      name={name}
      value={value}
      pattern="[0-9]*\.?[0-9]*"
      onChange={(e) => e.target.validity.valid ? onChange(e) : (e.target.value === "") ? onChange(e) : ""}
      autoFocus={autoFocus}
      onFocus={e => {
        if (cursorPosition) {
          e.target.selectionEnd = cursorPosition;
          e.target.selectionStart = cursorPosition;
        }
        handleFocus()
      }}
      onKeyDown={evt => { (evt.code === "Enter") && evt.preventDefault() }}
      required={required}
      disabled={disabled}
      onWheel={onWheel}
      onBlur={(evt) => { }}
    />
  );
};

// Variable type settings
NumberInputEC.propTypes = {

};
