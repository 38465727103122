import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { SocketContext } from '../../context/socket';
import useCollapse from 'react-collapsed'

import { getCheckMarkLabelById, getCurrentRatePercent, getHigherNumberOfHours, getLowerNumberOfHours, getLowerNumberOfHoursValueOnly, getTagsOptions, replaceSpaces } from '../../util/utils';

// Styles
import styles from "./TemplateLongTwoDayParams.module.css";
//Components
import { TemplateSeparateDayView } from '../template-separate-day-view/Template-separate-day-view';
import { useDispatch, useSelector } from 'react-redux';
import { getUserName } from '../../clientStore/authSlice/auth-slice';


import { CalculationTabTwoDays150Values } from '../calculation-tab-two-days-150-values/Calculation-tab-two-days-150-values';
import { CalculationTabLongOneDayValues } from '../calculation-tab-long-one-day-values/Calculation-tab-long-one-day-values';
import { TemplateSeparateDayLongView } from '../template-separate-day-long-view/Template-separate-day-long-view';
import { CalculationTabLongTwoDayValues } from '../calculation-tab-long-two-day-values/Calculation-tab-long-two-day-values';
import { TemplatesExtraOptionsResults } from '../templates-extra-options-results/Templates-extra-options-results';
import { setTotalFragileBoxes } from '../../clientStore/calculatedParamsSlice/calculated-params-slice';
import { CalculationLongTwoDayParams } from '../calculation-long-two-day-params/Calculation-long-two-day-params';

export const TemplateLongTwoDayParams = ({
  highLightItem,
  company,
  department,
  templateForm,
  rates,
  onCalcFormValueChange,
  resetLastTarget,
  setOptions,
  users,
  departments,
  renderLongDistancesOptions,
  onChangeLongDistanceSelect,
  onChangeUnpDepartmentSelect,
  unpRates,
  longDistance,
  longdistances,
  checkMarkCheckboxValueChange,
  lastTarget,
  setLastTarget
}) => {
  const dispatch = useDispatch();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const userName = useSelector(getUserName);
  const scrollRefs = useRef([]);
  const calculationForm = templateForm.templateCalcObj;
 
  const scrollKeys = [
    'username', 'department', 'truckloads', 'additionalStopsCount', 'movers',
    'moversToLoad', 'moversToDrivesAndUnload',
    'rate', 'rateCash', 'rateCashLoad', 'rateCardLoad', 'truckfee',
    'rateCardUnload', 'rateCashUnload', 'trucks', 'shuttle', 'doubleDrive', 'twoTrips',
    'biggestOrOk', 'lowerNumberOfHours', 'higherNumberOfHours', 'packingKit', 'packingType',
    'packingKitWithTax', 'smallBox', 'mediumBox', 'largeBox', 'rollOfPaper', 'rollOfBubble',
    'liftGate', 'hardwoodFloorCovers', 'carpetFloorCovers', 'notToExceedPrice',
    'notToExceedPricePercent', 'cashDiscount', 'sameDayUnpack', 'separateDayUnpack',
    'trucksUnpackingSeparateDay', 'moversUnpackingSeparateDay', 'unpackingSeparateDayLowerhours',
    'unpackingSeparateDayHigherhours', 'totalCardUnpackingSeparateDay', 'totalCashUnpackingSeparateDay',
    'rateCashUnpackingSeparateDay', 'rateCardUnpackingSeparateDay', 'truckFeeUnpackingSeparateDay',
    'truckFeeUnpackingSeparateDay', 'combinedMileage', 'calculationNumber', 'trucksPercent', 'truckloadsRoundDown'];
  if (company) {
    if (company.extraOptCharges) {
      company.extraOptCharges.forEach((charge, index) => {
        scrollKeys.push(`chIdName_${index}`)
        scrollKeys.push(`chIdAmount_${index}`)
      })
    }
    if (company.additProcedures) {
      company.additProcedures.forEach((procedure, index) => {
        scrollKeys.push(`pIdName_${index}`)
        scrollKeys.push(`pIdCost_${index}`)
        scrollKeys.push(`pIdQty_${index}`)
      })
    }
    if (company.miscCheckMarks) {
      company.miscCheckMarks.forEach((checkMark, index) => {
        scrollKeys.push(`mChId_${index}`)
      })
    }
  }
  scrollKeys.forEach(element => {
    scrollRefs.current[element] = React.createRef()
  });

  const commonValues = calculationForm.commonValues;
  const longTwoDaysPacking = calculationForm.longTwoDaysPacking;
  const unpDepartment = calculationForm.unpDepartment;



  const socket = useContext(SocketContext);

  useEffect(() => {

  }, [commonValues, longTwoDaysPacking, department])


  const sepCheckboxValueChange = (evt) => {
    resetLastTarget();

    switch (evt.target.value) {
      case 'true':
        return onCalcFormValueChange(`commonValues.${evt.target.name}`, 'set', false);
      case 'false':
        return onCalcFormValueChange(`commonValues.${evt.target.name}`, 'set', true);
      default:
        break;
    }
  };


  useEffect(() => {
    if (highLightItem) {
      try {
        scrollRefs.current[highLightItem].current.scrollIntoView({ block: 'nearest' });
      }
      catch {
      }
    }
  }, [highLightItem]);


  useEffect(() => {
    let currentOptions = getTagsOptions({
      company: company, templateCalcType: templateForm.templateCalcType, users: users, departments: departments, longdistances: longdistances, calculation: templateForm.templateCalcObj,
      calculatedParams: {}
    });

    setOptions(currentOptions);
  }, [templateForm.tags, templateForm.templateCalcObj, users]);


  return (
    <div className={styles.parentCont}>

      {(calculationForm && Object.keys(calculationForm).length) ?
        <CalculationTabLongTwoDayValues
          renderLongDistancesOptions={renderLongDistancesOptions}
          rates={(rates.length > 0) ? rates : (calculationForm.department && calculationForm.department.rates) ? calculationForm.department.rates.ratesList : []}
          formData={calculationForm}
          formChange={onCalcFormValueChange}
          resetLastTarget={resetLastTarget}
          onChangeLongDistanceSelect={onChangeLongDistanceSelect}
          sepCheckboxValueChange={sepCheckboxValueChange}
          departments={departments}
          unpRates={(unpRates.length > 0) ? unpRates : (calculationForm.unpDepartment && calculationForm.unpDepartment.rates) ? calculationForm.unpDepartment.rates.ratesList : []}
          unpDepartment={calculationForm.unpDepartment}
          onChangeUnpDepartmentSelect={onChangeUnpDepartmentSelect}
          templatePage
          checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
          lastTarget={lastTarget}
          setLastTarget={setLastTarget}
        />
        : ""}

      <div>
        <h3 className={`${styles.title} ${(highLightItem === 'username') ? styles.highLighted : ""}`} ref={scrollRefs?.current['username']}>{userName}</h3>
        <h3 className={`${styles.title}`}><span className={`${(highLightItem === 'calculationNumber') ? styles.highLighted : ""}`} ref={scrollRefs?.current['calculationNumber']}>{calculationForm.calculationNumber} </span><span className={`${(highLightItem === 'department') ? styles.highLighted : ""}`} ref={scrollRefs?.current['department']}>{calculationForm && calculationForm.department && calculationForm.department.departmentName}</span>{/*  <span className={`${(highLightItem === 'rate') ? styles.highLighted : ""}`}>XP</span> */}</h3>
        <div className={`${styles.title}`}>
          <p className={styles.calculationDate}>

            {(calculationForm && Object.keys(calculationForm).length && calculationForm.longTwoDaysPacking.firstDayDate) ? <>
              <span className={`${(highLightItem === 'day1weekday') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1weekday']}>{new Date(calculationForm.longTwoDaysPacking.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' })}, </span>
              <span className={`${(highLightItem === 'day1month') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1month']}>{new Date(calculationForm.longTwoDaysPacking.firstDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day1date') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1date']}>{new Date(calculationForm.longTwoDaysPacking.firstDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day1year') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day1year']}>{new Date(calculationForm.longTwoDaysPacking.firstDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })}</span>
            </> : ""}
            {calculationForm && Object.keys(calculationForm).length && longTwoDaysPacking.secondDayDate && longTwoDaysPacking.firstDayDate && ' &  '}
            {(calculationForm && Object.keys(calculationForm).length && calculationForm.longTwoDaysPacking.secondDayDate) ? <>
              <span className={`${(highLightItem === 'day2weekday') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day2weekday']}>{new Date(calculationForm.longTwoDaysPacking.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' })}, </span>
              <span className={`${(highLightItem === 'day2month') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day2month']}>{new Date(calculationForm.longTwoDaysPacking.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day2date') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day2date']}>{new Date(calculationForm.longTwoDaysPacking.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day2year') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day2year']}>{new Date(calculationForm.longTwoDaysPacking.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })}</span> {' '}
            </> : ""}
            {calculationForm && Object.keys(calculationForm).length && calculationForm.longTwoDaysPacking.thirdDayDate && '& '}
            {(calculationForm && Object.keys(calculationForm).length && calculationForm.longTwoDaysPacking.secondDayDate) ? <>
              <span className={`${(highLightItem === 'day3weekday') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day3weekday']}>{new Date(calculationForm.longTwoDaysPacking.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', timeZone: 'UTC' })}, </span>
              <span className={`${(highLightItem === 'day3month') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day3month']}>{new Date(calculationForm.longTwoDaysPacking.secondDayDate).toLocaleDateString('en-us', { month: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day3date') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day3date']}>{new Date(calculationForm.longTwoDaysPacking.secondDayDate).toLocaleDateString('en-us', { day: 'numeric', timeZone: 'UTC' })}</span> /
              <span className={`${(highLightItem === 'day3year') ? styles.highLighted : ""}`} ref={scrollRefs?.current['day3year']}>{new Date(calculationForm.longTwoDaysPacking.secondDayDate).toLocaleDateString('en-us', { year: 'numeric', timeZone: 'UTC' })}</span>
            </> : ""}
          </p>
        </div>
        <CalculationLongTwoDayParams
          commonValues={commonValues}
          longTwoDaysPacking={longTwoDaysPacking}
          department={department}
          longDistance={longDistance}
          unpDepartment={unpDepartment}
          selectedMiscCheckMarks={calculationForm.selectedMiscCheckMarks}
          resetLastTarget={resetLastTarget}
          calculationData={calculationForm}
        />
      </div>
    </div >
  );
};

