import React from 'react';
import styles from './AdjustTime.module.css';

const AdjustTime = ({ name, value, onChange, labelText, disabled }) => {
  return (
    <label className={styles.inputContainer} >
      {labelText}
      <select
        className={styles.commonInput1}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        <option>0</option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>10</option>
      </select>
    </label>
  );
};

export default AdjustTime;