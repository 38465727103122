import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchedData } from '../../util/utils';

export const saveCalculation = createAsyncThunk(
  'calculation/save',
  async (newCalculationData, { rejectWithValue }) => {
    try {
      const response = await fetchedData('calculation/calculation', 'POST', { ...newCalculationData })
      if (response.error) {
        return rejectWithValue(response);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message || 'An unexpected error occurred');
    }
  }
);

export const updateCalculation = createAsyncThunk(
  'calculation/update',
  async (calculationData, { rejectWithValue }) => {
    try {
      const response = await fetchedData('calculation/update', 'POST', calculationData);
      if (response.error) {
        return rejectWithValue(response);
      }
      return response;
    } catch (error) {
      return rejectWithValue(error.message || 'An unexpected error occurred');
    }
  }
);

export const loadCalculation = createAsyncThunk(
  'calculation/getCalculation',
  async (id) => await fetchedData('calculation/getCalculation', 'POST', { id })
);

export const removeCalculation = createAsyncThunk(
  'calculation/remove',
  async (id) => await fetchedData('calculation/remove', 'POST', { id })
);

export const removeCalculationHard = createAsyncThunk(
  'calculation/removeHard',
  async (id) => await fetchedData('calculation/removeHard', 'POST', { id })
);

export const cloneCalculation = createAsyncThunk(
  'calculation/clone',
  async (id) => await fetchedData('calculation/clone', 'POST', { id })
);

export const cloneToSubCalculation = createAsyncThunk(
  'calculation/cloneToSubCalc',
  async (mainCalcId) => await fetchedData('calculation/cloneToSubCalc', 'POST', { mainCalcId })
);

export const restoreCalculation = createAsyncThunk(
  'calculation/restore',
  async (id) => await fetchedData('calculation/restore', 'POST', { id })
);
