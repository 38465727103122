// Third party libraries
import PropTypes from 'prop-types';
// Components
import { DepartmentLabel } from '../department-label/Department-label';
import { DepartmentTitle } from '../department-title/Department-title';
import { Icon } from '../Icon';
// Utils
import { IconNames } from '../../util/const';
// Styles
import styles from './AdditionalServices.module.css';
import { useEffect, useState } from 'react';
import { generateRandomString, removeByIndex } from '../../util/utils';
import { DepartmentRadiobuttons } from '../department-radiobuttons/Department-radiobuttons';

export const AdditionalServices = (props) => {
  const {
    additionalServices,
    changeAdditionalServices,
    clearLastTarget,
    companyCharges,
    setCompanyCharges,
  } = props;


  const addAdditionalServices = (index) => {
    const newAdditionalService = {
      serviceName: '',
      serviceType: 'cuft',// cuft, factor
      pricesPerCuFt: [{
        cubicFtRange0: '',
        factorName0: '',
        operator0: '===',// '===', '<', '>' '<=', '>='
        pricePerCuFt0: '',
      }],
    }
    changeAdditionalServices(`additionalServices.services`, 'push', newAdditionalService);
  }

  const addPlaceTypes = (placeTypesLength) => {
    changeAdditionalServices(`additionalServices.placeTypes`, 'push', { placeTypeName: '' });
  }

  const removeService = (indexToRemove) => {
    let newAdditionalServices = additionalServices.services.filter((_, index) => index !== indexToRemove);
    changeAdditionalServices(`additionalServices.services`, 'set', newAdditionalServices);
  }

  const removeSubPricePerCuFt = (serviceIndex, pcftIndex) => {
    const newAdditionalServices = [...additionalServices.services];
    const newPricesPerCuFt = removeByIndex([...additionalServices.services[serviceIndex].pricesPerCuFt], pcftIndex)
    newAdditionalServices[serviceIndex].pricesPerCuFt = newPricesPerCuFt;
    changeAdditionalServices(`additionalServices.services`, 'set', newAdditionalServices);
  }

  const removePriceType = (indexToRemove) => {
    const placeTypes = [...additionalServices.placeTypes];
    const newPlaceTypes = placeTypes.filter((_, index) => index !== indexToRemove);
    changeAdditionalServices(`additionalServices.placeTypes`, 'set', newPlaceTypes);
  }

  const addSubPricePerCuFt = (serviceIndex, index) => {
    const newAdditionalServices = [...additionalServices.services];
    const newPricePerCuFt = {
      [`cubicFtRange${index}`]: '',
      [`factorName${index}`]: '',
      [`operator${index}`]: '===',// '===', '<', '>' '<=', '>='
      [`pricePerCuFt${index}`]: '',
    }
    newAdditionalServices[serviceIndex].pricesPerCuFt.push(newPricePerCuFt)
    changeAdditionalServices(`additionalServices.services`, 'set', newAdditionalServices);
  }

  const changeSubPricePerCuFtValue = (evt, asIndex, pcftIndex) => {
    changeAdditionalServices(`additionalServices.services.${asIndex}.pricesPerCuFt.${pcftIndex}.${evt.target.name}`, 'set', evt.target.value);
  }

  return (
    <section>
      <DepartmentTitle
        title={'Additional Services'}
      />
      <div>


        {additionalServices.placeTypes.map((placeType, placeTypeIndex) => {
          return (
            <div style={{ display: 'flex', margin: '15px' }}>
              <input
                type='text'
                name='placeTypeName'
                placeholder='placeTypeName'
                value={placeType.placeTypeName}
                className={styles.input}
                onChange={(evt) => changeAdditionalServices(`additionalServices.placeTypes.${placeTypeIndex}.${evt.target.name}`, 'set', evt.target.value)}
              ></input>
              <button
                className={styles.removeExtraBtnList}
                onClick={
                  (evt) => {
                    evt.preventDefault();
                    removePriceType(placeTypeIndex)
                  }
                }
              >
                <Icon name={IconNames.MINUS} />
              </button>
            </div>)
        })}
        <div className={styles.buttonContainer} style={{ left: "10px", position: "relative" }}>
          <button
            className={styles.extraBtn}
            onClick={
              (evt) => {
                evt.preventDefault();
                addPlaceTypes(additionalServices.placeTypes.length)
              }
            }
          >
            Add place type
            <Icon name={IconNames.PLUS} />
          </button>
        </div>
      </div>
      {
        additionalServices && additionalServices.services.map((additionalService, asIndex) => {
          return (
            <div>
              <div style={{ display: 'flex', marginBottom: '10px' }}>
                <input
                  className={styles.input}
                  type='text'
                  name='serviceName'
                  placeholder='serviceName'
                  value={additionalService.serviceName}
                  onChange={(evt) => changeAdditionalServices(`additionalServices.services.${asIndex}.${evt.target.name}`, 'set', evt.target.value)}
                >
                </input>
                <label className={styles.optionsContainer}>
                  <p className={styles.radiobtnContainer}>
                    <input
                      type='checkbox'
                      name='perTruck'
                      checked={additionalService.perTruck}
                      onChange={(evt) => changeAdditionalServices(`additionalServices.services.${asIndex}.${evt.target.name}`, 'set', evt.target.checked)}
                      defaultValue={false}
                    />
                    Per truck
                    <br />
                    <input
                      type='checkbox'
                      name='isCountable'
                      checked={additionalService.isCountable}
                      onChange={(evt) => changeAdditionalServices(`additionalServices.services.${asIndex}.${evt.target.name}`, 'set', evt.target.checked)}
                      defaultValue={false}
                    />
                    Quantity
                    <br />
                    {additionalService.serviceType === 'cuft' && <>
                      <input
                        type='checkbox'
                        name='fixedAmount'
                        checked={additionalService.fixedAmount}
                        onChange={(evt) => changeAdditionalServices(`additionalServices.services.${asIndex}.${evt.target.name}`, 'set', evt.target.checked)}
                        defaultValue={false}
                      />
                      Fixed amount
                    </>}
                  </p>
                </label>
                <label className={styles.optionsContainer}>
                  <p className={styles.radiobtnContainer}>
                    <input
                      style={{ marginLeft: "35px" }}
                      type='radio'
                      value='cuft'
                      name={'serviceType' + asIndex}
                      checked={additionalService.serviceType === 'cuft'}
                      onChange={(evt) => changeAdditionalServices(`additionalServices.services.${asIndex}.serviceType`, 'set', evt.target.value)}
                    />
                    Cu Ft
                    <input
                      type='radio'
                      value='factor'
                      name={'serviceType' + asIndex}
                      checked={additionalService.serviceType === 'factor'}
                      onChange={(evt) => changeAdditionalServices(`additionalServices.services.${asIndex}.serviceType`, 'set', evt.target.value)}
                    />
                    Factor
                  </p>
                </label>
                <button
                  className={styles.removeExtraBtnList}
                  onClick={(evt) => { evt.preventDefault(); removeService(asIndex) }}
                  style={{ marginLeft: '10px' }}
                >
                  <Icon name={IconNames.MINUS} />
                  Remove service
                </button>
              </div>

              {additionalService.pricesPerCuFt.map((pricePerCuFt, pcftIndex) => {
                return (
                  <div className={styles.distanceContainer} key={`pftii${pcftIndex}`}>
                    <div style={{ display: 'flex' }}>{/* array add/remove(remove per item) */}
                      $<input
                        className={styles.inputSecond}
                        type='number'
                        placeholder='1'
                        name={`pricePerCuFt${pcftIndex}`}
                        value={pricePerCuFt[`pricePerCuFt${pcftIndex}`]}
                        onChange={(evt) => changeSubPricePerCuFtValue(evt, asIndex, pcftIndex)}
                      ></input> IF
                      <select
                        name={`operator${pcftIndex}`}
                        value={
                          (pricePerCuFt[`operator${pcftIndex}`] !== '===' && pricePerCuFt[`operator${pcftIndex}`] !== '!=') && additionalService.serviceType !== 'cuft'
                            ? '==='
                            : pricePerCuFt[`operator${pcftIndex}`]
                        }
                        onChange={(evt) => changeSubPricePerCuFtValue(evt, asIndex, pcftIndex)}
                        className={styles.custom_select}
                      >
                        <option value='==='>=</option>
                        {additionalService.serviceType === 'cuft' && <option >{'>'}</option>}
                        {additionalService.serviceType === 'cuft' && <option>{'<'}</option>}
                        {additionalService.serviceType === 'cuft' && <option>{'<='}</option>}
                        {additionalService.serviceType === 'cuft' && <option>{'>='}</option>}
                        <option>{'!='}</option>
                      </select>

                      {(additionalService.serviceType === 'cuft') && <><input
                        type='number'
                        placeholder='1400'
                        name={`cubicFtRange${pcftIndex}`}
                        value={pricePerCuFt[`cubicFtRange${pcftIndex}`]}
                        onChange={(evt) => changeSubPricePerCuFtValue(evt, asIndex, pcftIndex)}
                        className={styles.inputSecond}
                      />
                        cu ft
                      </>}
                      {(additionalService.serviceType === 'factor') &&
                        <select
                          name={`factorName${pcftIndex}`}
                          value={pricePerCuFt[`factorName${pcftIndex}`]}
                          onChange={(evt) => changeSubPricePerCuFtValue(evt, asIndex, pcftIndex)}
                          className={styles.custom_selectSecond}
                        >
                          <option value=''>None</option>
                          {additionalServices.placeTypes.map((placeType, placeTypeIndex) => {
                            return <option value={placeType.placeTypeName}>{placeType.placeTypeName}</option>
                          })}
                        </select>}

                      {/* <input type='text' 
                       
                     onChange={(evt) => changeSubPricePerCuFtValue(evt, asIndex, pcftIndex)} /> */}


                      <button
                        className={styles.removeExtraBtnList}
                        onClick={
                          (evt) => {
                            evt.preventDefault();
                            removeSubPricePerCuFt(asIndex, pcftIndex)
                          }
                        }
                      >
                        <Icon name={IconNames.MINUS} />
                      </button>
                    </div>

                  </div>
                )
              })}
              <div className={styles.buttonContainer} style={{ left: "10px", position: "relative" }}>
                <button
                  className={styles.extraBtn}
                  onClick={
                    (evt) => {
                      evt.preventDefault();
                      addSubPricePerCuFt(asIndex, additionalService.pricesPerCuFt.length)
                      /*   addPricePerCuFt(formValue.pricesPerCuFt.length); */
                    }
                  }
                  style={{ marginTop: '10px' }}
                >
                  {(additionalService.serviceType === 'cuft') ? 'Add price per cu ft' : 'Add amount'}
                  <Icon name={IconNames.PLUS} />
                </button>
              </div>
            </div>)
        })}


      <br />
      <div style={{ display: 'block' }}>

        <div className={styles.buttonContainer} style={{ left: "10px", position: "relative" }}>
          <button
            className={styles.extraBtn}
            onClick={
              (evt) => {
                evt.preventDefault();
                addAdditionalServices(additionalServices.services.length);
              }
            }

          >
            Add service
            <Icon name={IconNames.PLUS} />
          </button>
        </div>


      </div>
    </section>
  );
}

AdditionalServices.propTypes = {
  changeAdditionalServices: PropTypes.func.isRequired,
  clearLastTarget: PropTypes.func.isRequired,
  companyCharges: PropTypes.array,
}
