// Third party libraries
import { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
// Components
import { CalculationCommonLabel } from '../calculation-common-label/Calculation-common-label';
import { CalculationExtraStop } from '../calculation-extra-stop/Calculation-extra-stop';
import { CalculationProcedure } from '../calculation-procedure/Calculation-procedure';
import { Icon } from '../Icon';
// Utils
import { parseName, setPackingOptions } from '../../util/utils';
import { IconNames } from '../../util/const';
// Styles
import styles from './CalculationCommonValues.module.css';
import { useSelector } from 'react-redux';

const PROCEDURE_DATA_TEMPLATE = {
  procedureType: '',
  numberOfProcedures: 0,
};

export const CalculationCommonValues = (props) => {
  const {
    commonValues,
    onFormChange,
    lastTarget,
    setLastTarget,
    renderProceduresOptions,
    resetLastTarget,
    calculationType,
    setCursorPosition,
    cursorPosition,
    department,
    isCuFtDepartment,
    hasRole
  } = props
  const [cratesNumber, setCratesNumber] = useState(0);
  const Total_small_boxes = useSelector(state => state.calculatedParams.Total_small_boxes);
  const Total_medium_boxes = useSelector(state => state.calculatedParams.Total_medium_boxes);
  const Total_fragile_boxes = useSelector(state => state.calculatedParams.Total_fragile_boxes);
  const groupInputChange = (evt) => {
    setLastTarget(evt.target.name);
    setCursorPosition(evt.target.selectionStart)
    onFormChange(`commonValues.${evt.target.name}`, 'set', evt.target.value);
  };

  const checkboxValueChange = (evt) => {
    resetLastTarget();

    switch (evt.target.value) {
      case 'true':
        return onFormChange(`commonValues.${evt.target.name}`, 'set', false);
      case 'false':
        return onFormChange(`commonValues.${evt.target.name}`, 'set', true);
      default:
        break;
    }
  };

  /*   useEffect(() => {
      if (commonValues.packing === 'No') {
        onFormChange(`commonValues.unpacking`, 'set', 'No')
      }
    }, [commonValues.packing]); */

  useEffect(() => {
    if (commonValues.fragileBoxesPartialUnpacking > commonValues.fragileBoxes) {
      alert('Partial Unpacking for Fragile boxes can not be more than total Fragile boxes!')
      onFormChange(`commonValues.fragileBoxesPartialUnpacking`, 'set', commonValues.fragileBoxes);
    }
  }, [commonValues.fragileBoxes]);

  useEffect(() => {
    if (commonValues.smallBoxesPartialUnpacking > commonValues.smallBoxes) {
      alert('Partial Unpacking for Small boxes can not be more than total Small boxes!')
      onFormChange(`commonValues.smallBoxesPartialUnpacking`, 'set', commonValues.smallBoxes);
    }
  }, [commonValues.smallBoxes]);

  useEffect(() => {
    if (commonValues.mediumBoxesPartialUnpacking > commonValues.mediumBoxes) {
      alert('Partial Unpacking for Medium boxes can not be more than total Medium boxes!')
      onFormChange(`commonValues.mediumBoxesPartialUnpacking`, 'set', commonValues.mediumBoxes);
    }
  }, [commonValues.mediumBoxes]);

  useEffect(() => {
    try {
      commonValues.procedures.forEach((procedure, pIndex) => {
        if (!procedure.uId && procedure.procedureType !== '') {
          let crateId = ''
          department.packing.cratings.forEach((crate, cIndex) => {
            if (crate[`crateName${cIndex}`] === procedure.procedureType) {
              if (crate[`uId${cIndex}`]) {
                crateId = crate[`uId${cIndex}`]
              }
              else {
                crateId = 'undefined_uId'
              }
            }
          });
          console.log('crateIddddd', crateId)
          onFormChange(`commonValues.procedures.${pIndex}.uId`, 'set', crateId);
        }
      });
    } catch (err) { }
  }, [commonValues.procedures]);

  const extraStopInputChange = (evt, index) => {
    setLastTarget(evt.target.name);
    onFormChange(`commonValues.extraStops.${index}.${evt.target.name}`, 'set', evt.target.value);
  };

  const procedureInputChange = (evt, index) => {
    setLastTarget(evt.target.name + index);
    onFormChange(`commonValues.procedures.${index}.${evt.target.name}`, 'set', evt.target.value);
  };

  const addProcedure = (evt) => {
    evt.preventDefault();
    resetLastTarget();
    onFormChange(`commonValues.procedures`, 'push', { ...PROCEDURE_DATA_TEMPLATE });
  };

  const removeProcedure = (evt) => {
    evt.preventDefault();
    resetLastTarget();
    onFormChange(`commonValues.procedures.${commonValues.procedures.length - 1}`, 'del');
  };

  const addExtraStop = (evt, index) => {
    evt.preventDefault();
    resetLastTarget();
    const newExtraStop = {
      [`baseUnLoadingHours${index}`]: 0,
      [`baseLoadingHours${index}`]: 0,
    }
    newExtraStop[`cubicFt${index}`] = '';
    if (!isCuFtDepartment) {
      newExtraStop[`miles${index}`] = '';
    }
    newExtraStop[`driveTime${index}`] = '';
    newExtraStop[`fragileBoxes${index}`] = '';
    newExtraStop[`smallBoxes${index}`] = '';
    newExtraStop[`mediumBoxes${index}`] = '';

    onFormChange(`commonValues.extraStops`, 'push', newExtraStop);
  };

  const removeExtraStop = (evt) => {
    evt.preventDefault();
    resetLastTarget();
    onFormChange(`commonValues.extraStops.${commonValues.extraStops.length - 1}`, 'del');
  };


  return (
    <section className={styles.commonValues}>
      <div className={styles.procedure}>

        <label className={styles.commonLabel}>
          <span>Packing</span>
          <select
            className={styles.commonInput}
            name={'packing'}
            value={commonValues.packing}
            onChange={(evt) => onFormChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)}
            disabled={!hasRole}
          >
            {setPackingOptions(calculationType)}
          </select>
        </label>
        <label className={styles.commonLabel}>
          <span>Base Loading Hours</span>
          <select
            className={styles.commonInput}
            name={'baseLoadingHours'}
            value={commonValues.baseLoadingHours}
            onChange={(evt) => {
              setLastTarget(evt.target.name);
              onFormChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
            }}
            disabled={!hasRole}
          >
            <option>0</option>
            <option>0.5</option>
            <option>1</option>
            <option>1.5</option>
            <option>2</option>
            <option>2.5</option>
            <option>3</option>
            <option>3.5</option>
            <option>4</option>
            <option>4.5</option>
            <option>5</option>
            <option>5.5</option>
            <option>6</option>
            <option>6.5</option>
            <option>7</option>
            <option>7.5</option>
            <option>8</option>
            <option>8.5</option>
            <option>9</option>
            <option>9.5</option>
            <option>10</option>
            <option>10.5</option>
            <option>11</option>
            <option>11.5</option>
            <option>12</option>
            <option>12.5</option>
            <option>13</option>
            <option>13.5</option>
            <option>14</option>
            <option>14.5</option>
            <option>15</option>
          </select>
        </label>
        <label className={styles.commonLabel}>
          <span>Base Unloading Hours</span>
          <select
            className={styles.commonInput}
            name={'baseUnloadingHours'}
            value={commonValues.baseUnloadingHours}
            onChange={(evt) => {
              setLastTarget(evt.target.name);
              onFormChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
            }}
            disabled={!hasRole}
          >
            <option>0</option>
            <option>0.5</option>
            <option>1</option>
            <option>1.5</option>
            <option>2</option>
            <option>2.5</option>
            <option>3</option>
            <option>3.5</option>
            <option>4</option>
            <option>4.5</option>
            <option>5</option>
            <option>5.5</option>
            <option>6</option>
            <option>6.5</option>
            <option>7</option>
            <option>7.5</option>
            <option>8</option>
            <option>8.5</option>
            <option>9</option>
            <option>9.5</option>
            <option>10</option>
            <option>10.5</option>
            <option>11</option>
            <option>11.5</option>
            <option>12</option>
            <option>12.5</option>
            <option>13</option>
            <option>13.5</option>
            <option>14</option>
            <option>14.5</option>
            <option>15</option>
          </select>
        </label>
        <div className={styles.commonInputList}>
          <CalculationCommonLabel
            key={nanoid(6)}
            title={parseName('cubicFt')}
            placeholder={'ex: 3'}
            value={commonValues['cubicFt']}
            inputName={'cubicFt'}
            changeValue={groupInputChange}
            isFocused={lastTarget === 'cubicFt'}
            cursorPosition={cursorPosition}
            disabled={!hasRole}
          />

          {(isCuFtDepartment) &&
            <CalculationCommonLabel
              key={nanoid(6)}
              title={parseName('milesFromParking')}
              placeholder={'ex: 3'}
              value={commonValues['milesFromParkingCuFt']}
              inputName={'milesFromParkingCuFt'}
              changeValue={groupInputChange}
              isFocused={lastTarget === 'milesFromParkingCuFt'}
              cursorPosition={cursorPosition}
              isMilesFin={!(calculationType === 'oneDayPacking'
                || calculationType === 'twoDaysPacking'
                || calculationType === 'twoDays150Miles')}
              disabled={!hasRole}
            />
          }
          <CalculationCommonLabel
            key={nanoid(6)}
            title={parseName(isCuFtDepartment ? 'Trip Miles' : 'miles')}
            placeholder={'ex: 3'}
            value={commonValues['miles']}
            inputName={'miles'}
            changeValue={groupInputChange}
            isFocused={lastTarget === 'miles'}
            cursorPosition={cursorPosition}
            isMilesFin={!(calculationType === 'oneDayPacking'
              || calculationType === 'twoDaysPacking'
              || calculationType === 'twoDays150Miles')}
            disabled={!hasRole}
          />
          {(isCuFtDepartment) &&
            <CalculationCommonLabel
              key={nanoid(6)}
              title={parseName('milesToParking')}
              placeholder={'ex: 3'}
              value={commonValues['milesToParkingCuFt']}
              inputName={'milesToParkingCuFt'}
              changeValue={groupInputChange}
              isFocused={lastTarget === 'milesToParkingCuFt'}
              cursorPosition={cursorPosition}
              isMilesFin={!(calculationType === 'oneDayPacking'
                || calculationType === 'twoDaysPacking'
                || calculationType === 'twoDays150Miles')}
            />
          }

          {(calculationType === 'oneDayPacking'
            || calculationType === 'twoDaysPacking'
            || calculationType === 'twoDays150Miles') ?
            <label className={styles.commonLabel}>
              <span>Drive Time</span>
              <select
                className={styles.commonInput}
                name={'driveTime'}
                value={commonValues.driveTime}
                onChange={(evt) => {
                  setLastTarget(evt.target.name);
                  onFormChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
                }}
                autoFocus={lastTarget === 'driveTime'}
                disabled={!hasRole}
              >
                <option>0</option>
                <option>0.05</option>
                <option>0.10</option>
                <option>0.16</option>
                <option>0.25</option>
                <option>0.33</option>
                <option>0.5</option>
                <option>0.75</option>
                <option>1</option>
                <option>1.25</option>
                <option>1.5</option>
                <option>1.75</option>
                <option>2</option>
                <option>2.25</option>
                <option>2.5</option>
                <option>2.75</option>
                <option>3</option>
                <option>3.25</option>
                <option>3.5</option>
                <option>3.75</option>
                <option>4</option>
                <option>4.25</option>
                <option>4.5</option>
                <option>4.75</option>
                <option>5</option>
                <option>5.25</option>
                <option>5.5</option>
                <option>5.75</option>
                <option>6</option>
                <option>6.25</option>
                <option>6.5</option>
                <option>6.75</option>
                <option>7</option>
                <option>7.25</option>
                <option>7.5</option>
                <option>7.75</option>
                <option>8</option>
                <option>8.25</option>
                <option>8.5</option>
                <option>8.75</option>
                <option>9</option>
                <option>9.25</option>
                <option>9.5</option>
                <option>9.75</option>
                <option>10</option>
                <option>10.25</option>
                <option>10.5</option>
                <option>10.75</option>
                <option>11</option>
                <option>11.25</option>
                <option>11.5</option>
                <option>11.75</option>
                <option>12</option>
              </select>
            </label>
            /*    <CalculationCommonLabel
                 key={nanoid(6)}
                 title={parseName('driveTime')}
                 placeholder={'ex: 3'}
                 value={commonValues['driveTime']}
                 inputName={'driveTime'}
                 changeValue={groupInputChange}
                 isFocused={lastTarget === 'driveTime'}
                 cursorPosition={cursorPosition}
               /> */
            : ''}



          <CalculationCommonLabel
            key={nanoid(6)}
            title={parseName('fragileBoxes')}
            placeholder={'ex: 3'}
            value={commonValues['fragileBoxes']}
            inputName={'fragileBoxes'}
            changeValue={groupInputChange}
            isFocused={lastTarget === 'fragileBoxes'}
            cursorPosition={cursorPosition}
            titleStyle={{ color: 'red' }}
            disabled={!hasRole}
          />

          <CalculationCommonLabel
            key={nanoid(6)}
            title={parseName('smallBoxes')}
            placeholder={'ex: 3'}
            value={commonValues['smallBoxes']}
            inputName={'smallBoxes'}
            changeValue={groupInputChange}
            isFocused={lastTarget === 'smallBoxes'}
            cursorPosition={cursorPosition}
            disabled={!hasRole}
          />

          <CalculationCommonLabel
            key={nanoid(6)}
            title={parseName('mediumBoxes')}
            placeholder={'ex: 3'}
            value={commonValues['mediumBoxes']}
            inputName={'mediumBoxes'}
            changeValue={groupInputChange}
            isFocused={lastTarget === 'mediumBoxes'}
            cursorPosition={cursorPosition}
            disabled={!hasRole}
          />



        </div>
        <CalculationCommonLabel
          title={parseName(`packingKitOverride`)}
          placeholder={'ex: 3'}
          value={commonValues.packingKitOverride}
          inputName={`packingKitOverride`}
          cursorPosition={cursorPosition}
          changeValue={(evt) => {
            resetLastTarget();
            setCursorPosition(evt.target.selectionStart)
            onFormChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
          }}
          disabled={!hasRole}
        />

        <fieldset className={styles.checkboxContainer}>
          <label className={styles.checkLabel}>
            <span>Shuttle</span>
            <input
              className={styles.checkbox}
              type='checkbox'
              name={'shuttle'}
              value={commonValues.shuttle}
              onChange={checkboxValueChange}
              checked={commonValues.shuttle}
              onFocus={resetLastTarget}
              disabled={!hasRole}
            />
          </label>
          <label className={styles.checkLabel}>
            <span>Liftgate</span>
            <input
              className={styles.checkbox}
              type='checkbox'
              name={'liftGate'}
              value={commonValues.liftGate}
              onChange={checkboxValueChange}
              checked={commonValues.liftGate}
              disabled={!hasRole}
            />
          </label>
          <label className={styles.checkLabel}>
            <span>Hard Floor Covers</span>
            <input
              className={styles.checkbox}
              type='checkbox'
              name={'hardFloorCovers'}
              value={commonValues.hardFloorCovers}
              onChange={checkboxValueChange}
              checked={commonValues.hardFloorCovers}
              disabled={!hasRole}
            />
          </label>
          <label className={styles.checkLabel}>
            <span>Film Floor Covers</span>
            <input
              className={styles.checkbox}
              type='checkbox'
              name={'filmFloorCovers'}
              value={commonValues.filmFloorCovers}
              onChange={checkboxValueChange}
              checked={commonValues.filmFloorCovers}
              disabled={!hasRole}
            />
          </label>
        </fieldset>
        {/*  <CalculationCommonLabel
        title={parseName(`numOfExtraHeavyItem`)}
        placeholder={'ex: 3'}
        value={commonValues.numOfExtraHeavyItem}
        inputName={`numOfExtraHeavyItem`}
        changeValue={(evt) => {
          resetLastTarget();
          onFormChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
        }
        }
      /> */}

        <label className={styles.commonLabel}>
          <span>Num Of Extra Heavy Item</span>
          <select
            className={styles.commonInput}
            name={'numOfExtraHeavyItem'}
            value={commonValues.numOfExtraHeavyItem}
            onChange={(evt) => onFormChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)}
            disabled={!hasRole}
          >
            <option>0</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
          </select>
        </label>


        {
          cratesNumber > 0 &&
          commonValues.cratesCosts.map((it, index) =>
            <CalculationCommonLabel
              key={nanoid(commonValues.cratesCosts.length)}
              title={`Crates cost ${index + 1}`}
              placeholder={'ex: 3'}
              value={commonValues.cratesCosts[index][`crateCost${index}`]}
              inputName={`crateCost${index}`}
              changeValue={(evt) => {
                setLastTarget(evt.target.name);
                onFormChange(`commonValues.cratesCosts.${index}.${evt.target.name}`, 'set', evt.target.value)
              }}
              isFocused={lastTarget === `crateCost${index}`}
              disabled={!hasRole}
            />)
        }
        <label className={styles.commonLabel}>
          <span>Unpacking</span>
          <select
            className={styles.commonInput}
            name={'unpacking'}
            value={commonValues.unpacking}
            onChange={(evt) => onFormChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)}
            disabled={!commonValues['fragileBoxes'] && !commonValues['smallBoxes'] && !commonValues['mediumBoxes'] && !hasRole}
          >
            <option>No</option>
            <option>Same Day</option>
            <option>Separate Day</option>
          </select>
        </label>

        {commonValues.unpacking !== 'No' &&

          <label className={styles.checkLabel} style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px' }}>
            <span>Partial Unpacking</span>
            <input
              className={styles.checkbox}
              type='checkbox'
              name={'partialUnPacking'}
              value={(commonValues.partialUnPacking === undefined) ? false : commonValues.partialUnPacking}
              onChange={checkboxValueChange}
              checked={commonValues.partialUnPacking}
              onFocus={resetLastTarget}
              disabled={!hasRole}
            />
          </label>
        }
        {(commonValues.unpacking !== 'No' && commonValues.partialUnPacking) && <>
          <CalculationCommonLabel
            key={nanoid(6)}
            title={parseName('fragileBoxes')}
            placeholder={'ex: 3'}
            value={commonValues['fragileBoxesPartialUnpacking']}
            inputName={'fragileBoxesPartialUnpacking'}
            changeValue={(evt) => { if (evt.target.value <= Total_fragile_boxes) { groupInputChange(evt) } else { alert('Partial Unpacking for Fragile boxes can not be more than total Fragile boxes!') } }}
            isFocused={lastTarget === 'fragileBoxesPartialUnpacking'}
            cursorPosition={cursorPosition}
            titleStyle={{ color: 'red' }}
            disabled={!hasRole}
          />

          <CalculationCommonLabel
            key={nanoid(6)}
            title={parseName('smallBoxes')}
            placeholder={'ex: 3'}
            value={commonValues['smallBoxesPartialUnpacking']}
            inputName={'smallBoxesPartialUnpacking'}
            changeValue={(evt) => { if (evt.target.value <= Total_small_boxes) { groupInputChange(evt) } else { alert('Partial Unpacking for Small boxes can not be more than total Small boxes!') } }}
            isFocused={lastTarget === 'smallBoxesPartialUnpacking'}
            cursorPosition={cursorPosition}
            disabled={!hasRole}
          />

          <CalculationCommonLabel
            key={nanoid(6)}
            title={parseName('mediumBoxes')}
            placeholder={'ex: 3'}
            value={commonValues['mediumBoxesPartialUnpacking']}
            inputName={'mediumBoxesPartialUnpacking'}
            changeValue={(evt) => { if (evt.target.value <= Total_medium_boxes) { groupInputChange(evt) } else { alert('Partial Unpacking for Medium boxes can not be more than total Medium boxes!') } }}
            isFocused={lastTarget === 'mediumBoxesPartialUnpacking'}
            cursorPosition={cursorPosition}
            disabled={!hasRole}
          />
        </>}

        <button
          className={styles.extraBtnAdd}
          onClick={(evt) => { addExtraStop(evt, commonValues.extraStops.length) }}
          disabled={!hasRole}
        >
          <Icon name={IconNames.PLUS} />
          Add stop
        </button>
        <fieldset className={styles.extraStop}>
          <button
            className={styles.extraBtn}
            onClick={addProcedure}
            disabled={!hasRole}
          > <Icon name={IconNames.PLUS} />
            Add procedure
          </button>
        </fieldset>
      </div>
      <div className={((commonValues.extraStops.length > 0) || (commonValues.procedures.length > 0)) ? styles.extraContainer : ""} >

        {commonValues.extraStops.length > 0 && commonValues.extraStops.map((extraStop, index) => {
          return (
            <CalculationExtraStop
              key={'eS' + index}
              extraStop={extraStop}
              groupInputChange={extraStopInputChange}
              lastTarget={lastTarget}
              index={index}
              calculationType={calculationType}
            />
          );
        })}
        <div className={((commonValues.extraStops.length > 0)) ? styles.buttonContainer : ""}>
          {commonValues.extraStops.length > 0 && <>
            <button
              className={styles.extraBtn1}
              onClick={(evt) => { addExtraStop(evt, commonValues.extraStops.length) }}
              disabled={!hasRole}
            >Add extra  stop
              <Icon name={IconNames.PLUS} />

            </button>
          </>
          }
          {
            commonValues.extraStops.length > 0 &&
            <button
              className={styles.removeExtraBtn}
              onClick={removeExtraStop}
              disabled={!hasRole}
            >
              Delete extra stop
              <Icon name={IconNames.MINUS} />
            </button>
          }
        </div>
        <div className={((commonValues.extraStops.length > 0) || (commonValues.procedures.length > 0)) ? styles.procedureContainer : ""}>
          {commonValues.procedures.length > 0 && commonValues.procedures.map((procedure, index) => {
            return (
              <CalculationProcedure
                key={nanoid(commonValues.procedures.length)}
                procedure={procedure}
                renderProceduresOptions={renderProceduresOptions}
                groupInputChange={procedureInputChange}
                index={index}
                lastTarget={lastTarget}
              />

            );
          })}
          {commonValues.procedures.length > 0 && <>
            <button
              className={styles.extraBtn1}
              onClick={addProcedure}
              disabled={!hasRole}
            >
              Add extra procedure
              <Icon name={IconNames.PLUS} />
            </button>
          </>
          }
          {
            commonValues.procedures.length > 0 &&
            <button
              className={styles.removeExtraBtn}
              onClick={removeProcedure}
              disabled={!hasRole}
            >
              Delete extra procedure
              <Icon name={IconNames.MINUS} />
            </button>
          }
        </div>
      </div>
    </section>

  );
}