// Third party libraries
import { nanoid } from 'nanoid';
import useCollapse from 'react-collapsed';
import { getTruckFeeMin } from '../../util/utils';
// Styles
import styles from './CalculationTabLongTwoDayValues.module.css';
import { ExtraOptionsValues } from '../extra-options-values/Extra-options-values';
import { MiscChechMarksValues } from '../misc-checkmarks-values/Misc-checkmarks-values';
import { useState } from 'react';
import AdjustTime from '../adjust-time/Adjust-time';
import clockIcon from './../../img/clock.png';

export const CalculationTabLongTwoDayValues = ({ rates, formData, formChange, resetLastTarget, renderLongDistancesOptions, onChangeLongDistanceSelect, sepCheckboxValueChange, departments, unpRates, onChangeUnpDepartmentSelect, unpDepartment, lastTarget, setLastTarget, checkMarkCheckboxValueChange, disabled }) => {
  const [showInputs, setShowInputs] = useState(false);
  const [showInputsSecond, setShowInputsSecond] = useState(false);
  const [showInputsThird, setShowInputsThird] = useState(false);
  const [showInputsUnpacking, setShowInputsUnpacking] = useState(false);

  const onValueChange = (evt) => {
    resetLastTarget();
    formChange(`longTwoDaysPacking.${evt.target.name}`, 'set', evt.target.value)
  }

  const checkboxValueChange = (evt) => {
    resetLastTarget();

    switch (evt.target.value) {
      case 'true':
        return formChange(`longTwoDaysPacking.${evt.target.name}`, 'set', false);
      case 'false':
        return formChange(`longTwoDaysPacking.${evt.target.name}`, 'set', true);
      default:
        break;
    }
  };

  const chargeCheckboxValueChangeDayOne = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedChargesNames = [...formData.longTwoDaysPacking.selectedChargesNamesDay1];
        var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedChargesNames.splice(index, 1);
          return formChange(`longTwoDaysPacking.selectedChargesNamesDay1`, 'set', newSelectedChargesNames);
        }
        break;
      case 'false':
        return formChange(`longTwoDaysPacking.selectedChargesNamesDay1`, 'push', { key: evt.target.name, quantity: 1 });
      default:
        break;
    }
  };

  const chargeCheckboxValueChangeDayTwo = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedChargesNames = [...formData.longTwoDaysPacking.selectedChargesNamesDay2];
        var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedChargesNames.splice(index, 1);
          return formChange(`longTwoDaysPacking.selectedChargesNamesDay2`, 'set', newSelectedChargesNames);
        }
        break;
      case 'false':
        return formChange(`longTwoDaysPacking.selectedChargesNamesDay2`, 'push', { key: evt.target.name, quantity: 1 });
      default:
        break;
    }
  };

  const chargeCheckboxValueChangeDayThree = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedChargesNames = [...formData.longTwoDaysPacking.selectedChargesNamesDay3];
        var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedChargesNames.splice(index, 1);
          return formChange(`longTwoDaysPacking.selectedChargesNamesDay3`, 'set', newSelectedChargesNames);
        }
        break;
      case 'false':
        return formChange(`longTwoDaysPacking.selectedChargesNamesDay3`, 'push', { key: evt.target.name, quantity: 1 });
      default:
        break;
    }
  };

  const chargeQuantityChangeDayOne = (evt) => {
    resetLastTarget();
    setLastTarget(evt.target.name)
    var newSelectedChargesNames = [...formData.longTwoDaysPacking.selectedChargesNamesDay1];
    var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedChargesNames[index].quantity = Number(evt.target.value)
      return formChange(`longTwoDaysPacking.selectedChargesNamesDay1`, 'set', newSelectedChargesNames);
    }
  }

  const chargeQuantityChangeDayTwo = (evt) => {
    resetLastTarget();
    setLastTarget(evt.target.name)
    var newSelectedChargesNames = [...formData.longTwoDaysPacking.selectedChargesNamesDay2];
    var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedChargesNames[index].quantity = Number(evt.target.value)
      return formChange(`longTwoDaysPacking.selectedChargesNamesDay2`, 'set', newSelectedChargesNames);
    }
  }

  const chargeQuantityChangeDayThree = (evt) => {
    resetLastTarget();
    setLastTarget(evt.target.name)
    var newSelectedChargesNames = [...formData.longTwoDaysPacking.selectedChargesNamesDay3];
    var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedChargesNames[index].quantity = Number(evt.target.value)
      return formChange(`longTwoDaysPacking.selectedChargesNamesDay3`, 'set', newSelectedChargesNames);
    }
  }

  const proceduresCheckboxValueChange = (evt) => {
    let longTwoDaysPackingTemp = { ...formData.longTwoDaysPacking };
    longTwoDaysPackingTemp.proceduresOnDay1 = !longTwoDaysPackingTemp.proceduresOnDay1;
    longTwoDaysPackingTemp.proceduresOnDay2 = !longTwoDaysPackingTemp.proceduresOnDay2;
    return formChange(`longTwoDaysPacking`, 'set', longTwoDaysPackingTemp);
  };



  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  return (
    <section className={styles.container}>

      <div className={styles.groupInput}>
        <label className={styles.commonLabel}>
          1st date:
          <input
            className={styles.dateInput}
            type='date'
            name={'firstDayDate'}
            value={formData.longTwoDaysPacking.firstDayDate}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>
        <label className={styles.commonLabel} style={{ marginLeft: "20px" }}>
          2nd date:
          <input
            className={styles.dateInput}
            type='date'
            name={'secondDayDate'}
            value={formData.longTwoDaysPacking.secondDayDate}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>
        <label className={styles.commonLabel} style={{ marginLeft: "20px" }}>
          3rd date:
          <input
            className={styles.dateInput}
            type='date'
            name={'thirdDayDate'}
            value={formData.longTwoDaysPacking.thirdDayDate}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>
      </div>
      <hr className={styles.hLine} style={{ marginLeft: '0px' }} />
      <fieldset className={styles.groupInput}>
        <label style={{ display: 'flex', marginBottom: '10px' }} >
          Direction:
          <select
            className={styles.select}
            style={{ marginRight: '0px' }}
            value={formData.longDistance !== undefined ? formData.longDistance._id : 'None'}
            onChange={onChangeLongDistanceSelect}
            disabled={disabled}
          >
            <option>None</option>
            {renderLongDistancesOptions()}
          </select>
        </label>

        <div className={styles.vl}></div>
        <label style={{ marginLeft: '40px', marginTop: "5px" }}>
          Additional amount:
          <input
            onWheel={(e) => e.target.blur()}
            style={{ width: "60px" }}
            type='number'
            name={'additionalAmount'}
            value={(formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.additionalAmount : ''}
            placeholder={'ex: 2'}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>


      </fieldset>
      <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", width: '630px' }}>
        <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>DAY - 1 (Packing)</span>
            <img src={clockIcon} onClick={() => setShowInputs(!showInputs)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
          </div>
          {showInputs && (
            <fieldset className={styles.groupInput}>
              <div className={styles.decorateContainer}>
                <AdjustTime
                  name={'addTimeFirstDay'}
                  value={formData.commonValues.addTimeFirstDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Add time:'}
                  disabled={disabled}
                />
                <AdjustTime
                  name={'removeTimeFirstDay'}
                  value={formData.commonValues.removeTimeFirstDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Remove time:'}
                  disabled={disabled}
                />
              </div>
            </fieldset>
          )}
        </div>
        <div style={{ padding: '5px' }}>
          <fieldset className={styles.groupInput}>
            {(formData.longTwoDaysPacking.directionType !== 'roomDistance') ?
              <>
                {rates.length === 0 && <p className={styles.warning}>Select Department!</p>}
                {rates.length > 0 &&

                  <label style={{ marginBottom: '10px', display: 'block' }} >
                    Rate:
                    <select
                      className={styles.select}
                      value={formData.longTwoDaysPacking ? formData.longTwoDaysPacking.firstDayRate : 'none'}
                      onChange={(evt) => {
                        resetLastTarget();
                        formChange('longTwoDaysPacking.firstDayRate', 'set', evt.target.value);
                      }}
                      disabled={disabled}
                    >
                      <option value={''}>None</option>
                      {
                        rates.map((rate, index) => {
                          return (
                            <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                          );
                        })
                      }
                    </select>
                  </label>
                }
              </>
              : ""}

            {formData.commonValues.shuttle &&
              <div style={{ display: 'flex' }}>
                <label style={{ marginBottom: '10px', display: 'block' }}>
                  Shuttle on Day 1
                  <input
                    type='checkbox'
                    name={'shuttleOnDay1'}
                    value={(formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.shuttleOnDay1 : false}
                    checked={(formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.shuttleOnDay1 : false}
                    onChange={checkboxValueChange}
                    disabled={disabled}
                  />
                </label>
                {(formData.department.extraOptions.shuttle.isCountable && formData.longTwoDaysPacking.shuttleOnDay1) &&
                  <label className={styles.inputContainer}>
                    Qty:
                    <input
                      type='number'
                      name={'quantityDay1'}
                      value={(formData.longTwoDaysPacking.quantityDay1)}
                      placeholder={'ex: 2'}
                      onChange={onValueChange}
                      onWheel={(e) => e.target.blur()}
                      disabled={disabled}
                    />
                  </label>
                }
              </div>
            }

            <div style={{ width: '100%', display: 'flex' }}>
              {(formData.commonValues.procedures.length) ?
                <label className={styles.commonLabel} style={{ marginBottom: '10px' }}>
                  Procedures on Day 1
                  <input
                    className={styles.checkbox}
                    type='checkbox'
                    name={'proceduresOnDay1'}
                    value={(formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.proceduresOnDay1 : false}
                    checked={(formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.proceduresOnDay1 : false}
                    onChange={proceduresCheckboxValueChange}
                    disabled={disabled}
                  />
                </label>

                : ''}

              {formData.department && formData.department.truckFee && formData.department.truckFee.calcMethod === "different" &&
                <label className={styles.inputContainer} style={{ display: 'block' }}>
                  Truck fee:
                  <input
                    onWheel={(e) => e.target.blur()}
                    type='number'
                    name={'truckFee'}
                    value={(formData._id) ? (formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.truckFee : '' : getTruckFeeMin(formData.department.truckFee.diffTruckReservations)}
                    placeholder={'ex: 40'}
                    onChange={onValueChange}
                    disabled={disabled}
                  />
                </label>
              }
            </div>
            <div style={{ width: '100%', display: 'flex' }}></div>
            <label className={styles.commonLabel} style={{ justifyContent: "flex-start" }}>
              <span>Movers to pack:</span>
              <select
                className={styles.commonInput}
                name={'moversToPack'}
                value={(formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.moversToPack : ''}
                onChange={onValueChange}
                disabled={disabled}
              >
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
              </select>
            </label>

            <label className={styles.commonLabel} style={{ marginLeft: '20px' }}>
              <span>Add Trucks:</span>
              <select
                className={styles.commonInput}
                name={'addTrucks'}
                value={formData.longTwoDaysPacking.addTrucks}
                onChange={onValueChange}
                disabled={disabled}
              >
                <option key='0'>0</option>
                <option key='1'>1</option>
                <option key='2'>2</option>
                <option key='3'>3</option>
                <option key='4'>4</option>
                <option key='5'>5</option>
                <option key='6'>6</option>
                <option key='7'>7</option>
                <option key='8'>8</option>
                <option key='9'>9</option>
                <option key='10'>10</option>
              </select>
            </label>

          </fieldset>



          {(formData.department && formData.department.extraOptions && formData.department.extraOptions && formData.department.extraOptions.charges.length) ?
            <>
              <hr className={styles.hLine} />
              <ExtraOptionsValues
                charges={(formData.department && formData.department.extraOptions) ? formData.department.extraOptions.charges : []}
                chargeCheckboxValueChange={chargeCheckboxValueChangeDayOne}
                selectedChargesNames={formData.longTwoDaysPacking.selectedChargesNamesDay1}
                lastTarget={lastTarget}
                chargeQuantityChange={chargeQuantityChangeDayOne}
                disabled={disabled}
              />
            </>
            : ""
          }
        </div>
      </div>




      <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", marginTop: '20px', width: '630px' }}>
        <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>DAY - 2 (Loading)</span>
            <img src={clockIcon} onClick={() => setShowInputsSecond(!showInputsSecond)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
          </div>
          {showInputsSecond && (
            <fieldset className={styles.groupInput}>
              <div className={styles.decorateContainer}>
                <AdjustTime
                  name={'addTimeSecondDay'}
                  value={formData.commonValues.addTimeSecondDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Add time:'}
                  disabled={disabled}
                />
                <AdjustTime
                  name={'removeTimeSecondDay'}
                  value={formData.commonValues.removeTimeSecondDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Remove time:'}
                  disabled={disabled}
                />
              </div>
            </fieldset>
          )}
        </div>
        <div style={{ padding: '5px' }}>
          <fieldset className={styles.groupInput}>
            {rates.length === 0 && <p className={styles.warning}>Select Department!</p>}
            {(formData.longDistance && formData.longDistance.directionType === 'roomDistance') &&
              <div>
                <label style={{ display: 'block', marginTop: '10px' }}>
                  <span>Number of areas (rooms/office/garage):</span>
                  <select
                    className={styles.commonInput}
                    name={'numberOfRoomsDay2'}
                    value={formData.longTwoDaysPacking.numberOfRoomsDay2}
                    onChange={onValueChange}
                    disabled={disabled}
                  >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </label>

                <label className={styles.shuttleOnDayOne} style={{ marginLeft: '20px', display: 'block', marginTop: '10px' }}>
                  Adjust rooms to pack
                  <input
                    type='checkbox'
                    name={'adjustRoomsToPack'}
                    value={(formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.adjustRoomsToPack : false}
                    checked={(formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.adjustRoomsToPack : false}
                    onChange={checkboxValueChange}
                    disabled={disabled}
                  />
                </label>
                {formData.longTwoDaysPacking.adjustRoomsToPack &&
                  <label style={{ marginLeft: '20px', display: 'block', marginTop: '10px' }}>
                    <span>Number of areas to pack:</span>
                    <select
                      className={styles.commonInput}
                      name={'numberOfRoomsToPack'}
                      value={formData.longTwoDaysPacking.numberOfRoomsToPack}
                      onChange={onValueChange}
                      disabled={disabled}
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </label>
                }
              </div>
            }


            {rates.length > 0 &&
              <label style={{ marginTop: '5px', width: (formData.commonValues.procedures.length) ? '233px' : '500px' }}>
                Rate:
                <select
                  className={styles.select}
                  value={formData.longTwoDaysPacking ? formData.longTwoDaysPacking.secondDayRate : 'none'}
                  onChange={(evt) => {
                    resetLastTarget();
                    formChange('longTwoDaysPacking.secondDayRate', 'set', evt.target.value);
                  }}
                  disabled={disabled}
                >
                  <option value={''}>None</option>
                  {
                    rates.map((rate, index) => {
                      return (
                        <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                      );
                    })
                  }
                </select>
              </label>
            }
            {(formData.commonValues.procedures.length) ?
              <label className={styles.commonLabel} style={{ marginLeft: "20px", marginBottom: '10px' }}>
                Procedures on Day 2
                <input
                  className={styles.checkbox}
                  type='checkbox'
                  name={'proceduresOnDay2'}
                  value={(formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.proceduresOnDay2 : false}
                  checked={(formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.proceduresOnDay2 : false}
                  onChange={proceduresCheckboxValueChange}
                  disabled={disabled}
                />
              </label>
              : ''}

            <div style={{ display: 'flex' }}>
              <label className={styles.commonLabel} style={{ marginTop: '20px' }}>
                <span>Add Movers:</span>
                <select
                  className={styles.commonInput}
                  name={'addMoversDay2'}
                  value={formData.longTwoDaysPacking.addMoversDay2}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                </select>
              </label>

              <label className={styles.commonLabel} style={{ marginTop: '20px' }}>
                <span>Remove Movers:</span>
                <select
                  className={styles.commonInput}
                  name={'removeMoversDay2'}
                  value={formData.longTwoDaysPacking.removeMoversDay2}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                </select>

              </label>
              {formData.commonValues.shuttle &&
                <div style={{ display: 'flex' }}>
                  <label className={styles.shuttleOnDayOne} style={{ marginLeft: '20px', marginTop: '20px' }}>
                    Shuttle on Day 2
                    <input
                      type='checkbox'
                      name={'shuttleOnDay2'}
                      value={(formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.shuttleOnDay2 : false}
                      checked={(formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.shuttleOnDay2 : false}
                      onChange={checkboxValueChange}
                      disabled={disabled}
                    />
                  </label>
                  {(formData.department.extraOptions.shuttle.isCountable && formData.longTwoDaysPacking.shuttleOnDay2) &&
                    <label className={styles.inputContainer} style={{ marginTop: '20px' }}>
                      Qty:
                      <input
                        type='number'
                        name={'quantityDay2'}
                        value={(formData.longTwoDaysPacking.quantityDay2)}
                        placeholder={'ex: 2'}
                        onChange={onValueChange}
                        onWheel={(e) => e.target.blur()}
                        disabled={disabled}
                      />
                    </label>
                  }
                </div>
              }
            </div>

          </fieldset>

          {(formData.department && formData.department.extraOptions && formData.department.extraOptions.charges.length) ?
            <>
              <hr className={styles.hLine} />
              <ExtraOptionsValues
                charges={(formData.department && formData.department.extraOptions) ? formData.department.extraOptions.charges : []}
                chargeCheckboxValueChange={chargeCheckboxValueChangeDayTwo}
                selectedChargesNames={formData.longTwoDaysPacking.selectedChargesNamesDay2}
                lastTarget={lastTarget}
                chargeQuantityChange={chargeQuantityChangeDayTwo}
                disabled={disabled}
              />
              <hr className={styles.hLine} />
            </>
            : ''
          }

          <fieldset className={styles.groupInput}>
            <label className={styles.inputContainer} style={{ marginTop: '10px' }}>
              Driving time adjustment:
              <input
                onWheel={(e) => e.target.blur()}
                style={{ width: "45px" }}
                type='number'
                name={'drivingTimeAdjustment'}
                value={(formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.drivingTimeAdjustment : ''}
                placeholder={'ex: 2'}
                onChange={onValueChange}
                disabled={disabled}
              />
            </label>
          </fieldset>
        </div>
      </div>
      <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", marginTop: '20px', width: '630px' }}>
        <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>DAY - 3 (Unloading)</span>
            <img src={clockIcon} onClick={() => setShowInputsThird(!showInputsThird)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
          </div>
          {showInputsThird && (
            <fieldset className={styles.groupInput}>
              <div className={styles.decorateContainer}>
                <AdjustTime
                  name={'addTimeThirdDay'}
                  value={formData.commonValues.addTimeThirdDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Add time:'}
                  disabled={disabled}
                />
                <AdjustTime
                  name={'removeTimeThirdDay'}
                  value={formData.commonValues.removeTimeThirdDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Remove time:'}
                  disabled={disabled}
                />
              </div>
            </fieldset>
          )}
        </div>
        <div style={{ padding: '5px' }}>
          <fieldset className={styles.groupInput}>
            <label className={styles.commonLabel} style={{ marginTop: '10px' }}>
              <span>Add Movers:</span>
              <select
                className={styles.commonInput}
                name={'addMoversDay3'}
                value={formData.longTwoDaysPacking.addMoversDay3}
                onChange={onValueChange}
                disabled={disabled}
              >
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>
            </label>

            <label className={styles.commonLabel} style={{ marginTop: '10px', marginLeft: '20px' }}>
              <span>Remove Movers:</span>
              <select
                className={styles.commonInput}
                name={'removeMoversDay3'}
                value={formData.longTwoDaysPacking.removeMoversDay3}
                onChange={onValueChange}
                disabled={disabled}
              >
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>

            </label>

            {formData.commonValues.shuttle &&
              <div>
                <label className={styles.shuttleOnDayOne} style={{ marginTop: '20px', marginLeft: '20px' }}>
                  Shuttle on Day 3
                  <input
                    type='checkbox'
                    name={'shuttleOnDay3'}
                    value={(formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.shuttleOnDay3 : false}
                    checked={(formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.shuttleOnDay3 : false}
                    onChange={checkboxValueChange}
                    disabled={disabled}
                  />
                </label>
                {(formData.department.extraOptions.shuttle.isCountable && formData.longTwoDaysPacking.shuttleOnDay3) &&
                  <label className={styles.inputContainer} style={{ marginTop: '20px' }}>
                    Qty:
                    <input
                      type='number'
                      name={'quantityDay3'}
                      value={(formData.longTwoDaysPacking.quantityDay3)}
                      placeholder={'ex: 2'}
                      onChange={onValueChange}
                      onWheel={(e) => e.target.blur()}
                      disabled={disabled}
                    />
                  </label>
                }
              </div>
            }
          </fieldset>
          {(formData.department && formData.department.extraOptions && formData.department.extraOptions.charges.length) ?
            <>
              <hr className={styles.hLine} />
              <ExtraOptionsValues
                charges={(formData.department && formData.department.extraOptions) ? formData.department.extraOptions.charges : []}
                chargeCheckboxValueChange={chargeCheckboxValueChangeDayThree}
                selectedChargesNames={formData.longTwoDaysPacking.selectedChargesNamesDay3}
                lastTarget={lastTarget}
                chargeQuantityChange={chargeQuantityChangeDayThree}
                disabled={disabled}
              />
              <hr className={styles.hLine} />
            </>
            : <hr className={styles.hLine} />
          }

          <MiscChechMarksValues
            miscCheckMarks={(formData.department && formData.department.miscCheckMarks && formData.department.miscCheckMarks.miscCheckMarks.length) ? formData.department.miscCheckMarks.miscCheckMarks : []}
            selectedMiscCheckMarks={formData.selectedMiscCheckMarks}
            checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
            disabled={disabled}
          />
        </div>
      </div >

      {
        formData.commonValues.unpacking === 'Separate Day' &&
        <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", marginTop: '20px', width: '630px' }}>
          <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>UNPACKING ON SEPARATE DAY</span>
              <img src={clockIcon} onClick={() => setShowInputsUnpacking(!showInputsUnpacking)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
            </div>
            {showInputsUnpacking && (
              <fieldset className={styles.groupInput}>
                <div className={styles.decorateContainer}>
                  <AdjustTime
                    name={'addTimeUnp'}
                    value={formData.commonValues.addTimeUnp}
                    onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                    labelText={'Add time:'}
                    disabled={disabled}
                  />
                  <AdjustTime
                    name={'removeTimeUnp'}
                    value={formData.commonValues.removeTimeUnp}
                    onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                    labelText={'Remove time:'}
                    disabled={disabled}
                  />
                </div>
              </fieldset>
            )}
          </div>
          <div style={{ padding: '5px' }}>
            <div className={styles.unpSepCont}>
              <div className={styles.unpSepCont1}>
                <label className={styles.label} style={{ marginTop: '5px' }}>
                  Department
                  <select
                    className={styles.select}
                    value={unpDepartment !== undefined ? unpDepartment._id : 'None'}
                    onChange={onChangeUnpDepartmentSelect}
                    onFocus={resetLastTarget}
                    disabled={disabled}
                  >
                    <option value={undefined}>None</option>
                    {
                      departments && departments.map((department) => {
                        return (
                          <option key={nanoid(departments.length)} value={department._id}>{department.departmentName}</option>
                        );
                      })
                    }
                  </select>
                </label>

                {unpRates.length === 0 && <p className={styles.warning1} style={{ marginTop: '10px' }}>Select Separate day Department!</p>}
                {unpRates.length > 0 &&
                  <label style={{ display: 'block', marginTop: '10px', marginBottom: '10px' }}>
                    Rate:
                    <select
                      className={styles.select}
                      value={formData.commonValues.unpackingRate}
                      onChange={(evt) => {
                        resetLastTarget();
                        formChange('commonValues.unpackingRate', 'set', evt.target.value);
                      }}
                      style={{ width: "90px" }}
                      disabled={disabled}
                    >
                      <option value={''} key='none'>None</option>
                      {
                        unpRates.map((rate, index) => {
                          return (
                            <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                          );
                        })
                      }
                    </select>
                  </label>
                }

                <label className={styles.checkLabel} style={{ justifyContent: "flex-start" }} >
                  <span>Movers to Unpack:</span>
                  <select
                    className={styles.commonInput}
                    name={'moversToUnPackSeparate'}
                    value={(formData.commonValues.moversToUnPackSeparate) ? formData.commonValues.moversToUnPackSeparate : ''}
                    onChange={(evt) => {
                      resetLastTarget();
                      formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
                    }}
                    disabled={disabled}
                  >
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </label>


                {formData.commonValues.shuttle &&
                  <div className={styles.groupInput}>
                    <label className={styles.shuttleOnDayOne}>
                      Shuttle on Unpacking Day
                      <input
                        type='checkbox'
                        name={'shuttleOnUnpacking'}
                        value={(formData.commonValues.shuttleOnUnpacking) ? formData.commonValues.shuttleOnUnpacking : false}
                        checked={(formData.commonValues.shuttleOnUnpacking) ? formData.commonValues.shuttleOnUnpacking : false}
                        onChange={sepCheckboxValueChange}
                        disabled={disabled}
                      />
                    </label>
                    {formData.commonValues.shuttleOnUnpacking &&
                      <label className={styles.inputContainer} >
                        Qty:
                        <input
                          type='number'
                          name={'quantityUnpacking'}
                          value={formData.commonValues.quantityUnpacking}
                          placeholder={'ex: 2'}
                          onChange={(evt) => {
                            resetLastTarget();
                            formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
                          }}
                          onWheel={(e) => e.target.blur()}
                          disabled={disabled}
                        />
                      </label>
                    }
                  </div>
                }

              </div>
              {formData.department && formData.department.truckFee && formData.department.truckFee.calcMethod === "different" &&
                <fieldset className={styles.groupInput}>
                  <label className={styles.inputContainer} style={{ display: 'block', marginLeft: '40px' }}>
                    Truck fee:
                    <input
                      onWheel={(e) => e.target.blur()}
                      type='number'
                      name={'truckFeeSeparate'}
                      value={(formData._id) ? (formData.longTwoDaysPacking) ? formData.longTwoDaysPacking.truckFeeSeparate : '' : getTruckFeeMin(formData.department.truckFee.diffTruckReservations)}
                      placeholder={'ex: 40'}
                      onChange={onValueChange}
                      style={{ width: '50px !important' }}
                      disabled={disabled}
                    />
                  </label>
                </fieldset>
              }

            </div>
          </div>
        </div>
      }

    </section >
  );
};