// Third party libraries
import { useState, useEffect, useRef, createRef } from 'react';

import useCollapse from 'react-collapsed'
// Components
import { Icon } from '../Icon';
import Select from 'react-select'
import { CustomOption } from '../custom-option/Custom-option';

// Utils
import { IconNames } from '../../util/const';


// Styles
import styles from './TemplateTagItem.module.css';
import { fetchedData, getTagsOptions } from '../../util/utils';
import copyIcon from './../../img/copy.png'
import deleteIcon from './../../img/delete.png';
import doneIcon from './../../img/done.png';
import editIcon from './../../img/edit.png';
import CustomPopup from '../custom-popup/Custom-popup';
import { Loading } from '../loading/Loading';


export const TemplateTagItem = ({ tag, tagsLength, tagIndex, setTagsValues, setTagsChBValues, tagNames, changeTag,
  setHighLiteItem, template, company, users, departments, department, priceOptionAllowedItems,
  tags, removeTag, removeCondition, cloneTag, renameTag, resultText, saveBtnEvent, options, emptyRef, clonnedRef, clonnedName, resetLastTarget, templateDepType }) => {
  const selectRef = useRef();
  const [tagNameEditable, setTagNameEditable] = useState(tag.tagName === '');
  const [localTagName, setLocalTagName] = useState(tag.tagName);
  const [isOpen, setIsOpen] = useState(false);
  const [info, setInfo] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const departmentOptions = [];
  if (departments && departments.length) {
    departments.forEach(department => {
      departmentOptions.push({ value: department._id, label: department.departmentName });
    });
  }
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: tagsLength === (tagIndex + 1) })
  const [textareaRefs, setTextareaRefs] = useState([]);
  useEffect(() => {
    // Initialize the array of refs with the correct length
    let refsArr = []
    tag.conditions.forEach(element => {
      refsArr.push(createRef())
    });
    setTextareaRefs(refsArr);
  }, [tag.conditions.length]);


  useEffect(() => {
    setLocalTagName(tag.tagName)
  }, [tag.tagName])
  const addCondition = (newIndex) => {
    if (tag[`conditions`].length > 0 && tag[`conditions`][0]) {
      if (tag[`conditions`][0].selectedItemObj0.value === '') {
        alert('Please select condition variable')
      }
      else {
        changeTag(`tags.${tagIndex}.conditions`, 'push', {
          [`operator${newIndex}`]: '=',
          [`selectedItemObj${newIndex}`]: tag[`conditions`][0].selectedItemObj0,
          [`selectedItemType${newIndex}`]: tag[`conditions`][0].selectedItemType0,
          [`selectOptions${newIndex}`]: tag[`conditions`][0].selectOptions0,
          [`resultingText${newIndex}`]: '',
          [`conditionAmountValue${newIndex}`]: '',
          [`conditionSelectValue${newIndex}`]: '',
          [`conditionValueType${newIndex}`]: 'input',//input, none
          [`resultTextType${newIndex}`]: 'input', //input, result, none, tag
          [`selectedTag${newIndex}`]: 'none',
        });
      }
    } else {
      changeTag(`tags.${tagIndex}.conditions`, 'push', {
        [`operator${newIndex}`]: '=',
        [`selectedItemObj${newIndex}`]: { value: '', label: '' },
        [`selectedItemType${newIndex}`]: '',
        [`selectOptions${newIndex}`]: [],
        [`resultingText${newIndex}`]: '',
        [`conditionAmountValue${newIndex}`]: '',
        [`conditionSelectValue${newIndex}`]: '',
        [`conditionValueType${newIndex}`]: 'input',//input, none
        [`resultTextType${newIndex}`]: 'input', //input, result, none, tag
        [`selectedTag${newIndex}`]: 'none',
      });

    }
  }


  const setTagName = (evt) => {
    setTagsValues(evt, tagIndex);
  }

  const setConditionSelectValue = (choice, conditionIndex) => {
    setHighLiteItem('');
    const conditions = tag[`conditions`];
    const newConditions = [];
    conditions.forEach((condition, cIndex) => {
      const newCondition = { ...condition };
      newCondition[`selectedItemObj${cIndex}`] = { value: choice.value, label: choice.label };
      newCondition[`selectedItemType${cIndex}`] = choice.type;
      if (choice.type === 'selectable') {
        newCondition[`selectOptions${cIndex}`] = choice.selectOptions;
        newCondition[`conditionSelectValue${cIndex}`] = choice.selectOptions[0].value;
      }
      newConditions.push(newCondition);
    });
    changeTag(`tags.${tagIndex}.conditions`, 'set', newConditions);
  };
  const setConditionValue = (evt, conditionIndex) => {
    changeTag(`tags.${evt.target.name}`, 'set', evt.target.value);
  };

  const setConditionTAValue = (evt, conditionIndex, textareaIndex) => {
    if (evt.key === 'Enter') {
      const textarea = textareaRefs[conditionIndex].current;
      const { selectionStart, selectionEnd, value } = textarea;
      // Get the text before and after the current selection
      const textBeforeSelection = value.substring(0, selectionStart);
      const textAfterSelection = value.substring(selectionEnd, value.length);
      // Create the updated value with the line break at the cursor position
      const updatedValue = `${textBeforeSelection}<br/>${textAfterSelection}`;
      // Update the textarea value and restore the cursor position
      textarea.value = updatedValue;
      textarea.selectionStart = textarea.selectionEnd = selectionStart + 5; // Set cursor after the inserted line break
      changeTag(`tags.${evt.target.name}`, 'set', updatedValue);
    }
  };

  const approveTagName = (evt) => {
    evt.preventDefault();
    resetLastTarget();
    var format = /[!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?]+/;
    let inValidTagName = false;
    if (format.test(localTagName)) {
      inValidTagName = true;
    } else {
      inValidTagName = false;
    }

    if (tagNameEditable) {
      const match = tagNames.filter(element => {
        if (element === localTagName) {
          return true;
        }
      });
      if ((match.length < 1 || tag[`tagName`] === localTagName) && localTagName !== '' && !inValidTagName) {
        setTagNameEditable(!tagNameEditable)
        if (tag[`tagName`] !== localTagName) {
          if (tag[`tagName`] !== '') {
            renameTag(tagIndex, localTagName, tag[`tagName`])
          }
          else {
            // setTagName({ target: { name: `${tagIndex}.tagName`, value: localTagName } });
            let newTags = [...tags];
            newTags[tagIndex].tagName = localTagName
            newTags = newTags.sort((a, b) => a.tagName.localeCompare(b.tagName));
            changeTag('tags', 'set', newTags);
          }
        }
      }
      else {
        alert('This tag name is invalid. Please rename tag. Please do not use these symbols in tag name: !@#$%^&*()+[]-={};:,.<>?')
      }
    }
    else {
      setTagNameEditable(true)
    }
  }

  const prevToggleValueRef = useRef(tagNameEditable);
  useEffect(() => {
    const prevToggleValue = prevToggleValueRef.current;
    if (prevToggleValue && !tagNameEditable) {
      saveBtnEvent(null, false);
    }
    prevToggleValueRef.current = tagNameEditable;
  }, [tagNameEditable]);


  const handleKeyDown = (e) => {
    const menuOptions = selectRef.current.state.prevProps.options
    const { focusedOption } = selectRef.current.state;
    const focusedIndex = menuOptions.findIndex(option => option === focusedOption);
    if (e.key === 'ArrowDown') {
      const nextOption = menuOptions[focusedIndex + 1] || menuOptions[0];
      setHighLiteItem(nextOption.value);
    } else if (e.key === 'ArrowUp') {
      const prevOption = menuOptions[focusedIndex - 1] || menuOptions[menuOptions.length - 1];
      setHighLiteItem(prevOption.value);
    }
  }

  useEffect(() => {
    if (emptyRef && emptyRef.current) {
      emptyRef.current.scrollIntoView({ block: 'start' });
      emptyRef.current.focus();
    }
  }, [emptyRef]);

  useEffect(() => {
    if (clonnedRef && clonnedRef.current) {
      clonnedRef.current.scrollIntoView({ block: 'nearest' });
      clonnedRef.current.focus();
    }
  }, [clonnedName]);

  const sameTagForAllTemplates = (evt, tagName) => {
    evt.preventDefault();
    setIsLoading(true);
    const params = { tag, templateDepType };
    fetchedData('template/sameTagForAllTemplates', 'POST', params)

      .then(response => {
        setIsLoading(false);
        if (response.status === 200) {
          if (response.data.existingInCalculationTypes && response.data.existingInCalculationTypes.length) {
            setInfo(`${tagName} already exists in: ` + response.data.existingInCalculationTypes);
          }
          if (response.data.addedCalculationTypes && response.data.addedCalculationTypes.length) {
            setErrorMessage(`${tagName} was added in: ` + response.data.addedCalculationTypes);
          }
          setIsOpen(true);
        }
        // Do something with the data
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error:', error);
      });
  };

  const hideDialog = (value) => {
    setIsOpen(value);
    setErrorMessage('');
    setInfo('');
  };

  return (
    <div className={styles.itemCont}>
      <div className={styles.blockHead}>
        <CustomPopup isOpen={isOpen} info={info} errorMessage={errorMessage} hideDialog={hideDialog} />
        {(tagNameEditable) ?
          <input type='text'
            value={localTagName}
            onChange={evt => {
              setLocalTagName(evt.target.value)
            }}
            ref={(tag[`tagName`] === '') ? emptyRef : null}
          />
          : <input type='text'
            className={(tagNameEditable) ? styles.tagNameReg : styles.tagNameB}
            placeholder='tagname'
            value={tag[`tagName`]}
            name={`${tagIndex}.tagName`}
            onChange={setTagName}
            disabled={!tagNameEditable}
            ref={(clonnedName === tag[`tagName`]) ? clonnedRef : null}
          />}

        <img src={(tagNameEditable) ? doneIcon : editIcon} alt={(tagNameEditable) ? "done" : "edit"} onClick={approveTagName} />
        <img src={copyIcon} alt="copy" onClick={evt => cloneTag(evt, tagIndex)} />
        <img src={deleteIcon} alt="delete" onClick={evt => window.confirm("Are you sure to delete this tag?") && removeTag(evt, tagIndex)} />


        <button {...getToggleProps()} className={styles.btnSubmit}>
          ㅤ
          <i className={isExpanded ? styles.up : styles.down}></i>
        </button>

      </div>

      <span className={styles.btnSubmit} style={{ display: 'none' }}></span>

      <div {...getCollapseProps()}>

        {tag[`conditions`] && tag[`conditions`].map((condition, conditionIndex) => {
          return (
            <div className={styles.ifBlock} key={conditionIndex}>

              <div className={styles.topBlock}>
                <span>IF</span>
                <Select
                  ref={selectRef}
                  value={condition[`selectedItemObj${conditionIndex}`]}
                  options={options}
                  components={{
                    Option: (props) => <CustomOption {...props}
                      setHighLiteItem={setHighLiteItem} />
                  }}
                  onChange={(choice) => setConditionSelectValue(choice, conditionIndex)}
                  onKeyDown={handleKeyDown}
                  isDisabled={conditionIndex > 0 || !tagNameEditable}
                />
                <button onClick={evt => removeCondition(evt, tagIndex, conditionIndex)}>-</button>
              </div>
              {(isLoading) ? <Loading /> : <>
                {(conditionIndex === 0 && (condition[`selectedItemObj${conditionIndex}`].value.split('_')[0] === 'mChId'
                  || condition[`selectedItemObj${conditionIndex}`].value.split('_')[0] === 'aSIdName'
                  || condition[`selectedItemObj${conditionIndex}`].value.split('_')[0] === 'aSIdAmount')) && !tagNameEditable &&
                  <>
                    <div style={{ display: 'block', width: '100 %', marginLeft: '30px', marginTop: '5px' }}>
                      <button className={styles.btnSubmitSame} onClick={evt => sameTagForAllTemplates(evt, tag.tagName)}>
                        Same for all templates
                      </button>
                    </div>
                  </>}
              </>}
              <div className={styles.middleBlock}>

                <select className={`${styles.firstSelect} ${styles.custom_select}`}
                  name={`${tagIndex}.conditions.${conditionIndex}.operator${conditionIndex}`}
                  value={condition[`operator${conditionIndex}`]}
                  onChange={(evt) => setConditionValue(evt, conditionIndex)}
                  disabled={!tagNameEditable}
                >
                  <option>=</option>
                  {condition[`selectedItemType${conditionIndex}`] === 'amount' && <option>{"<"}</option>}
                  {condition[`selectedItemType${conditionIndex}`] === 'amount' && <option>{">"}</option>}
                  <option>!=</option>
                  {/* && priceOptionAllowedItems.includes(condition[`selectedItemObj${conditionIndex}`].value.split('_')[0]) */}
                  {(condition[`selectedItemType${conditionIndex}`] === 'amount') && <option value="Value">Price</option>}
                </select>

                {(condition[`operator${conditionIndex}`] !== 'Value') ? <>
                  {(condition[`selectedItemType${conditionIndex}`] === 'amount') ? <>
                    <label>
                      <input
                        type="radio"
                        name={`${tagIndex}.conditions.${conditionIndex}.conditionValueType${conditionIndex}`}
                        value='input'
                        checked={condition[`conditionValueType${conditionIndex}`] === 'input'}
                        onChange={(evt) => setConditionValue(evt, conditionIndex)}
                        disabled={!tagNameEditable}
                      />
                      <input type='text'
                        className={styles.conditionNameInp}
                        value={condition[`conditionAmountValue${conditionIndex}`]}
                        name={`${tagIndex}.conditions.${conditionIndex}.conditionAmountValue${conditionIndex}`}
                        onChange={(evt) => setConditionValue(evt, conditionIndex)}
                        placeholder="Amount"
                        disabled={condition[`conditionValueType${conditionIndex}`] === 'none' || !tagNameEditable}
                      />
                    </label>
                    {(condition[`operator${conditionIndex}`] === '=' || condition[`operator${conditionIndex}`] === '!=') ?
                      <label>
                        <input
                          type="radio"
                          name={`${tagIndex}.conditions.${conditionIndex}.conditionValueType${conditionIndex}`}
                          value='none'
                          checked={condition[`conditionValueType${conditionIndex}`] === 'none'}
                          onChange={(evt) => setConditionValue(evt, conditionIndex)}
                          disabled={!tagNameEditable}
                        />
                        None
                      </label>
                      : ''}
                  </>
                    : <>
                      <select className={`${styles.secondSelect} ${styles.custom_select}`}
                        name={`${tagIndex}.conditions.${conditionIndex}.conditionSelectValue${conditionIndex}`}
                        value={condition[`conditionSelectValue${conditionIndex}`]}
                        onChange={(evt) => setConditionValue(evt, conditionIndex)}
                        disabled={!tagNameEditable}
                      >
                        {(condition[`selectedItemObj${conditionIndex}`].value === 'department') ? departmentOptions.map(option => <option value={option.value} key={option.value}>{option.label}</option>) : condition[`selectOptions${conditionIndex}`] && condition[`selectOptions${conditionIndex}`].map(option => <option value={option.value} key={option.value}>{option.label}</option>)}
                      </select>
                    </>}
                </> : ''}
              </div>
              <label>
                <input
                  type='checkbox'
                  name='isEditable'
                  checked={tag.isEditable}
                  onChange={(evt) => {
                    changeTag(`tags.${tagIndex}.isEditable`, 'set', evt.target.checked);
                  }}
                  defaultValue={false}
                  disabled={!tagNameEditable}
                />
                Editable
              </label>
              {(condition[`operator${conditionIndex}`] !== 'Value') && <>
                <div className={styles.bottomBlock}>
                  <div>
                    <span>THEN</span>
                    <label>
                      <input
                        type="radio"
                        name={`${tagIndex}.conditions.${conditionIndex}.resultTextType${conditionIndex}`}
                        value='input'
                        checked={condition[`resultTextType${conditionIndex}`] === 'input'}
                        onChange={(evt) => setConditionValue(evt, conditionIndex)}
                        disabled={!tagNameEditable}
                      />
                      Input
                    </label>
                    <label>
                      <input
                        type="radio"
                        name={`${tagIndex}.conditions.${conditionIndex}.resultTextType${conditionIndex}`}
                        value='result'
                        checked={condition[`resultTextType${conditionIndex}`] === 'result'}
                        onChange={(evt) => setConditionValue(evt, conditionIndex)}
                        disabled={!tagNameEditable}
                      />
                      Result
                    </label>
                    <label>
                      <input
                        type="radio"
                        name={`${tagIndex}.conditions.${conditionIndex}.resultTextType${conditionIndex}`}
                        value='none'
                        checked={condition[`resultTextType${conditionIndex}`] === 'none'}
                        onChange={(evt) => setConditionValue(evt, conditionIndex)}
                        disabled={!tagNameEditable}
                      />
                      None
                    </label>
                    <label>
                      <input
                        type="radio"
                        name={`${tagIndex}.conditions.${conditionIndex}.resultTextType${conditionIndex}`}
                        value='tag'
                        checked={condition[`resultTextType${conditionIndex}`] === 'tag'}
                        onChange={(evt) => setConditionValue(evt, conditionIndex)}
                        disabled={!tagNameEditable}
                      />
                      Tag
                    </label>
                  </div>
                  {(condition[`resultTextType${conditionIndex}`] === 'tag') ?
                    <select className={`${styles.select} ${styles.custom_select}`}
                      name={`${tagIndex}.conditions.${conditionIndex}.selectedTag${conditionIndex}`}
                      value={condition[`selectedTag${conditionIndex}`]}
                      onChange={(evt) => setConditionValue(evt, conditionIndex)}
                      disabled={!tagNameEditable}
                    >
                      <option value='none'>Select tag</option>
                      {tags.map((currentTag, index) => {
                        if (tag.tagName !== currentTag.tagName) {
                          return <option
                            value={'|{' + currentTag[`tagName`] + '}'}
                            key={currentTag[`tagName`]}>
                            {currentTag[`tagName`]}
                          </option>
                        }
                      })}
                    </select>
                    : ''}
                  {(condition[`resultTextType${conditionIndex}`] === 'result') ?
                    <textarea
                      type='text'
                      value='RESULT VALUE'/* {resultText} */
                      disabled={true}
                    />
                    : ''}
                  {(condition[`resultTextType${conditionIndex}`] === 'none') ?
                    <textarea
                      type='text'
                      name=''
                      value=''
                      disabled={true}
                    />
                    : ''}
                  {(condition[`resultTextType${conditionIndex}`] === 'input') ?
                    <textarea
                      ref={textareaRefs[conditionIndex]}
                      type='text'
                      name={`${tagIndex}.conditions.${conditionIndex}.resultingText${conditionIndex}`}
                      value={condition[`resultingText${conditionIndex}`]}
                      onChange={(evt) => { setConditionValue(evt, conditionIndex) }}
                      onKeyDown={(evt) => { setConditionTAValue(evt, conditionIndex, tagIndex) }}
                      disabled={condition[`resultTextType${conditionIndex}`] !== 'input' || !tagNameEditable}
                    />
                    : ''}
                </div>
              </>}
            </div>
          )
        })}
        {(tag[`conditions`][0][`operator0`] !== 'Value') && <>
          <div className={styles.buttonContainer}>
            <button
              className={styles.extraBtn}
              onClick={
                (evt) => {
                  evt.preventDefault();
                  addCondition(tag[`conditions`].length);
                }
              }
            >
              Add condition
              <Icon name={IconNames.PLUS} />
            </button>
            <div className={styles.bottomBlock}>
              <div>
                <span>ELSE</span>
                <label>
                  <input
                    type="radio"
                    name={`${tagIndex}.elseTextType`}
                    value='input'
                    checked={tag[`elseTextType`] === 'input'}
                    onChange={(evt) => setTagsValues(evt, tagIndex)}
                    disabled={!tagNameEditable}
                  />
                  Input
                </label>
                {/* Result
              <input
                type="radio"
                name={`${tagIndex}.elseTextType`}
                value='result'
                checked={tag[`elseTextType`] === 'result'}
                onChange={(evt) => setTagsValues(evt, tagIndex)}
              /> */}
                <label>
                  <input
                    type="radio"
                    name={`${tagIndex}.elseTextType`}
                    value='none'
                    checked={tag[`elseTextType`] === 'none'}
                    onChange={(evt) => setTagsValues(evt, tagIndex)}
                    disabled={!tagNameEditable}
                  />
                  None
                </label>
                <label>
                  <input
                    type="radio"
                    name={`${tagIndex}.elseTextType`}
                    value='tag'
                    checked={tag[`elseTextType`] === 'tag'}
                    onChange={(evt) => setTagsValues(evt, tagIndex)}
                    disabled={!tagNameEditable}
                  />
                  Tag
                </label>
              </div>
              {(tag[`elseTextType`] !== 'tag') ?
                <textarea
                  type='text'
                  name={`${tagIndex}.elseText`}
                  value={tag[`elseText`]}
                  className={styles.conditionNameInp}
                  onChange={(evt) => setTagsValues(evt, tagIndex)}
                  disabled={tag[`elseTextType`] !== 'input' || !tagNameEditable}
                />
                : <>
                  <select className={`${styles.select} ${styles.custom_select}`}
                    name={`${tagIndex}.elseSelectedTag`}
                    value={tag[`elseSelectedTag`]}
                    onChange={(evt) => setTagsValues(evt, tagIndex)}
                    disabled={!tagNameEditable}
                  >
                    <option value='none'>Select tag</option>
                    {tags.map((tag, index) => {
                      return <option
                        value={'|{' + tag[`tagName`] + '}'}
                        key={tag[`tagName`]}>
                        {tag[`tagName`]}
                      </option>
                    })}
                  </select>
                </>}
            </div>
          </div>
        </>}
      </div>
      <hr className={styles.hLine} />
    </div>
  );
};
