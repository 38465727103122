// QuantityInput.jsx
import React, { useEffect } from 'react';
import styles from './BoxesInput.module.css';

const BoxesInput = ({ onFormValueChange, path, inputName, boxes, value, index, hasRole }) => {

  const handleChange = (e) => {
    onFormValueChange(path, 'set', { ...boxes, [inputName]: e.target.value })
  }

  const handleMinus = () => {
    onFormValueChange(path, 'set', { ...boxes, [inputName]: Number(value) - 1 })
  }

  const handlePlus = () => {
    onFormValueChange(path, 'set', { ...boxes, [inputName]: Number(value) + 1 })
  }


  return (
    <div className={styles.buttonContainer}>
      <div className={`${styles.button} ${!hasRole ? styles.buttonDisabled : ''}`} onClick={hasRole ? handleMinus : null}>
        <svg className={styles.svgIcon} viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg" >
          <rect x="4" y="11" width="16" height="2" />
        </svg>
      </div>
      <input type="number" className={styles.inputBox} value={value} onChange={handleChange} disabled={!hasRole} />
      <div className={`${styles.button} ${!hasRole ? styles.buttonDisabled : ''}`} onClick={hasRole ? handlePlus : null}>
        <svg className={styles.svgIcon} viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
          <rect x="11" y="4" width="2" height="16" />
          <rect x="4" y="11" width="16" height="2" />
        </svg>
      </div>
    </div>
  );
};

export default BoxesInput;