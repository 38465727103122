// StopSelect.jsx
import React, { useEffect, useState } from 'react';
import styles from './StopSelect.module.css';

const StopSelect = ({ value, onFormValueChange, options, itemIndex, selectedRoomIndex, furnitureType, name, hasRole }) => {
  const [selectedColor, setSelectedColor] = useState('');
  useEffect(() => {
    const selectedOption = options.find((option, oIndex) => oIndex == value);
    setSelectedColor(selectedOption ? selectedOption.color : '');
  }, [options, value]);

  const handleChange = (event) => {
    const selectedOption = options.find((option, oIndex) => oIndex == event.target.value);
    setSelectedColor(selectedOption ? selectedOption.color : '');
    if (onFormValueChange) {
      onFormValueChange(`inventory.rooms.${selectedRoomIndex}.${furnitureType}.${itemIndex}.${name}`, 'set', event.target.value)
    }
  };


  return (
    <div style={{ display: 'flex' }} >
      <select className={styles.inputBox} value={value}
        onChange={handleChange}
        style={{ backgroundColor: selectedColor }}
        tabIndex={options.length > 1 ? 0 : -1}
        disabled={!hasRole}
      >
        {options.map((option, oIndex) => (
          <option key={option.name} value={oIndex} style={{ backgroundColor: option.color }}>
            {option.name}
          </option>
        ))}
      </select>

    </div>
  );
};

export default StopSelect;
