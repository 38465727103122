// Third party libraries
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
// Local State Functions
import {
  removeCalculation,
  removeCalculationHard,
  cloneCalculation,
  restoreCalculation,
} from '../../clientStore/calculationSlice/calculation-async-thunk';

// Styles
import styles from './CalculationTableBodyItem.module.css';
import deleteIcon from './../../img/delete.png';
import restoreIcon from './../../img/restore.png';
import copyIcon from './../../img/copy.png';
import dragHandle from './../../img/drag_handle_corner.png';
import { Icon } from '../Icon';
import { IconNames, Role } from '../../util/const';
import { ChildCalcsMain } from '../child-calcs-main/Child-calcs-main';
import { setGroupCalculationsLoadingStatus } from '../../clientStore/calculationsSlice/calculations-slice';
import { hasRole } from '../../util/utils';
import { getUserRole } from '../../clientStore/authSlice/auth-slice';

export const CalculationTableBodyItem = ({ data, handleExpandToggle, isExpanded, isCalculationPage, isFirstItem }) => {
  const { url } = useRouteMatch();
  var resultURL = url.substring(0, url.lastIndexOf("/"));
  if (!resultURL) {
    resultURL = url;
  }
  const dispatch = useDispatch();
  const currentUserRole = useSelector(getUserRole);

  const renderIcons = (data, dispatch) => (
    <>
      {data.isDeleted && (
        <img
          src={restoreIcon}
          onClick={() => dispatch(restoreCalculation(data._id))}
          className={styles.icon}
          style={{ marginRight: "20px" }}
        />
      )}
      {!data.isDeleted && (
        <img
          src={copyIcon}
          alt="Copy icon"
          className={styles.icon}
          style={{ marginRight: "20px" }}
          onClick={() => dispatch(cloneCalculation(data._id))}
        />
      )}
      <img
        src={deleteIcon}
        alt="Delete icon"
        onClick={
          !data.isDeleted
            ? () => window.confirm("Are you sure to delete calculation?") && dispatch(removeCalculation(data._id))
            : () => window.confirm("Are you sure to hard delete calculation?") && dispatch(removeCalculationHard(data._id))
        }
        className={styles.icon}
        style={{ marginLeft: "20px" }}
      />
    </>
  );

  const userHasRequiredRole = hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR]);


  return (
    <li key={data._id} className={`${styles.listItem} ${styles.link}`}>
      <div className={styles.txt}>
        {!isCalculationPage && data.isSubCalc && <img src={dragHandle} className={styles.dragIcon} style={{ marginLeft: '-20px' }} />}
        {data.hasSubCalcs && (<button onClick={() => handleExpandToggle(data._id)} className={styles.expandButton} style={{ marginRight: '5px' }}>
          <span className={styles.arrowIcon}>{isExpanded ? '▼' : '►'}</span>
        </button>)}
        <Link to={`${resultURL}/${data._id}`} ><span>{data.calculationNumber}</span> </Link>
      </div>
      <Link to={`${resultURL}/${data._id}`} className={styles.txt}><span>{(data.departmentId) ? data.departmentId.departmentName : "N/A"}</span></Link>
      {isCalculationPage ? (
        !isFirstItem ? (
          <span className={styles.txt}>
            {userHasRequiredRole && renderIcons(data, dispatch)}
          </span>
        ) : (
          <span style={{ width: '225px' }}></span>
        )
      ) : (
        <span className={styles.txt}>
          {userHasRequiredRole && renderIcons(data, dispatch)}
        </span>
      )}
      <Link to={`${resultURL}/${data._id}`} className={styles.txt}><span>{(data.userId) ? data.userId.login : "N/A"}</span></Link>
      <Link to={`${resultURL}/${data._id}`} className={styles.txt}><span>{new Date(data.createdAt).toDateString()}</span></Link>
    </li>
  );

};