// Third party libraries
import { nanoid } from 'nanoid'
// Components
import { MainLink } from '../main-link/Main-link';
// Styles
import styles from './Estimates.module.css';
import { getUserRole } from '../../clientStore/authSlice/auth-slice';
import { useSelector } from 'react-redux';
import { Role } from '../../util/const';
import { hasRole } from '../../util/utils';



export const Estimates = () => {
  const currentUserRole = useSelector(getUserRole);
  const ESTIMATES_LINKS = [
    {
      name: 'Calculator',
      description: 'Customizable calculator',
      link: '/calculations',
    }
  ];
  if (hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR])) {
    ESTIMATES_LINKS.push(
      {
        name: 'settings',
        description: 'Parameters settings',
        link: '/settings',//departments
      }
    )
  }

  return (
    <section className={styles.container}>
      {/*  <h2 className={styles.title}>Estimates</h2> */}
      <ul className={styles.list}>
        {ESTIMATES_LINKS.map((item) => {
          return (
            <li className={styles.item} key={nanoid(ESTIMATES_LINKS.length)}>
              <MainLink
                linkData={item}
              />
            </li>
          )
        })}
      </ul>
    </section>
  );
}