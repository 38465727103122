import { configureStore } from '@reduxjs/toolkit';

import authReducer from './authSlice/auth-slice';
import actionListReducer from './actionsSlice/actions-slice';
import actionReducer from './actionSlice/action-slice';
import companyListReducer from './companiesSlice/companies-slice';
import companyReducer from './companySlice/company-slice';
import calculationListReducer from './calculationsSlice/calculations-slice';
import calculationReducer from './calculationSlice/calculation-slice';
import departmentListReducer from './departmentsSlice/departments-slice';
import departmentReducer from './departmentSlice/department-slice';
import furnitureReducer from './furnitureSlice/furniture-slice';
import roomsAreasReducer from './roomsAreasSlice/roomsAreas-slice';
import unpDepartmentReducer from './unpDepartmentSlice/unp-department-slice';
import longdistanceListReducer from './longdistancesSlice/longdistances-slice';
import longdistanceReducer from './longdistanceSlice/longdistance-slice';
import outofstateListReducer from './outofstatesSlice/outofstates-slice';
import outofstateReducer from './outofstateSlice/outofstate-slice';
import usersReducer from './usersSlice/user-slice';
import templateListReducer from './templatesSlice/templates-slice';
import templateReducer from './templateSlice/template-slice';
import calculatedParamsReducer from './calculatedParamsSlice/calculated-params-slice';

export default configureStore({
  reducer: {
    auth: authReducer,
    actionList: actionListReducer,
    action: actionReducer,
    companyList: companyListReducer,
    company: companyReducer,
    calculationList: calculationListReducer,
    calculation: calculationReducer,
    departmentList: departmentListReducer,
    department: departmentReducer,
    furniture: furnitureReducer,
    roomsAreas: roomsAreasReducer,
    longdistanceList: longdistanceListReducer,
    longdistance: longdistanceReducer,
    outofstateList: outofstateListReducer,
    outofstate: outofstateReducer,
    users: usersReducer,
    templateList: templateListReducer,
    template: templateReducer,
    unpDepartment: unpDepartmentReducer,
    calculatedParams: calculatedParamsReducer,
  }
});
