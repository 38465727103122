// Third party libraries
import { nanoid } from 'nanoid';
import useCollapse from 'react-collapsed';
import { getTruckFeeMin } from '../../util/utils';
import * as immutable from 'object-path-immutable';
import { useSelector } from 'react-redux';
import clockIcon from './../../img/clock.png';
// Styles
import styles from './CalculationTabTwoDaysValues.module.css';
import { ExtraOptionsValues } from '../extra-options-values/Extra-options-values';
import { MiscChechMarksValues } from '../misc-checkmarks-values/Misc-checkmarks-values';
import { AdditionalServicesValues } from '../additional-services-values/Additional-services-values';
import { useEffect, useState } from 'react';
import AdjustTime from '../adjust-time/Adjust-time';

export const CalculationTabTwoDaysValues = ({ rates, setCalculationFormValue, formData, formChange, resetLastTarget,
  sepCheckboxValueChange, templatePage, validationError, setValidationError, lastTarget, setLastTarget, checkMarkCheckboxValueChange,
  additionalServicesCheckboxValueChange, disabled }) => {
  const TWO_Trips_Or_OK_Day2 = useSelector(state => state.calculatedParams.TWO_Trips_Or_OK_Day2);
  const [showInputs, setShowInputs] = useState(false);
  const [showInputsSecond, setShowInputsSecond] = useState(false);
  const [showInputsUnpacking, setShowInputsUnpacking] = useState(false);

  const onValueChange = (evt) => {
    resetLastTarget();
    formChange(`twoDaysPacking.${evt.target.name}`, 'set', evt.target.value)
    if (typeof setValidationError === 'function') {
      if ((evt.target.name === 'moversToDrivesAndUnload') && parseInt(evt.target.value) > parseInt(formData.twoDaysPacking.moversToLoad)) {
        setValidationError({ ...validationError, [formData.calculationType]: 'Movers to drive and unload can not be more than Movers to load!' });
      } else
        if ((evt.target.name === 'moversToLoad') && parseInt(evt.target.value) < parseInt(formData.twoDaysPacking.moversToDrivesAndUnload)) {
          setValidationError({ ...validationError, [formData.calculationType]: 'Movers to drive and unload can not be more than Movers to load!' });
        } else {
          setValidationError('');
        }
    }
  }

  const isCuFtDepartment = formData.department?.type === 'cuft';

  const checkboxValueChange = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        return formChange(`twoDaysPacking.${evt.target.name}`, 'set', false);
      case 'false':
        return formChange(`twoDaysPacking.${evt.target.name}`, 'set', true);
      default:
        break;
    }
  };

  useEffect(() => {
    if (isCuFtDepartment) {
      formChange(`commonValues.unpackingRate`, 'set', formData.twoDaysPacking.firstDayRate);
    }
  }, []);

  const chargeCheckboxValueChangeDayOne = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedChargesNames = [...formData.twoDaysPacking.selectedChargesNamesDay1];
        var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedChargesNames.splice(index, 1);
          return formChange(`twoDaysPacking.selectedChargesNamesDay1`, 'set', newSelectedChargesNames);
        }
        break;
      case 'false':
        return formChange(`twoDaysPacking.selectedChargesNamesDay1`, 'push', { key: evt.target.name, quantity: 1 });
      default:
        break;
    }
  };

  const chargeCheckboxValueChangeDayTwo = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedChargesNames = [...formData.twoDaysPacking.selectedChargesNamesDay2];
        var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedChargesNames.splice(index, 1);
          return formChange(`twoDaysPacking.selectedChargesNamesDay2`, 'set', newSelectedChargesNames);
        }
        break;
      case 'false':
        return formChange(`twoDaysPacking.selectedChargesNamesDay2`, 'push', { key: evt.target.name, quantity: 1 });
        break;
      default:
        break;
    }
  };

  const chargeQuantityChangeDayOne = (evt) => {
    resetLastTarget();
    setLastTarget(evt.target.name)
    var newSelectedChargesNames = JSON.parse(JSON.stringify(formData.twoDaysPacking.selectedChargesNamesDay1));
    var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedChargesNames[index].quantity = Number(evt.target.value)
      return formChange(`twoDaysPacking.selectedChargesNamesDay1`, 'set', newSelectedChargesNames);
    }
  }

  const chargeQuantityChangeDayTwo = (evt) => {
    resetLastTarget();
    setLastTarget(evt.target.name)
    var newSelectedChargesNames = JSON.parse(JSON.stringify(formData.twoDaysPacking.selectedChargesNamesDay2));
    var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedChargesNames[index].quantity = Number(evt.target.value)
      return formChange(`twoDaysPacking.selectedChargesNamesDay2`, 'set', newSelectedChargesNames);
    }
  }

  const proceduresCheckboxValueChange = (evt) => {
    let twoDaysPackingTemp = { ...formData.twoDaysPacking };
    twoDaysPackingTemp.proceduresOnDay1 = !twoDaysPackingTemp.proceduresOnDay1;
    twoDaysPackingTemp.proceduresOnDay2 = !twoDaysPackingTemp.proceduresOnDay2;
    return formChange(`twoDaysPacking`, 'set', twoDaysPackingTemp);
  };

  return (
    <section className={styles.container}>
      <div className={styles.groupInput}>
        <div className={styles.decorateContainer1} style={{ flexDirection: 'column' }}>
          <label className={styles.commonLabel} style={{ display: 'inline-block', marginLeft: '0px' }}>
            1st date:
            <input
              className={styles.dateInput}
              type='date'
              name={'firstDayDate'}
              value={formData.twoDaysPacking.firstDayDate}
              onChange={onValueChange}
              disabled={disabled}
            />
          </label>
          <label className={styles.commonLabel} style={{ display: 'inline-block', marginLeft: '0px' }}>
            2nd date:
            <input
              className={styles.dateInput}
              type='date'
              name={'secondDayDate'}
              value={formData.twoDaysPacking.secondDayDate}
              onChange={onValueChange}
              disabled={disabled}
            />
          </label>


        </div>
        <label style={{ marginTop: "5px" }} >
          Additional amount:
          <input
            onWheel={(e) => e.target.blur()}
            style={{ width: "60px" }}
            type='number'
            name={'additionalAmount'}
            value={(formData.twoDaysPacking) ? formData.twoDaysPacking.additionalAmount : ''}
            placeholder={'ex: 2'}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>
      </div>

      <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", width: '550px' }}>
        <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>DAY - 1 (Packing)</span>
            <img src={clockIcon} onClick={() => setShowInputs(!showInputs)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
          </div>
          {showInputs && (
            <fieldset className={styles.groupInput}>
              <div className={styles.decorateContainer}>
                <AdjustTime
                  name={'addTimeFirstDay'}
                  value={formData.commonValues.addTimeFirstDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Add time:'}
                  disabled={disabled}
                />
                <AdjustTime
                  name={'removeTimeFirstDay'}
                  value={formData.commonValues.removeTimeFirstDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Remove time:'}
                  disabled={disabled}
                />
              </div>
            </fieldset>
          )}
        </div>
        <div style={{ padding: '5px' }}>
          <div style={{ display: 'flex' }}>
            {rates.length === 0 && <p className={styles.warning} style={{ margin: '0px', marginLeft: '20px' }}>Select Department!</p>}
            {rates.length > 0 &&
              <label className={styles.decorateContainer1}>
                Rate:
                <select
                  className={styles.select}
                  value={formData.twoDaysPacking ? formData.twoDaysPacking.firstDayRate : 'none'}
                  onChange={(evt) => {
                    resetLastTarget();
                    /*   formChange('twoDaysPacking.firstDayRate', 'set', evt.target.value);
                    */
                    const newValue = evt.target.value;
                    const newState = immutable.set(formData, 'twoDaysPacking.firstDayRate', newValue);
                    setCalculationFormValue(newState);
                    if (isCuFtDepartment) {
                      const secondDayRatePath = 'twoDaysPacking.secondDayRate';
                      const secondDayRateState = immutable.set(newState, secondDayRatePath, newValue);
                      setCalculationFormValue(secondDayRateState);
                      const unpackingRatePath = 'commonValues.unpackingRate';
                      const unpackingRateState = immutable.set(secondDayRateState, unpackingRatePath, newValue)
                      setCalculationFormValue(unpackingRateState)
                    }
                  }}
                  style={{ marginBottom: '10px' }}
                  disabled={disabled}
                >
                  <option value={''}>None</option>
                  {
                    rates.map((rate, index) => {
                      return (
                        <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                      );
                    })
                  }
                </select>
              </label>
            }


            {formData.commonValues?.shuttle &&
              <div style={{ display: 'flex' }} className={styles.groupInput}>
                <label className={styles.shuttleOnDayOne}>
                  Shuttle on Day 1
                  <input
                    type='checkbox'
                    name={'shuttleOnDayOne'}
                    value={(formData.twoDaysPacking) ? formData.twoDaysPacking.shuttleOnDayOne : false}
                    checked={(formData.twoDaysPacking) ? formData.twoDaysPacking.shuttleOnDayOne : false}
                    onChange={checkboxValueChange}
                    disabled={disabled}
                  />
                </label>
                {(formData.department?.extraOptions?.shuttle.isCountable && formData.twoDaysPacking.shuttleOnDayOne) &&
                  <label className={styles.inputContainer}>
                    Qty:
                    <input
                      type='number'
                      name={'quantityDay1'}
                      value={formData.twoDaysPacking.quantityDay1}
                      placeholder={'ex: 2'}
                      onChange={onValueChange}
                      onWheel={(e) => e.target.blur()}
                      disabled={disabled}
                    />
                  </label>
                }
              </div>
            }
          </div>
          {(formData.commonValues.procedures.length) ?
            <label className={styles.inputContainer}>
              Procedures on Day 1
              <input
                className={styles.mycheckbox}
                type='checkbox'
                name={'proceduresOnDay1'}
                value={(formData.twoDaysPacking) ? formData.twoDaysPacking.proceduresOnDay1 : false}
                checked={(formData.twoDaysPacking) ? formData.twoDaysPacking.proceduresOnDay1 : false}
                onChange={proceduresCheckboxValueChange}
                disabled={disabled}
              />
            </label>
            :
            ''}

          <fieldset className={styles.groupInput} style={{ marginTop: '10px' }}>
            <div className={styles.divide}>
              {formData.department && formData.department.truckFee && formData.department.truckFee.calcMethod === "different" &&
                <label className={styles.inputContainer1} style={{ display: 'inline-block', marginLeft: '20px' }}>
                  Truck fee:
                  <input
                    onWheel={(e) => e.target.blur()}
                    type='number'
                    name={'truckFee'}
                    value={(formData._id) ? (formData.twoDaysPacking) ? formData.twoDaysPacking.truckFee : '' : getTruckFeeMin(formData.department.truckFee.diffTruckReservations)}
                    placeholder={'ex: 40'}
                    onChange={onValueChange}
                    disabled={disabled}
                  />
                </label>
              }


              <label className={styles.inputContainer} >
                <span>Movers to pack:</span>
                <select
                  className={styles.commonInput}
                  name={'moversToPack'}
                  value={(formData.twoDaysPacking) ? formData.twoDaysPacking.moversToPack : ''}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                  <option>13</option>
                  <option>14</option>
                  <option>15</option>
                </select>
              </label>

              <label className={styles.inputContainer}>
                Cubic Ft to move:
                <input
                  onWheel={(e) => e.target.blur()}
                  style={{ width: "45px" }}
                  type='number'
                  name={'cubicFtToMoveDay1'}
                  value={(formData.twoDaysPacking) ? formData.twoDaysPacking.cubicFtToMoveDay1 : ''}
                  placeholder={'ex: 2'}
                  onChange={evt => { if (Number(formData.commonValues.cubicFt) >= evt.target.value) { onValueChange(evt) } else { alert('Cubic Ft to move can not be more than Cubic Ft!') } }}
                  disabled={disabled}
                />
              </label>

              <label className={styles.inputContainer} style={{ display: 'inline-block', marginLeft: '20px', width: templatePage ? '200px' : null, marginLeft: templatePage ? '20px' : null }}>
                <span>Add Trucks:</span>
                <select
                  className={styles.commonInput}
                  name={'addTrucksDay1'}
                  value={(formData.twoDaysPacking) ? formData.twoDaysPacking.addTrucksDay1 : ''}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                </select>
              </label>
            </div>

          </fieldset>
          <fieldset className={styles.groupInput}>
            <div className={styles.decorateContainer1}>
              {(formData.department && formData.department.extraOptions && formData.department.extraOptions.charges.length) ?
                <>  <hr className={styles.hLine} />
                  <ExtraOptionsValues
                    charges={(formData.department && formData.department.extraOptions) ? formData.department.extraOptions.charges : []}
                    chargeCheckboxValueChange={chargeCheckboxValueChangeDayOne}
                    selectedChargesNames={formData.twoDaysPacking.selectedChargesNamesDay1}

                    lastTarget={lastTarget}
                    chargeQuantityChange={chargeQuantityChangeDayOne}
                    disabled={disabled}
                  />
                </>
                : ''
              }
            </div>
          </fieldset>
        </div>
      </div>

      <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", marginTop: '20px', width: '550px' }}>
        <fieldset className={styles.groupInput} >
          <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>DAY - 2 (Moving)</span>
              <img src={clockIcon} onClick={() => setShowInputsSecond(!showInputsSecond)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
            </div>
            {showInputsSecond && (
              <fieldset className={styles.groupInput}>
                <div className={styles.decorateContainer}>
                  <AdjustTime
                    name={'addTimeSecondDay'}
                    value={formData.commonValues.addTimeSecondDay}
                    onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                    labelText={'Add time:'}
                    disabled={disabled}
                  />
                  <AdjustTime
                    name={'removeTimeSecondDay'}
                    value={formData.commonValues.removeTimeSecondDay}
                    onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                    labelText={'Remove time:'}
                    disabled={disabled}
                  />
                </div>
              </fieldset>
            )}
          </div>
          <div style={{ padding: '5px' }}>
            <fieldset className={styles.groupInputSecondDay}>
              <div style={{ display: 'flex' }}>
                {rates.length === 0 && <p className={styles.warning} style={{ margin: '0px', marginLeft: '20px', marginRight: "20px" }}>Select Department!</p>}
                {rates.length > 0 &&
                  <label className={styles.decorateContainer1} style={{ marginBottom: '10px', display: 'block' }}>
                    Rate:
                    <select
                      className={styles.select}
                      value={(formData.twoDaysPacking) ? formData.twoDaysPacking.secondDayRate : 'none'}
                      onChange={(evt) => {
                        resetLastTarget();
                        formChange('twoDaysPacking.secondDayRate', 'set', evt.target.value);
                      }}
                      disabled={isCuFtDepartment || disabled}
                    >
                      <option value={''}>None</option>
                      {
                        rates.map((rate, index) => {
                          return (
                            <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                          );
                        })
                      }
                    </select>
                  </label>
                }
                <div className={styles.vl}></div>
                {isCuFtDepartment &&
                  <label style={{ marginLeft: '10px' }}>Type of place
                    <select value={formData.commonValues.additionalServicesPlaceType}
                      className={styles.commonInput}
                      onChange={evt => formChange(`commonValues.additionalServicesPlaceType`, 'set', evt.target.value)}
                      disabled={disabled}
                    >
                      <option>None</option>
                      {formData.department.additionalServices.placeTypes.length && formData.department.additionalServices.placeTypes.map((placeType, pcftIndex) => {
                        return <option>{placeType.placeTypeName}</option>
                      })}
                    </select>
                  </label>
                }
                {formData.commonValues?.shuttle &&
                  <div style={{ display: 'flex' }} className={styles.groupInput}>
                    <label className={styles.shuttleOnDayOne}>
                      Shuttle on Day 2
                      <input
                        type='checkbox'
                        name={'shuttleOnDayOne'}
                        value={(formData.twoDaysPacking) ? formData.commonValues?.shuttle : false}
                        checked={(formData.twoDaysPacking) ? formData.commonValues?.shuttle : false}
                        onChange={checkboxValueChange}
                        disabled
                      />
                    </label>
                    {(formData.department?.extraOptions?.shuttle.isCountable && formData.commonValues?.shuttle) &&
                      <label className={styles.inputContainer}>
                        Qty:
                        <input
                          type='number'
                          name={'quantityDay2'}
                          value={formData.twoDaysPacking.quantityDay2}
                          placeholder={'ex: 2'}
                          onChange={onValueChange}
                          onWheel={(e) => e.target.blur()}
                          disabled={disabled}
                        />
                      </label>
                    }
                  </div>
                }
              </div>

              {(formData.commonValues.procedures.length) ?

                <label className={styles.inputContainer} style={{ marginBottom: '10px' }}>
                  Procedures on Day 2
                  <input
                    className={styles.mycheckbox}
                    type='checkbox'
                    name={'proceduresOnDay2'}
                    value={(formData.twoDaysPacking) ? formData.twoDaysPacking.proceduresOnDay2 : false}
                    checked={(formData.twoDaysPacking) ? formData.twoDaysPacking.proceduresOnDay2 : false}
                    onChange={proceduresCheckboxValueChange}
                    disabled={disabled}
                  />
                </label>
                : ''}


              <div style={{ display: 'flex' }}>
                <label className={styles.commonLabel}>
                  <span>Add Movers:</span>
                  <select
                    className={styles.commonInput}
                    name={'addMovers'}
                    value={(formData.twoDaysPacking) ? formData.twoDaysPacking.addMovers : ''}
                    onChange={onValueChange}
                    style={{ marginBottom: '10px' }}
                    disabled={disabled}
                  >
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                  {/*  <div style={{ width:'200px' }}></div> */}
                </label>


                <label className={styles.commonLabel} style={{ marginLeft: '20px', marginBottom: '10px', width: '200px' }} >
                  <span>Remove Movers:</span>
                  <select
                    className={styles.commonInput}
                    name={'removeMovers'}
                    value={(formData.twoDaysPacking) ? formData.twoDaysPacking.removeMovers : ''}
                    onChange={onValueChange}
                    style={{ marginBottom: '10px' }}
                    disabled={disabled}
                  >
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </label>
              </div>

              <div style={{ display: 'flex' }}>
                <label className={styles.commonLabel} style={{ marginBottom: '10px' }}>
                  <span>Add Trucks:</span>
                  <select
                    className={styles.commonInput1}
                    name={'addTrucksDay2'}
                    value={(formData.twoDaysPacking) ? formData.twoDaysPacking.addTrucksDay2 : ''}
                    onChange={onValueChange}
                    style={{ marginBottom: '10px' }}
                    disabled={disabled}
                  >
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                  </select>
                </label>
                <div>
                  <label className={styles.commonLabel} style={{ marginLeft: '50px' }}>
                    <span>Remove Trucks:</span>
                    <select
                      className={styles.commonInput1}
                      name={'removeTrucks'}
                      value={(formData.twoDaysPacking) ? formData.twoDaysPacking.removeTrucks : ''}
                      onChange={onValueChange}
                      disabled={disabled}
                    >
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </label>
                </div>

                {(TWO_Trips_Or_OK_Day2 === '2 TRIPS' || formData.twoDaysPacking.noTwoTrips) ?
                  <label className={styles.procedure} style={{ marginBottom: '10px', width: templatePage ? '200px' : null, marginLeft: templatePage ? '20px' : null }}>
                    NO 2 TRIPS
                    <input
                      className={styles.mycheckbox}
                      type='checkbox'
                      name={'noTwoTrips'}
                      value={(formData.twoDaysPacking) ? formData.twoDaysPacking.noTwoTrips : false}
                      checked={(formData.twoDaysPacking) ? formData.twoDaysPacking.noTwoTrips : false}
                      onChange={checkboxValueChange}
                      style={{ marginBottom: '10px' }}
                      disabled={disabled}
                    />
                  </label>
                  : ''}
              </div>
              <div className={styles.decorateContainer} style={{ marginBottom: '10px' }}>
                <label className={styles.inputContainer}>
                  Movers to Load:
                  <input
                    onWheel={(e) => e.target.blur()}
                    type='number'
                    name={'moversToLoad'}
                    value={(formData.twoDaysPacking) ? formData.twoDaysPacking.moversToLoad : ''}
                    placeholder={'ex: 2'}
                    onChange={onValueChange}
                    disabled={disabled}
                  />
                </label>
                <label className={styles.inputContainer}>
                  Movers to drive and unload:
                  <input
                    onWheel={(e) => e.target.blur()}
                    type='number'
                    name={'moversToDrivesAndUnload'}
                    value={(formData.twoDaysPacking) ? formData.twoDaysPacking.moversToDrivesAndUnload : ''}
                    placeholder={'ex: 2'}
                    onChange={onValueChange}
                    disabled={disabled}
                  />
                </label>
                {validationError && <div className={styles.error}>{validationError[formData.calculationType]}</div>}
              </div>







            </fieldset>
            {formData.department && formData.department.extraOptions && formData.department.extraOptions.charges.length &&
              <div >
                <ExtraOptionsValues
                  charges={(formData.department && formData.department.extraOptions) ? formData.department.extraOptions.charges : []}
                  chargeCheckboxValueChange={chargeCheckboxValueChangeDayTwo}
                  selectedChargesNames={formData.twoDaysPacking.selectedChargesNamesDay2}
                  lastTarget={lastTarget}
                  chargeQuantityChange={chargeQuantityChangeDayTwo}
                  disabled={disabled}
                />
              </div>
            }


            <hr className={styles.hLine} style={{ marginLeft: "0px" }} />


            <div>
              {(formData.department && formData.department?.type === 'cuft') &&
                <AdditionalServicesValues
                  additionalServices={(formData.department && formData.department.additionalServices) ? formData.department.additionalServices : []}
                  selectedAdditionalServices={formData.commonValues.selectedAdditionalServices}
                  additionalServicesCheckboxValueChange={additionalServicesCheckboxValueChange}
                  additionalServicesPlaceType={formData.commonValues.additionalServicesPlaceType}
                  formChange={formChange}
                  disabled={disabled}
                />
              }
            </div>
            <hr className={styles.hLine} style={{ marginLeft: "0px" }} />
            <MiscChechMarksValues
              miscCheckMarks={(formData.department && formData.department.miscCheckMarks && formData.department.miscCheckMarks.miscCheckMarks.length) ? formData.department.miscCheckMarks.miscCheckMarks : []}
              selectedMiscCheckMarks={formData.selectedMiscCheckMarks}
              checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
              disabled={disabled}
            />
          </div>
        </fieldset>

      </div>


      {formData.commonValues.unpacking === 'Separate Day' &&
        <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", marginTop: '20px', width: '550px' }}>
          <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>UNPACKING ON SEPARATE DAY</span>
              <img src={clockIcon} onClick={() => setShowInputsUnpacking(!showInputsUnpacking)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
            </div>
            {showInputsUnpacking && (
              <fieldset className={styles.groupInput}>
                <div className={styles.decorateContainer}>
                  <AdjustTime
                    name={'addTimeUnp'}
                    value={formData.commonValues.addTimeUnp}
                    onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                    labelText={'Add time:'}
                  />
                  <AdjustTime
                    name={'removeTimeUnp'}
                    value={formData.commonValues.removeTimeUnp}
                    onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                    labelText={'Remove time:'}
                  />
                </div>
              </fieldset>
            )}
          </div>
          <div style={{ padding: '5px' }}>




            {rates.length === 0 && <p className={styles.warning}>Select Department!</p>}
            {rates.length > 0 &&
              <label style={{ marginTop: '10px' }}>
                Rate:
                <select
                  className={styles.select}
                  value={formData.commonValues.unpackingRate}
                  onChange={(evt) => {
                    resetLastTarget();
                    formChange('commonValues.unpackingRate', 'set', evt.target.value);
                  }}
                  style={{ marginTop: '10px' }}
                  disabled={isCuFtDepartment || disabled}
                >
                  <option value={''} key='none'>None</option>
                  {
                    rates.map((rate, index) => {
                      return (
                        <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                      );
                    })
                  }
                </select>
              </label>
            }
            <fieldset className={styles.groupInput}>
              <label className={styles.checkLabel} style={{ width: "400px", justifyContent: "flex-start", marginTop: '10px' }} >
                <span>Movers to Unpack:</span>
                <select
                  className={styles.commonInput}
                  name={'moversToUnPackSeparate'}
                  value={(formData.commonValues.moversToUnPackSeparate) ? formData.commonValues.moversToUnPackSeparate : ''}
                  onChange={(evt) => {
                    resetLastTarget();
                    formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
                  }
                  }
                >
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                </select>
              </label>
            </fieldset>
            {formData.department && formData.department.truckFee && formData.department.truckFee.calcMethod === "different" &&
              <fieldset className={styles.groupInput} style={{ marginLeft: '-20px', marginTop: '10px' }}>
                <label className={styles.inputContainer} style={{ width: "400px", justifyContent: "flex-start" }}>
                  Truck fee:
                  <input
                    onWheel={(e) => e.target.blur()}
                    type='number'
                    name={'truckFeeSeparate'}
                    value={(formData._id) ? (formData.twoDaysPacking) ? formData.twoDaysPacking.truckFeeSeparate : '' : getTruckFeeMin(formData.department.truckFee.diffTruckReservations)}
                    placeholder={'ex: 40'}
                    onChange={onValueChange}
                  />
                </label>
              </fieldset>
            }


            {formData.commonValues?.shuttle &&
              <div style={{ display: 'flex' }} className={styles.groupInput}>
                <label className={styles.shuttleOnDayTwo} style={{ marginTop: '10px' }}>
                  Shuttle on Unpacking Day
                  <input
                    type='checkbox'
                    name={'shuttleOnUnpacking'}
                    value={(formData.commonValues.shuttleOnUnpacking) ? formData.commonValues.shuttleOnUnpacking : false}
                    checked={(formData.commonValues.shuttleOnUnpacking) ? formData.commonValues.shuttleOnUnpacking : false}
                    onChange={sepCheckboxValueChange}
                    style={{ marginRight: '20px' }}
                  />
                </label>
                {formData.commonValues.shuttleOnUnpacking &&
                  <label className={styles.inputContainer} style={{ marginTop: '10px' }}>
                    Qty:
                    <input
                      type='number'
                      name={'quantityUnpacking'}
                      value={formData.commonValues.quantityUnpacking}
                      placeholder={'ex: 2'}
                      onChange={(evt) => {
                        resetLastTarget();
                        formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
                      }}
                      onWheel={(e) => e.target.blur()}
                    />
                  </label>
                }
              </div>
            }
          </div>
        </div>}
    </section>
  );
};