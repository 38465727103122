// Third party libraries
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
// Components
import { SocketContext, SocketProvider, socket } from './context/socket';
import Authorization from './pages/authorization';
import Main from './pages/main';
import { Departments } from './pages/departments';
import { LongDistances } from './pages/longdistances';
import { OutOfStates } from './pages/outofstates';
import { Calculations } from './pages/calculations';
import { Users } from './pages/users';
import { Companies } from './pages/companies';
import { Settings } from './pages/settings';
import { Actions } from './pages/actions';
import { Templates } from './pages/templates';
// Store function
import { checkAuth, getUserName } from './clientStore/authSlice/auth-slice';
// Util functions
import { PrivateRoute } from './util/private-route';
import { RoleRoute } from './util/role-route';
import { HourlyDepSettings } from './pages/hourlyDepSettings';
import { CuftDepSettings } from './pages/cuftDepSettings';
import { InventorySettings } from './pages/inventorySettings';
import { RoomsAreas } from './pages/roomsAreas';
import { Furniture } from './pages/furniture';


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(checkAuth());
    }
  }, [dispatch]);

  const userName = useSelector(getUserName);

  return (
    <div className="container">
      <SocketProvider>
        <Router>
          <Switch>
            {/**
* ---------- Public routing in the App ----------
*/}
            <Route exact path='/' component={Main} />
            <Route exact path='/login' component={Authorization} />
            {/**
* ---------- Private routing in the App ----------
*/}


            <RoleRoute path='/settings'>
              <Settings userName={userName} />
            </RoleRoute>

            <RoleRoute path='/hourlydepsettings'>
              <HourlyDepSettings userName={userName} />
            </RoleRoute>
            <RoleRoute path='/cuftdepsettings'>
              <CuftDepSettings userName={userName} />
            </RoleRoute>
            <RoleRoute path='/inventorysettings'>
              <InventorySettings userName={userName} />
            </RoleRoute>
            <RoleRoute path='/roomsareas'>
              <RoomsAreas userName={userName} />
            </RoleRoute>
            <RoleRoute path='/furniture'>
              <Furniture userName={userName} />
            </RoleRoute>
            <RoleRoute path='/departments'>
              <Departments userName={userName} />
            </RoleRoute>
            <RoleRoute path='/longdistances'>
              <LongDistances userName={userName} />
            </RoleRoute>
            <RoleRoute path='/outofstates'>
              <OutOfStates userName={userName} />
            </RoleRoute>
            <RoleRoute path='/templates'>

              <Templates userName={userName} />


            </RoleRoute>
            <PrivateRoute path='/calculations'>

              <Calculations userName={userName} />

            </PrivateRoute>
            <RoleRoute path='/users'>
              <Users userName={userName} />
            </RoleRoute>
            <RoleRoute path='/companies'>
              <Companies userName={userName} />
            </RoleRoute>
            <RoleRoute path='/actions'>
              <Actions userName={userName} />
            </RoleRoute>
          </Switch>
        </Router>
      </SocketProvider>
    </div>
  );
}

export default App;
