// Components
import { CalculationLongOneDayParams } from '../calculation-long-one-day-params/Calculation-long-one-day-params';
import { BtnApplyNewRate } from '../btn-apply-new-rate/Btn-apply-new-rate';
import { CalculationTwoDaysPayment } from '../calculation-two-days-payment/Calculation-two-days-payment';

// import { boxesKey } from '../../util/const';
// Styles
import styles from './CalculationLongOneDayResult.module.css';
import { BtnApplyNewTemplate } from '../btn-apply-new-template/Btn-apply-new-template';

export const CalculationLongOneDayResult = (props) => {
  const { calculationData, sameDepartmentSettings, sameLongDirectionSettings, applyNewRates, sameUnpDepartmentSettings, formChange, resetLastTarget, checkboxValueChange, editorState,
    tags,
    setPreviewText, setOptionsPreviewText,
    popUpShown, oldTemplateWasUsed, applyNewTemplate, setShowGenerateEmail, furniture, roomsAreas, setTagsValuesDefault, customTagsValues, showCustomTags } = props;
  const commonValues = { ...calculationData.commonValues };
  const longOneDayPacking = { ...calculationData.longOneDayPacking };
  const department = { ...calculationData.department };
  const unpDepartment = { ...calculationData.unpDepartment };
  const longDistance = { ...calculationData.longDistance };
  const selectedMiscCheckMarks = calculationData.selectedMiscCheckMarks

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Calculation - LONG - 1 day - Packing / No packing</h2>
      {(!sameDepartmentSettings || !sameLongDirectionSettings || !sameUnpDepartmentSettings) && <div className={styles.oldApplyNew}><h2 className={styles.title} style={{ color: "red" }}>Old department or destination settings were used</h2>
        <BtnApplyNewRate
          isActive={true}
          action={applyNewRates}
          name={'Apply new rates'}
        />
      </div>}
      {oldTemplateWasUsed && <div className={styles.oldApplyNew}><h2 className={styles.title} style={{ color: "#0000ff" }}>Old template settings were used</h2>
        <BtnApplyNewTemplate
          isActive={true}
          action={applyNewTemplate}
          name={'Apply new Template'}
        />
      </div>}
      {calculationData &&
        <div className={styles.selectedInfo}>
          {calculationData.calculationNumber && <p className={styles.calculationNumber}>{calculationData.calculationNumber}</p>}
          {calculationData.department && <p className={styles.departmentName}>{calculationData.department.departmentName}</p>}
          {calculationData.longOneDayPacking.firstDayRate && <p className={styles.rateName}>{calculationData.longOneDayPacking.firstDayRate}</p>}
          {calculationData.longOneDayPacking.secondDayRate && <p className={styles.secondRateName}>{calculationData.longOneDayPacking.secondDayRate}</p>}
        </div>
      }
      <div className={styles.selectedInfo}>
        {longOneDayPacking.firstDayDate && <p className={styles.calculationDate} style={{ marginRight: '4px' }}>{new Date(longOneDayPacking.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' })}</p>}
        {longOneDayPacking.secondDayDate && <p className={styles.calculationDate}>{longOneDayPacking.firstDayDate && '& '} {new Date(longOneDayPacking.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' })}</p>}
      </div>

      <CalculationLongOneDayParams commonValues={commonValues} longOneDayPacking={longOneDayPacking} department={department}
        longDistance={longDistance} unpDepartment={unpDepartment} selectedMiscCheckMarks={selectedMiscCheckMarks}
        formChange={formChange}
        resetLastTarget={resetLastTarget}
        checkboxValueChange={checkboxValueChange}
        editorState={editorState}
        calculationData={calculationData}
        tags={tags}
        popUpShown={popUpShown}
        setPreviewText={setPreviewText}
        setOptionsPreviewText={setOptionsPreviewText}
        setShowGenerateEmail={setShowGenerateEmail}
        furniture={furniture}
        roomsAreas={roomsAreas}
        setTagsValuesDefault={setTagsValuesDefault}
        customTagsValues={customTagsValues}
        showCustomTags={showCustomTags}
      />
      {/*       <CalculationTwoDaysPayment calculationData={calculationData} /> */}
    </section>
  );
};
