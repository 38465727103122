// Third party libraries
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch
} from 'react-router-dom';
// Components
import { CalculationForm } from '../Components/calculation-form/Calculation-form';
import { CalculationEdit } from '../Components/calculation-edit/Calculation-edit';
import { CalculationsMain } from '../Components/calculations-main/Calculations-main';
import { Footer } from '../Components/footer/Footer';
import { Header } from '../Components/header/Header';
import { PageHeader } from '../Components/page-header/Page-header';
import { PageMenu } from '../Components/page-menu/Page-menu';
// Functions for work with state
import { getUserRole, checkAuth } from '../clientStore/authSlice/auth-slice';
//Utils functions
import { IconNames } from '../util/const';
import { PrivateRoute } from '../util/private-route';
import { getSectionNames } from '../util/utils';
import { setDepartmentsLoadingStatus } from '../clientStore/departmentsSlice/departments-slice';
import { setRoomsAreasLoadingStatus } from '../clientStore/roomsAreasSlice/roomsAreas-slice';
import { setFurnitureLoadingStatus } from '../clientStore/furnitureSlice/furniture-slice';
import { setTemplatesLoadingStatus } from '../clientStore/templatesSlice/templates-slice';


export const Calculations = ({ userName }) => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const userRole = useSelector(getUserRole);
  const SectionNames = getSectionNames(userRole);

  const settingsHeaderBtn = [
    /*     {
          name: 'Save'
        },
        {
          name: 'Reset form'
        } */
  ];

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(checkAuth());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(setDepartmentsLoadingStatus({ status: 'idle' }));
    dispatch(setFurnitureLoadingStatus({ status: 'idle' }));
    dispatch(setRoomsAreasLoadingStatus({ status: 'idle' }));
    dispatch(setTemplatesLoadingStatus({ status: 'idle' }));
  })

  return (
    <div className='container'>
      <Header
        isLoginBtnShow={false}
      />
      <main className='main'>
        <PageHeader
          titleIcon={IconNames.CALCULATIONS}
          title={'Calculation'}
          menuItems={SectionNames}
        />

        <Switch>
          <Route exact path={path}>
            <CalculationsMain />
          </Route>
          <PrivateRoute path={`${path}/add`}>
            <CalculationForm formType={'new'} />
          </PrivateRoute>
          <PrivateRoute path={`${path}/:calculationId`}>
            <CalculationEdit />
          </PrivateRoute>
        </Switch>
      </main>
      {/*   <Footer /> */}
    </div>
  );
};