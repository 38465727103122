import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchedData } from '../../util/utils';

export const saveFurniture = createAsyncThunk(
  'furniture/save',
  async (newFurnitureData) => await fetchedData('furniture/furniture', 'POST', { ...newFurnitureData })
);

export const updateFurniture = createAsyncThunk(
  'furniture/update',
  async (updateFurniture) => await fetchedData('furniture/update', 'POST', updateFurniture)
);

export const loadFurniture = createAsyncThunk(
  'furniture/getFurniture',
  async (id) => await fetchedData('furniture/getFurniture', 'POST', { id })
);

