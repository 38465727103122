import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchedData } from '../../util/utils';

export const saveRoomsAreas = createAsyncThunk(
  'roomsAreas/save',
  async (newRoomsAreasData) => await fetchedData('roomsAreas/roomsAreas', 'POST', { ...newRoomsAreasData })
);

export const updateRoomsAreas = createAsyncThunk(
  'roomsAreas/update',
  async (updateRoomsAreas) => await fetchedData('roomsAreas/update', 'POST', updateRoomsAreas)
);

export const loadRoomsAreas = createAsyncThunk(
  'roomsAreas/getRoomsAreas',
  async (id) => await fetchedData('roomsAreas/getRoomsAreas', 'POST', { id })
);

