// Third party libraries
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import * as immutable from 'object-path-immutable';
import { nanoid } from 'nanoid';
// Components
import { AddBtn } from '../add-btn/Add-btn';
import { CalculationList } from '../calculation-list/Calculation-list';
import { Loading } from '../loading/Loading';
import { OutDataMessage } from '../outdata-message/OutData-message';
import { SettingsContainer } from '../settings-container/Settings-container';
import { SettingsTitle } from '../settings-title/Settings-title';
import { Search } from '../search/Search';
import { Filter } from '../filter/Filter';
// State functions
import { getCompanyId, getUserRole, getUserName, getUserId } from '../../clientStore/authSlice/auth-slice';
import {
  getProcessCalculationsLoading,
  getCalculations,
} from '../../clientStore/calculationsSlice/calculations-slice';
import { loadCalculations } from '../../clientStore/calculationsSlice/calculations-async-thunk';
import {
  getCalculationLoadingStatus,
  getCalculationSavingStatus,
  setCalculationLoadingStatus,
  setCalculationSavingStatus,
} from '../../clientStore/calculationSlice/calculation-slice';
import { useState } from 'react';
import { getDepartments, getProcessDepartmentsLoading } from '../../clientStore/departmentsSlice/departments-slice';
import {
  getUsers,
  getProcessUsersLoading,
} from '../../clientStore/usersSlice/user-slice';
import { loadDepartments } from '../../clientStore/departmentsSlice/departments-async-thunk';
import { loadUsers } from '../../clientStore/usersSlice/users-async-thunk';
import { Role } from '../../util/const';
import { setActionsLoadingStatus } from '../../clientStore/actionsSlice/actions-slice';
import { setDepartmentLoadingStatus } from '../../clientStore/departmentSlice/department-slice';
import { setLongDistanceLoadingStatus } from '../../clientStore/longdistanceSlice/longdistance-slice';
import { setOutOfStateLoadingStatus } from '../../clientStore/outofstateSlice/outofstate-slice';
import Pagination from '../pagination/Pagination';
import { setUnpDepartmentLoadingStatus } from '../../clientStore/unpDepartmentSlice/unp-department-slice';


export const CalculationsMain = () => {
  const { url } = useRouteMatch();

  const dispatch = useDispatch();
  const companyId = useSelector(getCompanyId);
  const departments = useSelector(getDepartments);
  const users = useSelector(getUsers);
  const currentUserRole = useSelector(getUserRole);
  const currentUserName = useSelector(getUserName);
  const currentUserId = useSelector(getUserId);
  const calculations = useSelector(getCalculations).calculations;
  const loadingProcess = useSelector(getProcessCalculationsLoading);
  const loadingDepartmentsStatus = useSelector(getProcessDepartmentsLoading);
  const loadingUsersStatus = useSelector(getProcessUsersLoading);
  const calculationIsLoading = useSelector(getCalculationLoadingStatus);
  const calculationIsSaving = useSelector(getCalculationSavingStatus);
  const totalPages = useSelector(getCalculations).totalPages;
  const [currentPage, onPageChange] = useState(useSelector(getCalculations).currentPage || 1);
  const [perPage, onPerPageChange] = useState(10);



  const [filters, setFilters] = useState(
    {
      'search': {
        'field': '',
        'searchText': '',
      },
      'sort': {
        'field': 'createdAt',
        'order': -1
      },
      'filter': {
        'field': '',
        'value': '',
      }
    }
  );
  const [isFilterChanged, setFilterChanged] = useState(false);
  const usersOptions = users.map((user) =>
    <option
      value={user._id}
      key={nanoid(users.length)}
    >
      {user.login}
    </option>
  );

  if (!users.some(el => el.login === currentUserName)) {
    usersOptions.push(<option
      value={currentUserId}
      key={nanoid(users.length)}
    >
      {currentUserName}
    </option>)
  }

  const departmentsOptions = departments && departments.length && departments.map(
    (department) =>
      <option
        value={department._id}
        key={nanoid(departments.length)}
      >
        {department.departmentName}
      </option>
  );

  useEffect(() => {
    if (loadingProcess === 'idle' || isFilterChanged) {
      dispatch(loadCalculations({ 'filters': filters, pageNumber: currentPage, pageSize: Number(perPage) }));
      dispatch(setActionsLoadingStatus({ status: 'idle' }));
      dispatch(setDepartmentLoadingStatus({ status: 'idle' }));
      dispatch(setUnpDepartmentLoadingStatus({ status: 'idle' }));
      dispatch(setLongDistanceLoadingStatus({ status: 'idle' }));
      dispatch(setOutOfStateLoadingStatus({ status: 'idle' }));
      try {
        setFilterChanged(false)
      }
      catch (err) {
        console.log(err)
      };
    }
    if (calculationIsLoading !== 'idle') {
      dispatch(setCalculationLoadingStatus({ status: 'idle' }));
    }
    if (calculationIsSaving !== 'idle') {
      dispatch(setCalculationSavingStatus({ status: 'idle' }));
    }
    if (loadingDepartmentsStatus === 'idle') {
      dispatch(loadDepartments());
    }
    if (loadingUsersStatus === 'idle') {
      dispatch(loadUsers({ 'companyId': companyId, 'filters': filters }))
    }
  }, [dispatch, loadingProcess, filters, isFilterChanged, totalPages]);

  const onFilterChange = (path, method, value) => {
    setFilters(immutable[method](filters, path, value));
    try {
      setFilterChanged(true)
    }
    catch (err) {
      console.log(err)
    };
  };


  return (
    <SettingsContainer>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        {(currentUserRole !== 'viewer') ?
          <AddBtn
            name={'calculation'}
            linkUrl={`${url}/add`}
            btnType={'link'}
          />
          : ''}
        {currentUserRole !== Role.MODERATOR && <>
          <Filter searchName="Department"
            selectOptions={departmentsOptions}
            setFilters={onFilterChange}
            filters={filters}
            filterField='departmentId'
          />
          <Filter searchName="User"
            selectOptions={usersOptions}
            setFilters={onFilterChange}
            filters={filters}
            filterField='userId'
          />
        </>}
        <Search setFilters={onFilterChange} searchName="N of calculation" />
      </div>
      <SettingsTitle titleName={'calculations'} />
      {loadingProcess === 'loading' && <Loading />}
      {loadingProcess === 'successed' && calculations && calculations.length > 0 && <CalculationList setFilterChanged={setFilterChanged} calculations={calculations} setFilters={onFilterChange} filters={filters} />}
      {loadingProcess === 'successed' && (!calculations || calculations.length === 0) && <OutDataMessage dataName={'Calculations'} />}
      <Pagination totalPages={totalPages} currentPage={currentPage} perPage={perPage} onPageChange={onPageChange} onPerPageChange={onPerPageChange} setFilterChanged={setFilterChanged} />
    </SettingsContainer>
  );
};