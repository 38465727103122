// Components
import { CalculationTwoDaysParams } from '../calculation-two-days-params/Calculation-two-days-params';
import { BtnApplyNewRate } from '../btn-apply-new-rate/Btn-apply-new-rate';
import { CalculationTwoDaysPayment } from '../calculation-two-days-payment/Calculation-two-days-payment';
// Utils
/* import {
  calculateOfMovers,
  totalCubicFt,
  numberOfTrucks,
  totalTrucks
} from '../../util/calculation-formulas'; */
// import { boxesKey } from '../../util/const';
// Styles
import styles from './CalculationTwoDaysResult.module.css';
import { BtnApplyNewTemplate } from '../btn-apply-new-template/Btn-apply-new-template';

export const CalculationTwoDaysResult = (props) => {
  const { calculationData, sameDepartmentSettings, applyNewRates, formChange, resetLastTarget, checkboxValueChange,
    editorState,
    tags,
    setPreviewText, setOptionsPreviewText,
    popUpShown, oldTemplateWasUsed, applyNewTemplate, setShowGenerateEmail, furniture, roomsAreas, setTagsValuesDefault, customTagsValues } = props;
  const commonValues = { ...calculationData.commonValues };
  const twoDaysPacking = { ...calculationData.twoDaysPacking };
  const department = { ...calculationData.department };
  const unpDepartment = { ...calculationData.unpDepartment };
  const selectedMiscCheckMarks = calculationData.selectedMiscCheckMarks

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Calculation Two days</h2>
      {!sameDepartmentSettings && <div className={styles.oldApplyNew}><h2 className={styles.title} style={{ color: "red" }}>Old department settings were used</h2>
        <BtnApplyNewRate
          isActive={true}
          action={applyNewRates}
          name={'Apply new rates'}
        />
      </div>}
      {calculationData &&
        <div className={styles.selectedInfo}>
          {calculationData.calculationNumber && <p className={styles.calculationNumber}>{calculationData.calculationNumber}</p>}
          {calculationData.department && <p className={styles.departmentName}>{calculationData.department.departmentName}</p>}
          {calculationData.twoDaysPacking.firstDayRate && <p className={styles.rateName}>{calculationData.twoDaysPacking.firstDayRate}</p>}
          {calculationData.twoDaysPacking.secondDayRate && <p className={styles.secondRateName}>{calculationData.twoDaysPacking.secondDayRate}</p>}
        </div>
      }
      {oldTemplateWasUsed && <div className={styles.oldApplyNew}><h2 className={styles.title} style={{ color: "#0000ff" }}>Old template settings were used</h2>
        <BtnApplyNewTemplate
          isActive={true}
          action={applyNewTemplate}
          name={'Apply new Template'}
        />
      </div>}
      <div className={styles.selectedInfo}>
        {twoDaysPacking.firstDayDate && <p className={styles.calculationDate} style={{ marginRight: '4px' }}>{new Date(twoDaysPacking.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' })}</p>}
        {twoDaysPacking.secondDayDate && <p className={styles.calculationDate}>{twoDaysPacking.firstDayDate && '&  '} {new Date(twoDaysPacking.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' })}</p>}
      </div>

      <CalculationTwoDaysParams commonValues={commonValues} twoDaysPacking={twoDaysPacking} department={department} unpDepartment={unpDepartment} selectedMiscCheckMarks={selectedMiscCheckMarks}
        formChange={formChange}
        resetLastTarget={resetLastTarget}
        checkboxValueChange={checkboxValueChange}

        editorState={editorState}
        calculationData={calculationData}
        tags={tags}
        popUpShown={popUpShown}
        setPreviewText={setPreviewText}
        setOptionsPreviewText={setOptionsPreviewText}
        setShowGenerateEmail={setShowGenerateEmail}
        furniture={furniture}
        roomsAreas={roomsAreas}
        setTagsValuesDefault={setTagsValuesDefault}
        customTagsValues={customTagsValues}
      />
      {/*       <CalculationTwoDaysPayment calculationData={calculationData} /> */}
    </section >
  );
};
