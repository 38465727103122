// Third party libraries
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch
} from 'react-router-dom';
// Components
import { Footer } from '../Components/footer/Footer';
import { Header } from '../Components/header/Header';
import { PageHeader } from '../Components/page-header/Page-header';
import { PageMenu } from '../Components/page-menu/Page-menu';
// Functions for work with state
import { getUserRole, checkAuth, getCompanyId } from '../clientStore/authSlice/auth-slice';

//Utils functions
import { IconNames } from '../util/const';
import { PrivateRoute } from '../util/private-route';
import { getSectionNames } from '../util/utils';
import { useLocation } from 'react-router-dom';
import { RoomsAreasForm } from '../Components/roomsAreas-form/RoomsAreas-form';
import { loadRoomsAreas } from '../clientStore/roomsAreasSlice/roomsAreas-async-thunk';
import {
  getRoomsAreas,
  getRoomsAreasLoadingStatus,
} from '../clientStore/roomsAreasSlice/roomsAreas-slice';
import { getCompany, getCompanyLoadingStatus } from '../clientStore/companySlice/company-slice';
import { loadCompany } from '../clientStore/companySlice/company-async-thunk';
import { Loading } from '../Components/loading/Loading';

export const RoomsAreas = ({ userName }) => {
  const dispatch = useDispatch();
  const userRole = useSelector(getUserRole);
  const SectionNames = getSectionNames(userRole);
  const loadingStatus = useSelector(getRoomsAreasLoadingStatus);
  const roomsAreas = JSON.parse(JSON.stringify(useSelector(getRoomsAreas)));
  const companyLoadingStatus = useSelector(getCompanyLoadingStatus);
  let formType = roomsAreas === null ? 'new' : 'edit';
  const companyId = useSelector(getCompanyId);
  const company = useSelector(getCompany);
  const roomsAreasId = company ? company.roomsAreasId : null;

  useEffect(() => {
    if (companyLoadingStatus === 'idle') {
      dispatch(loadCompany(companyId));
    }
  }, [dispatch, companyId, companyLoadingStatus]);


  const settingsHeaderBtn = [
    /*   {
        name: 'Change password'
      } */
  ];

  useEffect(() => {
    if (loadingStatus === 'idle') {
      if (roomsAreasId) {
        dispatch(loadRoomsAreas(roomsAreasId));
      }
    }
  }, [dispatch, roomsAreasId, loadingStatus]);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(checkAuth());
    }
  }, [dispatch]);
  console.log('loadingStatus', loadingStatus)

  return (
    <div className='container'>
      <Header
        isLoginBtnShow={false}
      />
      <main className='main'>
        <PageHeader
          titleIcon={IconNames.USER}
          title={userName}
          btns={settingsHeaderBtn}
          menuItems={SectionNames}
        />
        {(loadingStatus !== 'successed' && roomsAreasId) && <Loading />}
        {(loadingStatus === 'successed' || !roomsAreasId) && <RoomsAreasForm formType={formType} roomsAreasData={roomsAreas} />}
      </main>
      {/* <Footer /> */}
    </div>
  );
};