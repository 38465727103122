import useCollapse from 'react-collapsed';
import styles from './ExtraOptionsValues.module.css';
import { nanoid } from 'nanoid';

export const ExtraOptionsValues = (props) => {
  const {
    charges,
    chargeCheckboxValueChange,
    selectedChargesNames,
    style,
    chargeQuantityChange,
    lastTarget,
    disabled
  } = props;
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  return (
    <div style={style} >
      <span style={{ color: '#4db6ac' }}>EXTRA OPTIONS</span>
      <button {...getToggleProps()} className={styles.btnSubmit}>
        <i className={isExpanded ? styles.up : styles.down}></i>
      </button>
      <section {...getCollapseProps()} >
        <div style={{ marginBottom: '5px' }}>
          {charges.map((charge, index) => {
            return (
              <div key={nanoid(7)}>
                <label className={styles.checkLabel} key={nanoid(7)} >
                  <span>{charge[`chargeName${index}`]}</span>
                  <input
                    className={styles.depcheckbox}
                    type='checkbox'
                    name={charge[`uId${index}`]}
                    value={(selectedChargesNames) ? (selectedChargesNames.find(item => item.key === charge[`uId${index}`])) ? true : false : false}
                    onChange={chargeCheckboxValueChange}
                    checked={(selectedChargesNames) ? (selectedChargesNames.find(item => item.key === charge[`uId${index}`])) ? true : false : false}
                    disabled={disabled}
                  />
                </label>

                {(charge[`isCountable${index}`] && selectedChargesNames.find(item => item.key === charge[`uId${index}`])) ?
                  <label className={styles.inputContainer}>
                    Qty:
                    <input
                      onWheel={(e) => e.target.blur()}
                      style={{ width: "45px" }}
                      type='number'
                      name={charge[`uId${index}`]}
                      value={selectedChargesNames.find(item => item.key === charge[`uId${index}`]).quantity}
                      placeholder={'ex: 2'}
                      onChange={chargeQuantityChange}
                      autoFocus={(lastTarget) ? lastTarget === charge[`uId${index}`] : false}
                      disabled={disabled}
                    />
                  </label>
                  : ''}

              </div>
            )
          })}
        </div>
      </section>
    </div>
  );
}

