import React, { useState, useContext, useCallback, useEffect } from 'react';
import { SocketContext } from '../../context/socket';
import useCollapse from 'react-collapsed'
// Styles
import styles from "./CalculationLongOneDayParams.module.css";
//Components
import { CalculationSeparateDayLongView } from '../calculation-separate-day-long-view/Calculation-separate-day-long-view';
import { generateUnpackingLongText, getChargesResText, getCheckMarkLabelById, getTagsValues, getTotalTimeRange, replaceOptionsTagsByValues, replaceTagsByValues } from '../../util/utils';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import CopyIcon from '../results-copy-icon/Results-copy-icon';
import { ExtraOptionsResults } from '../extra-options-results/Extra-options-results';
import { setTotalFragileBoxes } from '../../clientStore/calculatedParamsSlice/calculated-params-slice';
import { useDispatch } from 'react-redux';

export const CalculationLongOneDayParams = ({
  commonValues,
  department,
  longOneDayPacking,
  longDistance,
  unpDepartment,
  selectedMiscCheckMarks,
  checkboxValueChange,
  editorState,
  tags,
  setPreviewText, setOptionsPreviewText,
  popUpShown,
  calculationData,
  setShowGenerateEmail,furniture, roomsAreas, setTagsValuesDefault, customTagsValues, showCustomTags
}) => {
  const dispatch = useDispatch();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  const [Total_cubes, setTotal_cubes] = useState(0);
  const [Total_small_boxes, setTotal_small_boxes] = useState(0);
  const [Total_medium_boxes, setTotal_medium_boxes] = useState(0);
  const [Total_fragile_boxes, setTotal_fragile_boxes] = useState(0);
  const [Total_box_cubes, setTotal_box_cubes] = useState(0);
  const [Truckloads, setTruckloads] = useState(0);
  const [Stop_CN_Truckload, setStop_CN_Truckload] = useState(0);
  const [Stop_1_Truckload, setStop_1_Truckload] = useState(0);
  const [BIGGEST_or_OK, setBIGGEST_or_OK] = useState(0);
  const [Heavy_fee, setHeavy_fee] = useState(0);
  const [Packing_kit_Show, setPacking_kit_Show] = useState(0);
  const [Packing_kit_total_amount_with_taxes, setPacking_kit_total_amount_with_taxes] = useState(0);
  const [Total_Hours_LONGOne_Day_Loading, setTotal_Hours_LONGOne_Day_Loading] = useState(0);
  const [Drivetime_LONGOne_Day, setDrivetime_LONGOne_Day] = useState(0);
  const [Movers_LONGOne_Day_Loading, setMovers_LONGOne_Day_Loading] = useState(0);
  const [Trucks_LONGOne_Day, setTrucks_LONGOne_Day] = useState(0);
  const [Total_Hours_LONGOne_Day_Loading_Lower_number, setTotal_Hours_LONGOne_Day_Loading_Lower_number] = useState(0);
  const [Total_Hours_LONGOne_Day_Unloading, setTotal_Hours_LONGOne_Day_Unloading] = useState(0);
  const [Movers_LONGOne_Day_Unloading, setMovers_LONGOne_Day_Unloading] = useState(0);
  const [Total_Hours_LONGOne_Day_Unloading_Lower_number, setTotal_Hours_LONGOne_Day_Unloading_Lower_number] = useState(0);
  const [Total_Card_LONGOne_Day, setTotal_Card_LONGOne_Day] = useState(0);
  const [Total_Cash_LONGOne_Day, setTotal_Cash_LONGOne_Day] = useState(0);
  const [Total_Card_LONGOne_Day_R, setTotal_Card_LONGOne_Day_R] = useState(0);
  const [Total_Cash_LONGOne_Day_R, setTotal_Cash_LONGOne_Day_R] = useState(0);
  const [Trucks_Unpacking_Separate_Day, setTrucks_Unpacking_Separate_Day] = useState(0)
  const [Movers_Unpacking_Separate_Day, setMovers_Unpacking_Separate_Day] = useState(0)
  const [Unpacking_Separate_Day_Lower_hours, setUnpacking_Separate_Day_Lower_hours] = useState(0)
  const [Unpacking_Separate_Day_Higher_hours, setUnpacking_Separate_Day_Higher_hours] = useState(0)
  const [Rate_cash_Unpacking_Separate_Day, setRate_cash_Unpacking_Separate_Day] = useState(0)
  const [Rate_card_Unpacking_Separate_Day, setRate_card_Unpacking_Separate_Day] = useState(0)
  const [Total_Card_Unpacking_Separate_Day_Long_and_OOS, setTotal_Card_Unpacking_Separate_Day_Long_and_OOS] = useState(0)
  const [Truck_fee_Unpacking_Separate_Day, setTruck_fee_Unpacking_Separate_Day] = useState(0)
  const [Combined_mileage, setCombined_mileage] = useState(0)
  const [Time_to_pack, setTime_to_pack] = useState(0);
  const [Time_to_unpack, setTime_to_unpack] = useState(0);
  const [Total_cubes_Within_Building, setTotal_cubes_Within_Building] = useState(0)
  const socket = useContext(SocketContext);

  useEffect(() => {
    socket.emit('getFormulasValues', { calculationType: 'longOneDayPacking', commonValues: commonValues, longOneDayPacking: longOneDayPacking, department: department, unpDepartment: unpDepartment, longDistance: longDistance })
    socket.on('Total_cubes', (data) => setTotal_cubes(data));
    socket.on('Total_cubes', (data) => setTotal_cubes(data));
    socket.on('Total_small_boxes', (data) => setTotal_small_boxes(data));
    socket.on('Total_medium_boxes', (data) => setTotal_medium_boxes(data));
    socket.on('Total_fragile_boxes', (data) => { setTotal_fragile_boxes(data); dispatch(setTotalFragileBoxes(data)); });
    socket.on('Total_box_cubes', (data) => setTotal_box_cubes(data));
    socket.on('Truckloads', (data) => setTruckloads(data));
    socket.on('Stop_CN_Truckload', (data) => setStop_CN_Truckload(data));
    socket.on('Stop_1_Truckload', (data) => setStop_1_Truckload(data));
    socket.on('BIGGEST_or_OK', (data) => setBIGGEST_or_OK(data));
    socket.on('Heavy_fee', (data) => setHeavy_fee(data));
    socket.on('Packing_kit_Show', (data) => setPacking_kit_Show(data));
    socket.on('Packing_kit_total_amount_with_taxes', (data) => setPacking_kit_total_amount_with_taxes(data));
    socket.on('Total_Hours_LONGOne_Day_Loading', (data) => setTotal_Hours_LONGOne_Day_Loading(data));
    socket.on('Drivetime_LONGOne_Day', (data) => setDrivetime_LONGOne_Day(data));
    socket.on('Movers_LONGOne_Day_Loading', (data) => setMovers_LONGOne_Day_Loading(data));
    socket.on('Trucks_LONGOne_Day', (data) => setTrucks_LONGOne_Day(data));
    socket.on('Total_Hours_LONGOne_Day_Loading_Lower_number', (data) => setTotal_Hours_LONGOne_Day_Loading_Lower_number(data));
    socket.on('Total_Hours_LONGOne_Day_Unloading', (data) => setTotal_Hours_LONGOne_Day_Unloading(data));
    socket.on('Movers_LONGOne_Day_Unloading', (data) => setMovers_LONGOne_Day_Unloading(data));
    socket.on('Total_Hours_LONGOne_Day_Unloading_Lower_number', (data) => setTotal_Hours_LONGOne_Day_Unloading_Lower_number(data));
    socket.on('Total_Card_LONGOne_Day', (data) => setTotal_Card_LONGOne_Day(data));
    socket.on('Total_Cash_LONGOne_Day', (data) => setTotal_Cash_LONGOne_Day(data));
    socket.on('Total_Card_LONGOne_Day_R', (data) => setTotal_Card_LONGOne_Day_R(data));
    socket.on('Total_Cash_LONGOne_Day_R', (data) => setTotal_Cash_LONGOne_Day_R(data));
    socket.on('Trucks_Unpacking_Separate_Day', (data) => setTrucks_Unpacking_Separate_Day(data))
    socket.on('Movers_Unpacking_Separate_Day', (data) => setMovers_Unpacking_Separate_Day(data))
    socket.on('Unpacking_Separate_Day_Lower_hours', (data) => setUnpacking_Separate_Day_Lower_hours(data))
    socket.on('Unpacking_Separate_Day_Higher_hours', (data) => setUnpacking_Separate_Day_Higher_hours(data))
    socket.on('Rate_cash_Unpacking_Separate_Day', (data) => setRate_cash_Unpacking_Separate_Day(data))
    socket.on('Rate_card_Unpacking_Separate_Day', (data) => setRate_card_Unpacking_Separate_Day(data))
    socket.on('Total_Card_Unpacking_Separate_Day_Long_and_OOS', (data) => setTotal_Card_Unpacking_Separate_Day_Long_and_OOS(data))
    socket.on('Truck_fee_Unpacking_Separate_Day', (data) => setTruck_fee_Unpacking_Separate_Day(data))
    socket.on('Combined_mileage', (data) => { setCombined_mileage(data) });
    socket.on('Time_to_pack', (data) => setTime_to_pack(data));
    socket.on('Time_to_unpack', (data) => setTime_to_unpack(data));
    socket.on('Total_cubes_Within_Building', (data) => setTotal_cubes_Within_Building(data));
  }, [commonValues, longOneDayPacking, department, longDistance])

  useEffect(() => {
    if (editorState) {
      let templateText = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      let tagValues = getTagsValues({
        calculation: calculationData, tags,
        calculatedParams: {
          Total_cubes: Total_cubes,
          Total_small_boxes: Total_small_boxes,
          Total_medium_boxes: Total_medium_boxes,
          Total_fragile_boxes: Total_fragile_boxes,
          Total_box_cubes: Total_box_cubes,
          Truckloads: Truckloads,
          Stop_CN_Truckload: Stop_CN_Truckload,
          Stop_1_Truckload: Stop_1_Truckload,
          BIGGEST_or_OK: BIGGEST_or_OK,
          Heavy_fee: Heavy_fee,
          Packing_kit_Show: Packing_kit_Show,
          Packing_kit_total_amount_with_taxes: Packing_kit_total_amount_with_taxes,
          Total_Hours_LONGOne_Day_Loading: Total_Hours_LONGOne_Day_Loading,
          Drivetime_LONGOne_Day: Drivetime_LONGOne_Day,
          Movers_LONGOne_Day_Loading: Movers_LONGOne_Day_Loading,
          Trucks_LONGOne_Day: Trucks_LONGOne_Day,
          Total_Hours_LONGOne_Day_Loading_Lower_number: Total_Hours_LONGOne_Day_Loading_Lower_number,
          Total_Hours_LONGOne_Day_Unloading: Total_Hours_LONGOne_Day_Unloading,
          Movers_LONGOne_Day_Unloading: Movers_LONGOne_Day_Unloading,
          Total_Hours_LONGOne_Day_Unloading_Lower_number: Total_Hours_LONGOne_Day_Unloading_Lower_number,
          Total_Card_LONGOne_Day: Total_Card_LONGOne_Day,
          Total_Cash_LONGOne_Day: Total_Cash_LONGOne_Day,
          Total_Card_LONGOne_Day_R: Total_Card_LONGOne_Day_R,
          Total_Cash_LONGOne_Day_R: Total_Cash_LONGOne_Day_R,
          Trucks_Unpacking_Separate_Day: Trucks_Unpacking_Separate_Day,
          Movers_Unpacking_Separate_Day: Movers_Unpacking_Separate_Day,
          Unpacking_Separate_Day_Lower_hours: Unpacking_Separate_Day_Lower_hours,
          Unpacking_Separate_Day_Higher_hours: Unpacking_Separate_Day_Higher_hours,
          Rate_cash_Unpacking_Separate_Day: Rate_cash_Unpacking_Separate_Day,
          Rate_card_Unpacking_Separate_Day: Rate_card_Unpacking_Separate_Day,
          Total_Card_Unpacking_Separate_Day_Long_and_OOS: Total_Card_Unpacking_Separate_Day_Long_and_OOS,
          Truck_fee_Unpacking_Separate_Day: Truck_fee_Unpacking_Separate_Day,
          Combined_mileage: Combined_mileage,
        },furniture, roomsAreas
      })
      const tagValuesCopy = JSON.parse(JSON.stringify(tagValues));
      setTagsValuesDefault(prevState => ({
        ...prevState,
        [calculationData.calculationType]: tagValuesCopy
      }));
      if (customTagsValues && Object.keys(customTagsValues).length > 0) {
        for (const key in customTagsValues) {
          tagValues[`|{${key}}`] = customTagsValues[key];
        }
      }
      setPreviewText(replaceTagsByValues(templateText, tagValues))
      setOptionsPreviewText(replaceOptionsTagsByValues(templateText, tagValues))
    }
  }, [popUpShown, tags, /* calculationData,  */showCustomTags]);

  const handleResCopy = (evt) => {
    evt.preventDefault();

    let chargesText = getChargesResText(department, longOneDayPacking.selectedChargesNamesDay1, Trucks_LONGOne_Day);

    let chargesTextDay2 = getChargesResText(department, longOneDayPacking.selectedChargesNamesDay2, Trucks_LONGOne_Day);


    let proceduresText = '';

    commonValues.procedures.forEach((procedure, pkIndex) => {
      if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
        proceduresText += '• ' + procedure.procedureType + ' x ' + procedure.numberOfProcedures + ':';
        if (Object.keys(department).length !== 0) {
          department.packing.cratings.forEach((crating, index) => {
            if (procedure.procedureType === crating[`crateName${index}`]) {
              proceduresText += ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
            }
          });
        }
        proceduresText += '\n';
      }
    });

    let unpackingText = generateUnpackingLongText(commonValues, longOneDayPacking, unpDepartment, Trucks_Unpacking_Separate_Day, Movers_Unpacking_Separate_Day, Unpacking_Separate_Day_Lower_hours, Unpacking_Separate_Day_Higher_hours, Rate_cash_Unpacking_Separate_Day, Rate_card_Unpacking_Separate_Day, Total_Card_Unpacking_Separate_Day_Long_and_OOS, Truck_fee_Unpacking_Separate_Day)


    const invoiceText = `Total Cubic ft: ${parseFloat(Total_cubes).toFixed(2)} cu ft.
${(Total_cubes_Within_Building > 0) ? `Cubic ft within building: ${parseFloat(Total_cubes_Within_Building).toFixed(2)} cu ft.
Cubic ft to move: ${parseFloat(Total_cubes - Total_cubes_Within_Building).toFixed(2)} cu ft.` : ''}
Truckloads: ${Truckloads}
${Truckloads > 1 && (Math.floor(Number(Truckloads))) - Number(Truckloads) < 0 ?
        Math.floor(Truckloads) + ' truck(s) + ' + Math.floor(Number((Number(Truckloads) - Math.floor(Truckloads)).toFixed(2) * 100)) + "%" : ''}
Total miles: ${parseFloat(Combined_mileage).toFixed(1)}
${(commonValues.extraStops.filter((extraStop, index) =>
          extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
        ).length > 0) ? `There will be ${commonValues.extraStops.filter((extraStop, index) =>
          extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
        ).length} additional stops` : ''}
DAY 1 - LOADING
Movers: ${Movers_LONGOne_Day_Loading}
Trucks: ${Trucks_LONGOne_Day}
${(commonValues.shuttle && longOneDayPacking.shuttleOnDay1) ? ("+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${longOneDayPacking.quantityDay1})` : '')) : ''}
${(BIGGEST_or_OK !== 'OK') ? BIGGEST_or_OK : ''}
Total Time Range: ${getTotalTimeRange((department) ? department.minimumHours : 0, Total_Hours_LONGOne_Day_Loading_Lower_number, Total_Hours_LONGOne_Day_Loading)}
${((Object.keys(department).length !== 0) && department.extraOptions.charges.length > 0 && longOneDayPacking.selectedChargesNamesDay1.length > 0) ? `Extra options:` : ''}
${chargesText}
${(Heavy_fee > 0) ?
        `Extra Heavy Items Charge: $${Heavy_fee}`
        : ''}
${commonValues.liftGate ? ' | LIFTGATE' : ''}
${commonValues.hardFloorCovers ? ' | HARDWOOD FLOOR COVERS' : ''}
${commonValues.filmFloorCovers ? ' | CARPET FLOOR COVERS' : ''}
${selectedMiscCheckMarks.map(checkMark => ` | ${getCheckMarkLabelById(checkMark, department.miscCheckMarks.miscCheckMarks).toUpperCase()}`).join('')}
 ${(commonValues.packing !== "No") ?
        (commonValues.packingKitOverride > 0) ? `Packing Kit: ${'$' + Packing_kit_Show} ${commonValues.packing} ${(department.packing && department.packing.packingKits.salesTax) ? `[${Packing_kit_total_amount_with_taxes} with tax]` : ""}`
          : `Packing Kit: ${'$' + Packing_kit_Show} ${(commonValues.packing === "Yes") ? "Full" : commonValues.packing} ${(department.packing && department.packing.packingKits.salesTax) ? `[${Packing_kit_total_amount_with_taxes} with tax]` : ""}`
        : ""}
${(commonValues?.unpacking === 'Same Day') ? `UNPACKING ${commonValues.partialUnPacking && "PARTIAL"}` : ''}
${(commonValues.packing !== "No") ? `Packing Supplies On The Go:
• Small box: $${(Object.keys(department).length !== 0) ? department.packing.supplies.smallBox : ''}
• Medium box: $${(Object.keys(department).length !== 0) ? department.packing.supplies.mediumBox : ''}
• Large box: $${(Object.keys(department).length !== 0) ? department.packing.supplies.largeBox : ''}
• Roll of packing paper: $${(Object.keys(department).length !== 0) ? department.packing.supplies.packingPapper : ''}
• Roll of bubble wrap: $${(Object.keys(department).length !== 0) ? department.packing.supplies.bubbleWrap : ''}
`: ''}
DRIVING
${(longDistance.directionType === 'expences') ? `Drive time: ${Drivetime_LONGOne_Day} hours` : `Arrival time (days): ${longDistance.arrivalTimeDaysRD}`}
Day 2 - Unloading
Movers: ${Movers_LONGOne_Day_Unloading}
Trucks: ${Trucks_LONGOne_Day}${(commonValues.shuttle && longOneDayPacking.shuttleOnDay2) ? ("+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${longOneDayPacking.quantityDay2})` : '')) : ''}
${((Object.keys(department).length !== 0) && department.extraOptions.charges.length > 0 && longOneDayPacking.selectedChargesNamesDay2.length > 0) ? `
Extra options:` : ''}
${chargesTextDay2}
Total time Range: ${getTotalTimeRange((department) ? department.minimumHours : 0, Total_Hours_LONGOne_Day_Unloading_Lower_number, Total_Hours_LONGOne_Day_Unloading)}
${(longOneDayPacking.additionalAmount) ? `Additional amount:${longOneDayPacking.additionalAmount}` : ''}
FLAT RATE: $${(Truckloads > 1 && longDistance.roundToFull && longDistance.directionType === 'expences') ? `-[ROUNDED]-` : ''}${(longDistance.calcMethod !== 'deduct') ?
        (Truckloads > 1 && longDistance.roundToFull && longDistance.directionType === 'expences') ?
          Total_Card_LONGOne_Day_R
          :
          Total_Card_LONGOne_Day
        :
        (Truckloads > 1 && longDistance.roundToFull && longDistance.directionType === 'expences') ?
          Total_Cash_LONGOne_Day_R
          + ' cash / $' + Total_Card_LONGOne_Day_R + " card"
          :
          (Total_Cash_LONGOne_Day
            + ' cash / $').toString() +
          ((Truckloads > 1 && longDistance.roundToFull && longDistance.directionType === 'expences')
            ?
            Total_Card_LONGOne_Day_R
            :
            Total_Card_LONGOne_Day + " card").toString()
      }
${(longDistance.calcMethod !== 'deduct') ? `FLAT RATE with ${longDistance.cashPaymentDiscount}% cash discount: $${(Truckloads > 1 && longDistance.roundToFull && longDistance.directionType === 'expences') ?
        Total_Cash_LONGOne_Day_R
        :
        Total_Cash_LONGOne_Day
        }`
        : ""}
${unpackingText}
`;




    // Create a textarea element to hold the text
    const textArea = document.createElement("textarea");
    textArea.value = invoiceText.replace(/\n{2,}/g, '\n\n') + '\n';;

    // Append the textarea to the document
    document.body.appendChild(textArea);

    // Select and copy the text
    textArea.select();
    document.execCommand("copy");

    // Remove the textarea from the document
    document.body.removeChild(textArea);

  }

  if (typeof setShowGenerateEmail === 'function') {
    if (department && Object.keys(department).length > 0 && longOneDayPacking.firstDayRate && Trucks_LONGOne_Day > 0
      && longDistance && Object.keys(longDistance).length > 0 && Movers_LONGOne_Day_Unloading > 0 && Movers_LONGOne_Day_Loading > 0) {
      setShowGenerateEmail(true)

    } else {

      setShowGenerateEmail(false)

    }
  }

  return (
    <ul className={styles.paramsSection}>
      <button {...getToggleProps()} className={styles.btnSubmit}>
        <i className={isExpanded ? styles.up : styles.down}></i>
      </button>
      <section {...getCollapseProps()}>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Small boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>{Total_small_boxes}</p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Medium boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>{Total_medium_boxes}</p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Fragile boxes:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {Total_fragile_boxes}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Boxes cubic ft:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Total_box_cubes).toFixed(2) + " cu ft."}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Time to pack for 1 mover:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Time_to_pack).toFixed(2) + " h"}
          </p>
        </li>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Time to unpack for 1 mover:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Time_to_unpack).toFixed(2) + " h"}
          </p>
        </li>
        <li className={styles.paramsItem} >
          <p className={styles.paramsName}>Truckloads from Stop 1:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Stop_1_Truckload).toFixed(3)}
          </p>
        </li>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>
            Truckloads from Additional stops:
          </p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Stop_CN_Truckload).toFixed(3)}
          </p>
        </li>
        <h4>DAY - 1 (Packing & Loading)</h4>
        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Loading hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Total_Hours_LONGOne_Day_Loading).toFixed(3)}{' h'}
          </p>
        </li>



        {(longDistance.directionType === 'expences') ?
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Drive Time:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Drivetime_LONGOne_Day}{' h'}
            </p>
          </li>
          : ""}

        <h4>DAY - 2 (Unloading)</h4>

        <li className={styles.paramsItem}>
          <p className={styles.paramsName}>Unloading Hours:</p>
          <span className={styles.paramsDecor}></span>
          <p className={styles.paramsValue}>
            {parseFloat(Total_Hours_LONGOne_Day_Unloading).toFixed(3)}{' h'}
          </p>
        </li>

      </section>
      {(department && Object.keys(department).length > 0 && longOneDayPacking.firstDayRate && Trucks_LONGOne_Day > 0
        && longDistance && Object.keys(longDistance).length > 0 && Movers_LONGOne_Day_Unloading > 0 && Movers_LONGOne_Day_Loading > 0) ?
        <div>
          <h2 className={styles.titleCenter}><div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Results  <CopyIcon onClick={handleResCopy} style={{ marginLeft: '5px' }} /></div>{(Total_box_cubes > 0 && commonValues.packing === 'No') ?
            <>
              <span style={{ color: 'red' }}> - No Packing
              </span>
              <label className={styles.commonLabeling} style={{ marginLeft: '5px' }}>
                Confirm
                <input
                  className={styles.mycheckbox}
                  type='checkbox'
                  name={'isNoPackingConfirmed'}
                  checked={commonValues.isNoPackingConfirmed}
                  value={commonValues.isNoPackingConfirmed}
                  onChange={checkboxValueChange}
                />
              </label>
            </> : ''}</h2>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Cubic ft:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {parseFloat(Total_cubes).toFixed(2)} cu ft.
            </p>
          </li>
          {Total_cubes_Within_Building > 0 && <>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Cubic ft within building:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Total_cubes_Within_Building).toFixed(2)} cu ft.
              </p>
            </li>
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Cubic ft to move:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {parseFloat(Total_cubes - Total_cubes_Within_Building).toFixed(2)} cu ft.
              </p>
            </li>
          </>}
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Truckloads:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>{Truckloads}</p>
            {/* make 1 variable for Truckloads or Truckloads_to_move */}
          </li>
          {/*  (Cubic ft within building>0)?Truckloads_to_move:Truckloads) */}
          {
            (Truckloads > 1 && (Math.floor(Number(Truckloads))) - Number(Truckloads) < 0) ?
              <li className={styles.paramsItem}>
                {Math.floor(Truckloads) + ' truck(s) + ' + Math.floor(Number((Number(Truckloads) - Math.floor(Truckloads)).toFixed(2) * 100)) + "%"}
              </li>
              : ''
          }
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total miles:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>{parseFloat(Combined_mileage).toFixed(1)}</p>
          </li>
          {
            (commonValues.extraStops.filter((extraStop, index) =>
              extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
            ).length > 0) ?
              <li className={styles.paramsItem}>
                There will be {commonValues.extraStops.filter((extraStop, index) =>
                  extraStop[`baseLoadingHours${index}`] !== 0 && Number(extraStop[`baseUnLoadingHours${index}`]) !== 0
                ).length} additional stops
              </li>
              : ''
          }
          <h2 className={styles.titleCenter}>Day 1 - Loading</h2>
          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Movers:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Movers_LONGOne_Day_Loading}
            </p>
          </li>

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Trucks:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Trucks_LONGOne_Day}
              {(commonValues.shuttle && longOneDayPacking.shuttleOnDay1) && ("+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${longOneDayPacking.quantityDay1})` : ''))}</p>
          </li>
          {(BIGGEST_or_OK !== 'OK') ?
            <li className={styles.paramsItem}>
              {BIGGEST_or_OK}
            </li>
            : ""
          }

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total Time Range:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {getTotalTimeRange((department) ? department.minimumHours : 0, Total_Hours_LONGOne_Day_Loading_Lower_number, Total_Hours_LONGOne_Day_Loading)}
            </p>
          </li>

          <ExtraOptionsResults
            department={department}
            selectedChargesNames={longOneDayPacking.selectedChargesNamesDay1}
            trucksCount={Trucks_LONGOne_Day}
          />

          {(Heavy_fee > 0) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Extra Heavy Items Charge:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>{'$' + Heavy_fee}</p>
            </li>
            : ""}

          {(commonValues.procedures.length > 0 && commonValues.procedures.some(procedure => procedure.procedureType !== 'None' && procedure.procedureType !== '')) ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Procedures:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>

              </p>
              <ul>
                {
                  commonValues.procedures.map((procedure) => {
                    if (procedure.procedureType !== '' && procedure.procedureType !== 'None') {
                      return <li>{procedure.procedureType + " x " + procedure.numberOfProcedures}:{
                        (Object.keys(department).length !== 0) && department.packing.cratings.map((crating, index) => {
                          if (procedure.procedureType === crating[`crateName${index}`]) {
                            return ' $' + (Number(procedure.numberOfProcedures) * Number(crating[`crateCost${index}`]));
                          }
                          else {
                            return ''
                          }
                        })
                      }</li>
                    }
                    else { return '' }
                  })
                }
              </ul>
            </li>
            :
            ''}

          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <span style={{ whiteSpace: 'nowrap' }}>{commonValues.liftGate && ' | LIFTGATE'}</span>
            <span style={{ whiteSpace: 'nowrap' }}>{commonValues.hardFloorCovers && " | HARDWOOD FLOOR COVERS"}</span>
            <span style={{ whiteSpace: 'nowrap' }}>{commonValues.filmFloorCovers && " | CARPET FLOOR COVERS "}</span>
            {selectedMiscCheckMarks.map(checkMark => <span style={{ textTransform: 'uppercase', whiteSpace: 'nowrap' }}>
              {' '} | {getCheckMarkLabelById(checkMark, department.miscCheckMarks.miscCheckMarks)}
            </span>)}
          </div>

          {(commonValues.packing !== "No") ?
            (commonValues.packingKitOverride > 0) ?
              <li className={styles.paramsItem} style={{ color: "red" }}>
                <p className={styles.paramsName}>Packing Kit:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  {'$' + Packing_kit_Show} {commonValues.packing} {(department.packing && department.packing.packingKits.salesTax) ? <>[${Packing_kit_total_amount_with_taxes} with tax] </> : ""}
                </p>
              </li>
              :
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>Packing Kit:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>
                  {'$' + Packing_kit_Show} {(commonValues.packing === "Yes") ? "Full" : commonValues.packing} {(department.packing && department.packing.packingKits.salesTax) ? <>[${Packing_kit_total_amount_with_taxes} with tax]</> : ""}
                </p>
              </li>
            : ""}
          {(commonValues?.unpacking === 'Same Day') &&
            <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
              <span style={{ whiteSpace: 'nowrap' }}>UNPACKING {commonValues.partialUnPacking && "PARTIAL"}</span>
            </div>
          }
          {(commonValues.packing !== "No") ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Packing Supplies On The Go:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
              </p>
              <ul>
                <li>
                  Small box: ${(Object.keys(department).length !== 0) && department.packing.supplies.smallBox}
                </li>
                <li>
                  Medium box: ${(Object.keys(department).length !== 0) && department.packing.supplies.mediumBox}
                </li>
                <li>
                  Large box: ${(Object.keys(department).length !== 0) && department.packing.supplies.largeBox}
                </li>
                <li>
                  Roll of packing paper: ${(Object.keys(department).length !== 0) && department.packing.supplies.packingPapper}
                </li>
                <li>
                  Roll of bubble wrap: ${(Object.keys(department).length !== 0) && department.packing.supplies.bubbleWrap}
                </li>
              </ul>
            </li>
            : ""}

          <h2 className={styles.titleCenter}>Driving</h2>
          {(longDistance.directionType === 'expences')
            ?
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Drive time:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {Drivetime_LONGOne_Day}{' hours'}
              </p>
            </li>
            :
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Arival time (days):</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                {longDistance.arrivalTimeDaysRD}
              </p>
            </li>
          }

          <h2 className={styles.titleCenter}>Day 2 - Unloading</h2>

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Movers:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Movers_LONGOne_Day_Unloading}
            </p>
          </li>

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Trucks:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {Trucks_LONGOne_Day}
              {(commonValues.shuttle && longOneDayPacking.shuttleOnDay2) && ("+ SHUTTLE " + ((department.extraOptions.shuttle.isCountable) ? `(x${longOneDayPacking.quantityDay2})` : ''))}</p>
          </li>

          <ExtraOptionsResults
            department={department}
            selectedChargesNames={longOneDayPacking.selectedChargesNamesDay2}
            trucksCount={Trucks_LONGOne_Day}
          />

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>Total time Range:</p>
            <span className={styles.paramsDecor}></span>
            <p className={styles.paramsValue}>
              {getTotalTimeRange((department) ? department.minimumHours : 0, Total_Hours_LONGOne_Day_Unloading_Lower_number, Total_Hours_LONGOne_Day_Unloading)}
            </p>
          </li>

          {(longOneDayPacking.additionalAmount) ? (
            <li className={styles.paramsItem}>
              <p className={styles.paramsName}>Additional amount:</p>
              <span className={styles.paramsDecor}></span>
              <p className={styles.paramsValue}>
                ${longOneDayPacking.additionalAmount}
              </p>
            </li>) : ''
          }

          <li className={styles.paramsItem}>
            <p className={styles.paramsName}>FLAT RATE:</p>
            <div className={styles.roundedOrNot}>
              {(Truckloads > 1 && longDistance.roundToFull && longDistance.directionType === 'expences') && <span>[ROUNDED]</span>}
              <span className={styles.paramsDecor}></span>
            </div>
            <p className={styles.paramsValue}>$
              {(longDistance.calcMethod !== 'deduct') ?
                (Truckloads > 1 && longDistance.roundToFull && longDistance.directionType === 'expences') ?
                  Total_Card_LONGOne_Day_R
                  :
                  Total_Card_LONGOne_Day
                :
                (Truckloads > 1 && longDistance.roundToFull && longDistance.directionType === 'expences') ?
                  Total_Cash_LONGOne_Day_R
                  + ' cash / $' + Total_Card_LONGOne_Day_R + " card"
                  :
                  (Total_Cash_LONGOne_Day
                    + ' cash / $').toString() +
                  ((Truckloads > 1 && longDistance.roundToFull && longDistance.directionType === 'expences')
                    ?
                    Total_Card_LONGOne_Day_R
                    :
                    Total_Card_LONGOne_Day + " card").toString()
              }
            </p>
          </li>
          {(longDistance.calcMethod !== 'deduct') ?
            <div style={{ paddingLeft: '30px' }}>
              <li className={styles.paramsItem}>
                <p className={styles.paramsName}>FLAT RATE with {longDistance.cashPaymentDiscount}% cash discount:</p>
                <span className={styles.paramsDecor}></span>
                <p className={styles.paramsValue}>$
                  {
                    (Truckloads > 1 && longDistance.roundToFull && longDistance.directionType === 'expences') ?
                      Total_Cash_LONGOne_Day_R
                      :
                      Total_Cash_LONGOne_Day
                  }
                </p>
              </li>
            </div>
            : ""}

          <CalculationSeparateDayLongView commonValues={commonValues} anyCalculationType={longOneDayPacking} unpDepartment={unpDepartment}
            Trucks_Unpacking_Separate_Day={Trucks_Unpacking_Separate_Day}
            Movers_Unpacking_Separate_Day={Movers_Unpacking_Separate_Day}
            Unpacking_Separate_Day_Lower_hours={Unpacking_Separate_Day_Lower_hours}
            Unpacking_Separate_Day_Higher_hours={Unpacking_Separate_Day_Higher_hours}
            Rate_cash_Unpacking_Separate_Day={Rate_cash_Unpacking_Separate_Day}
            Rate_card_Unpacking_Separate_Day={Rate_card_Unpacking_Separate_Day}
            Total_Card_Unpacking_Separate_Day_Long_and_OOS={Total_Card_Unpacking_Separate_Day_Long_and_OOS}
            Truck_fee_Unpacking_Separate_Day={Truck_fee_Unpacking_Separate_Day}
          />
        </div>
        : ""}
    </ul>

  );
};
