import useCollapse from 'react-collapsed';
import styles from './MiscCheckMarksValues.module.css';
import { nanoid } from 'nanoid';

export const MiscChechMarksValues = (props) => {
  const {
    miscCheckMarks,
    selectedMiscCheckMarks,
    checkMarkCheckboxValueChange,
    disabled  
  } = props;
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

  return (
    <> {(miscCheckMarks.length) ?
      <>
        <span style={{ color: '#4db6ac' }}>MISC. CHECK MARKS</span>
        <button {...getToggleProps()} className={styles.btnSubmit}>
          <i className={isExpanded ? styles.up : styles.down}></i>
        </button>
        <section {...getCollapseProps()} >
          <div>
            {miscCheckMarks.map((checkMark, index) => {
              return (
                <div key={nanoid(7)}>
                  <label className={styles.checkLabel} key={nanoid(7)}>
                    <span style={{ textTransform: 'uppercase' }}>{checkMark[`checkMarkName${index}`]}</span>
                    <input
                      className={styles.depcheckbox}
                      type='checkbox'
                      name={checkMark[`uId${index}`]}
                      value={(selectedMiscCheckMarks) ? (selectedMiscCheckMarks.indexOf(checkMark[`uId${index}`]) !== -1) ? true : false : false}
                      onChange={checkMarkCheckboxValueChange}
                      checked={(selectedMiscCheckMarks) ? (selectedMiscCheckMarks.indexOf(checkMark[`uId${index}`]) !== -1) ? true : false : false}
                      disabled={disabled}
                    />
                  </label>
                </div>
              )
            })}
          </div>
        </section>
      </>
      : ''}
    </>
  );
}

