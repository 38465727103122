export const boxesKey = {
    SMALL: 'smallBoxes',
    MEDIUM: 'mediumBoxes',
    FRAGILE: 'fragileBoxes',
};

export const Operators = {
    '===': (a, b) => a === b,
    '<': (a, b) => a < b,
    '>': (a, b) => a > b,
    '<=': (a, b) => a <= b,
    '>=': (a, b) => a >= b,
    '!=': (a, b) => a != b,
};

export const IconNames = {
    TEMPLATES: 'Templates',
    CALCULATIONS: 'Calculations',
    DEPARTMENTS: 'Departments',
    PENCIL: 'Pencil',
    PLUS: 'Plus',
    MINUS: 'Minus',
    SEARCH: 'Search',
    SETTINGS: 'Settings',
    TRIANGLE: 'Triangle',
    USER: 'User',
    USERS: 'Users',
    LOGOUT: 'Logout',
    LONGDISTANCE: 'Long distance',
    OUTOFSTATE: 'Out of state',
    COMPANIES: 'Companies',
    ERRORTRUCK: 'Error truck',
    HOME: 'Home',
    NOTE: 'Note',
    RESTORE: 'Restore',
    HOURLYDEPSETTINGS: 'Hourly departments',
    INVENTORY: 'Inventory',
    CUFTDEPSETTINGS: 'Cu ft departments',
    INVENTORYCOLORED: 'inventorycolored',
    CUSTOMTAG: 'customTag',
};

export const HttpCode = {
    OK: 200,
    FAILED: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
};

export const Role = {
    SUPERUSER: 'superuser', // SuperUser
    COMPANYUSER: 'companyuser', // role for user created with company
    ADMIN: 'admin', // first level admin inside company
    MODERATOR: 'moderator', // second level admin inside company
    VIEWERPLUS: 'viewerplus', // viewer plus inside company
    VIEWER: 'viewer', // viewer inside company

};

export const TypesOfDepartmentData = {
    DOUBLE_FIELD: 'DOUBLE_FIELD',
    FIELD: 'FIELD',
    LIST: 'LIST',
    RADIO: 'RADIO',
    SECTION: 'SECTION',
    SELECT: 'SELECT',
};

export const TypeOfInput = {
    ANY: 'ANY',
    STRING: 'STRING',
    NUMBER: 'NUMBER',
};

let socketUrl = 'http://localhost:3002';

switch (process.env.REACT_APP_MY_ENV) {
    case 'production':
        socketUrl = 'https://visualestimates.com:3001'
        break;

    case 'development':
        socketUrl = 'https://dev.visualestimates.com'
        break;

    default:
        break;
}
export const API_URL = (process.env.NODE_ENV === 'production') ? '/api' : 'http://localhost:9000/api';
export const SOCKET_URL = socketUrl;