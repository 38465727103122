// Third party libraries
import { nanoid } from 'nanoid';
import useCollapse from 'react-collapsed';
import { getTruckFeeMin, showDepartmentSelect } from '../../util/utils';
// Styles
import styles from './CalculationTabLongOneDayValues.module.css';
import { ExtraOptionsValues } from '../extra-options-values/Extra-options-values';
import { MiscChechMarksValues } from '../misc-checkmarks-values/Misc-checkmarks-values';
import { useState } from 'react';
import clockIcon from './../../img/clock.png';
import AdjustTime from '../adjust-time/Adjust-time';

export const CalculationTabLongOneDayValues = ({ rates, formData, formChange, resetLastTarget, renderLongDistancesOptions, onChangeLongDistanceSelect, sepCheckboxValueChange, departments, unpRates, onChangeUnpDepartmentSelect, unpDepartment, lastTarget, setLastTarget, checkMarkCheckboxValueChange, disabled }) => {
  const [showInputs, setShowInputs] = useState(false);
  const [showInputsSecond, setShowInputsSecond] = useState(false);
  const [showInputsUnpacking, setShowInputsUnpacking] = useState(false);
  const onValueChange = (evt) => {
    resetLastTarget();
    formChange(`longOneDayPacking.${evt.target.name}`, 'set', evt.target.value)
  }

  const checkboxValueChange = (evt) => {
    resetLastTarget();

    switch (evt.target.value) {
      case 'true':
        return formChange(`longOneDayPacking.${evt.target.name}`, 'set', false);
      case 'false':
        return formChange(`longOneDayPacking.${evt.target.name}`, 'set', true);
      default:
        break;
    }
  };

  const chargeCheckboxValueChangeDayOne = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedChargesNames = [...formData.longOneDayPacking.selectedChargesNamesDay1];
        var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedChargesNames.splice(index, 1);
          return formChange(`longOneDayPacking.selectedChargesNamesDay1`, 'set', newSelectedChargesNames);
        }
        break;
      case 'false':
        return formChange(`longOneDayPacking.selectedChargesNamesDay1`, 'push', { key: evt.target.name, quantity: 1 });
      default:
        break;
    }
  };

  const chargeCheckboxValueChangeDayTwo = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedChargesNames = [...formData.longOneDayPacking.selectedChargesNamesDay2];
        var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedChargesNames.splice(index, 1);
          return formChange(`longOneDayPacking.selectedChargesNamesDay2`, 'set', newSelectedChargesNames);
        }
        break;
      case 'false':
        return formChange(`longOneDayPacking.selectedChargesNamesDay2`, 'push', { key: evt.target.name, quantity: 1 });
      default:
        break;
    }
  };


  const chargeQuantityChangeDayOne = (evt) => {
    resetLastTarget();
    setLastTarget(evt.target.name)
    var newSelectedChargesNames = JSON.parse(JSON.stringify(formData.longOneDayPacking.selectedChargesNamesDay1));
    var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedChargesNames[index].quantity = Number(evt.target.value)
      return formChange(`longOneDayPacking.selectedChargesNamesDay1`, 'set', newSelectedChargesNames);
    }
  }

  const chargeQuantityChangeDayTwo = (evt) => {
    resetLastTarget();
    setLastTarget(evt.target.name)
    var newSelectedChargesNames = JSON.parse(JSON.stringify(formData.longOneDayPacking.selectedChargesNamesDay2));
    var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedChargesNames[index].quantity = Number(evt.target.value)
      return formChange(`longOneDayPacking.selectedChargesNamesDay2`, 'set', newSelectedChargesNames);
    }
  }


  return (
    <section className={styles.container}>
      <div className={styles.groupInput}>
        <label className={styles.commonLabel} >
          1st date:
          <input
            className={styles.dateInput}
            type='date'
            name={'firstDayDate'}
            value={formData.longOneDayPacking.firstDayDate}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>
        <label className={styles.commonLabel} style={{ marginLeft: "20px" }}>
          2nd date:
          <input
            className={styles.dateInput}
            type='date'
            name={'secondDayDate'}
            value={formData.longOneDayPacking.secondDayDate}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>
      </div>
      <hr className={styles.hLine} style={{ marginLeft: '0px' }} />
      <fieldset className={styles.groupInput}>
        <label>
          Direction:
          <select
            className={styles.select}
            value={formData.longDistance !== undefined ? formData.longDistance._id : 'None'}
            onChange={onChangeLongDistanceSelect}
            disabled={disabled}
          >
            <option>None</option>
            {renderLongDistancesOptions()}
          </select>
        </label>

        <div className={styles.vl}></div>
        <label style={{ marginLeft: '40px', marginTop: "5px" }}>
          Additional amount:
          <input
            onWheel={(e) => e.target.blur()}
            style={{ width: "60px" }}
            type='number'
            name={'additionalAmount'}
            value={(formData.longOneDayPacking) ? formData.longOneDayPacking.additionalAmount : ''}
            placeholder={'ex: 2'}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>

      </fieldset>

      <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", width: '630px' }}>
        <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>DAY - 1 (Packing & Loading)</span>
            <img src={clockIcon} onClick={() => setShowInputs(!showInputs)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
          </div>
          {showInputs && (
            <fieldset className={styles.groupInput}>
              <div className={styles.decorateContainer}>
                <AdjustTime
                  name={'addTimeFirstDay'}
                  value={formData.commonValues.addTimeFirstDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Add time:'}
                  disabled={disabled}
                />
                <AdjustTime
                  name={'removeTimeFirstDay'}
                  value={formData.commonValues.removeTimeFirstDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Remove time:'}
                  disabled={disabled}
                />
              </div>
            </fieldset>
          )}
        </div>
        <div style={{ padding: '5px' }}>
          <fieldset className={styles.groupInput}>
            <div style={{ display: 'flex', width: '100%' }}>
              {rates.length === 0 && <p className={styles.warning}>Select Department!</p>}

              {(rates.length && showDepartmentSelect(formData.department)) > 0 &&
                <label style={{ marginTop: '5px', display: 'inline-block' }}>
                  Rate:
                  <select
                    className={styles.commonInput}
                    value={formData.longOneDayPacking ? formData.longOneDayPacking.firstDayRate : 'none'}
                    onChange={(evt) => {
                      resetLastTarget();
                      formChange('longOneDayPacking.firstDayRate', 'set', evt.target.value);
                    }}
                    disabled={disabled}
                  >
                    <option value={''}>None</option>
                    {
                      rates.map((rate, index) => {
                        if (rate[`longDistanceExtra${index}`] !== '') {
                          return (
                            <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                          );
                        }
                      })
                    }
                  </select>
                </label>
              }
              {(formData.longDistance && formData.longDistance.directionType === 'roomDistance') &&
                <div>
                  <label style={{ marginLeft: '40px' }}>
                    <span>Number of areas (rooms/office/garage):</span>
                    <select
                      className={styles.commonInput}
                      name={'numberOfRoomsDay1'}
                      value={formData.longOneDayPacking.numberOfRoomsDay1}
                      onChange={onValueChange}
                      disabled={disabled}
                    >
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                      <option>7</option>
                      <option>8</option>
                      <option>9</option>
                      <option>10</option>
                    </select>
                  </label>

                  <label style={{ marginLeft: '40px' }}>
                    Adjust rooms to pack
                    <input
                      type='checkbox'
                      name={'adjustRoomsToPack'}
                      value={(formData.longOneDayPacking) ? formData.longOneDayPacking.adjustRoomsToPack : false}
                      checked={(formData.longOneDayPacking) ? formData.longOneDayPacking.adjustRoomsToPack : false}
                      onChange={checkboxValueChange}
                      disabled={disabled}
                    />
                  </label>
                  {formData.longOneDayPacking.adjustRoomsToPack &&
                    <label style={{ marginLeft: '40px', marginTop: "10px", display: 'block' }}>
                      <span>Number of areas to pack:</span>
                      <select
                        className={styles.commonInput}
                        name={'numberOfRoomsToPack'}
                        value={formData.longOneDayPacking.numberOfRoomsToPack}
                        onChange={onValueChange}
                        disabled={disabled}
                      >
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                        <option>7</option>
                        <option>8</option>
                        <option>9</option>
                        <option>10</option>
                      </select>
                    </label>
                  }
                </div>
              }
            </div>

            <div style={{ display: 'flex' }}>
              <label className={styles.commonLabel} style={{ marginTop: '10px' }}>
                <span>Add Movers:</span>
                <select
                  className={styles.commonInput}
                  name={'addMoversDay1'}
                  value={formData.longOneDayPacking.addMoversDay1}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                </select>
              </label>

              <label className={styles.commonLabel} style={{ marginLeft: '20px', marginTop: '10px' }}>
                <span>Remove Movers:</span>
                <select
                  className={styles.commonInput}
                  name={'removeMoversDay1'}
                  value={formData.longOneDayPacking.removeMoversDay1}
                  onChange={onValueChange}
                  disabled={disabled}
                >
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                </select>
              </label>

              {formData.commonValues.shuttle && <>
                <label className={styles.shuttleOnDayOne} style={{ marginLeft: '20px', marginTop: '10px' }}>
                  Shuttle on Day 1
                  <input
                    type='checkbox'
                    name={'shuttleOnDay1'}
                    value={(formData.longOneDayPacking) ? formData.longOneDayPacking.shuttleOnDay1 : false}
                    checked={(formData.longOneDayPacking) ? formData.longOneDayPacking.shuttleOnDay1 : false}
                    onChange={checkboxValueChange}
                    disabled={disabled}
                  />
                </label>
                {(formData.department.extraOptions.shuttle.isCountable && formData.longOneDayPacking.shuttleOnDay1) &&
                  <label className={styles.inputContainer}>
                    Qty:
                    <input
                      type='number'
                      name={'quantityDay1'}
                      value={(formData.longOneDayPacking.quantityDay1)}
                      placeholder={'ex: 2'}
                      onChange={onValueChange}
                      onWheel={(e) => e.target.blur()}
                      disabled={disabled}
                    />
                  </label>
                }
              </>
              }
            </div>
          </fieldset>
          {formData.department && formData.department.extraOptions && formData.department.extraOptions.charges.length &&
            <>
              <div style={{ marginTop: '10px' }}>
                <hr className={styles.hLine} />
                <ExtraOptionsValues
                  charges={(formData.department && formData.department.extraOptions) ? formData.department.extraOptions.charges : []}
                  chargeCheckboxValueChange={chargeCheckboxValueChangeDayOne}
                  selectedChargesNames={formData.longOneDayPacking.selectedChargesNamesDay1}
                  lastTarget={lastTarget}
                  chargeQuantityChange={chargeQuantityChangeDayOne}
                  disabled={disabled}
                />
                <hr className={styles.hLine} />
              </div>
            </>
          }


          <fieldset className={styles.groupInput} >
            <label style={{ marginTop: "5px" }}>
              Driving time adjustment:
              <input
                onWheel={(e) => e.target.blur()}
                style={{ width: "45px" }}
                type='number'
                name={'drivingTimeAdjustment'}
                value={(formData.longOneDayPacking) ? formData.longOneDayPacking.drivingTimeAdjustment : ''}
                placeholder={'ex: 2'}
                onChange={onValueChange}
                disabled={disabled}
              />
            </label>
          </fieldset>
        </div>
      </div>

      <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", marginTop: '20px', width: '630px' }}>
        <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>DAY - 2 (Unloading)</span>
            <img src={clockIcon} onClick={() => setShowInputsSecond(!showInputsSecond)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
          </div>
          {showInputsSecond && (
            <fieldset className={styles.groupInput}>
              <div className={styles.decorateContainer}>
                <AdjustTime
                  name={'addTimeSecondDay'}
                  value={formData.commonValues.addTimeSecondDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Add time:'}
                  disabled={disabled}
                />
                <AdjustTime
                  name={'removeTimeSecondDay'}
                  value={formData.commonValues.removeTimeSecondDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Remove time:'}
                  disabled={disabled}
                />
              </div>
            </fieldset>
          )}
        </div>
        <div style={{ padding: '5px' }}>
          <fieldset className={styles.groupInput}>
            <label className={styles.commonLabel} style={{ marginTop: '5px' }}>
              <span>Add Movers:</span>
              <select
                className={styles.commonInput}
                name={'addMoversDay2'}
                value={formData.longOneDayPacking.addMoversDay2}
                onChange={onValueChange}
                disabled={disabled}
              >
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>
            </label>

            <label className={styles.commonLabel} style={{ marginLeft: '20px', marginTop: '5px' }}>
              <span>Remove Movers:</span>
              <select
                className={styles.commonInput}
                name={'removeMoversDay2'}
                value={formData.longOneDayPacking.removeMoversDay2}
                onChange={onValueChange}
                disabled={disabled}
              >
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>

            </label>
            {formData.commonValues.shuttle && <>
              <label className={styles.shuttleOnDayOne} style={{ marginLeft: '20px', marginTop: '5px' }}>
                Shuttle on Day 2
                <input
                  type='checkbox'
                  name={'shuttleOnDay2'}
                  value={(formData.longOneDayPacking) ? formData.longOneDayPacking.shuttleOnDay2 : false}
                  checked={(formData.longOneDayPacking) ? formData.longOneDayPacking.shuttleOnDay2 : false}
                  onChange={checkboxValueChange}
                  disabled={disabled}
                />
              </label>
              {(formData.department.extraOptions.shuttle.isCountable && formData.commonValues.shuttle) && formData.longOneDayPacking.shuttleOnDay2 &&
                <label className={styles.inputContainer}>
                  Qty:
                  <input
                    type='number'
                    name={'quantityDay2'}
                    value={(formData.longOneDayPacking.quantityDay2)}
                    placeholder={'ex: 2'}
                    onChange={onValueChange}
                    onWheel={(e) => e.target.blur()}
                    disabled={disabled}
                  />
                </label>
              }
            </>
            }

          </fieldset>

          {(formData.department && formData.department.extraOptions && formData.department.extraOptions.charges.length) ?
            <>
              <hr className={styles.hLine} style={{ marginLeft: '0px' }} />

              <ExtraOptionsValues
                charges={(formData.department && formData.department.extraOptions) ? formData.department.extraOptions.charges : []}
                chargeCheckboxValueChange={chargeCheckboxValueChangeDayTwo}
                selectedChargesNames={formData.longOneDayPacking.selectedChargesNamesDay2}
                lastTarget={lastTarget}
                chargeQuantityChange={chargeQuantityChangeDayTwo}
                disabled={disabled}
              />
              <hr className={styles.hLine} style={{ marginLeft: '0px' }} />
            </>
            :
            <hr className={styles.hLine} style={{ marginLeft: '0px' }} />}

          <MiscChechMarksValues
            miscCheckMarks={(formData.department && formData.department.miscCheckMarks && formData.department.miscCheckMarks.miscCheckMarks.length) ? formData.department.miscCheckMarks.miscCheckMarks : []}
            selectedMiscCheckMarks={formData.selectedMiscCheckMarks}
            checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
            disabled={disabled}
          />
        </div>
      </div>
      {formData.commonValues.unpacking === 'Separate Day' && <div>
        <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", marginTop: '20px', width: '630px' }}>
          <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>UNPACKING ON SEPARATE DAY</span>
              <img src={clockIcon} onClick={() => setShowInputsUnpacking(!showInputsUnpacking)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
            </div>
            {showInputsUnpacking && (
              <fieldset className={styles.groupInput}>
                <div className={styles.decorateContainer}>
                  <AdjustTime
                    name={'addTimeUnp'}
                    value={formData.commonValues.addTimeUnp}
                    onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                    labelText={'Add time:'}
                    disabled={disabled}
                  />
                  <AdjustTime
                    name={'removeTimeUnp'}
                    value={formData.commonValues.removeTimeUnp}
                    onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                    labelText={'Remove time:'}
                    disabled={disabled}
                  />
                </div>
              </fieldset>
            )}
          </div>


          <div className={styles.unpSepCont}>
            <div className={styles.unpSepCont1}>
              <label className={styles.label} style={{ marginTop: '5px', display: 'block' }}>
                Department
                <select
                  className={styles.select}
                  value={unpDepartment !== undefined ? unpDepartment._id : 'None'}
                  onChange={onChangeUnpDepartmentSelect}
                  onFocus={resetLastTarget}
                  disabled={disabled}
                >
                  <option value={undefined}>None</option>
                  {
                    departments && departments.map((department) => {
                      return (
                        <option key={nanoid(departments.length)} value={department._id}>{department.departmentName}</option>
                      );
                    })
                  }
                </select>
              </label>

              {unpRates.length === 0 && <p className={styles.warning1} >Select Separate day Department!</p>}
              {unpRates.length > 0 &&
                <label style={{ display: 'block' }}>
                  Rate:
                  <select
                    className={styles.select}
                    value={formData.commonValues.unpackingRate}
                    onChange={(evt) => {
                      resetLastTarget();
                      formChange('commonValues.unpackingRate', 'set', evt.target.value);
                    }}
                    disabled={disabled}
                  >
                    <option value={''} key='none'>None</option>
                    {
                      unpRates.map((rate, index) => {
                        return (
                          <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                        );
                      })
                    }
                  </select>
                </label>
              }

              <label style={{ justifyContent: "flex-start", }} >
                <span>Movers to Unpack:</span>
                <select
                  className={styles.commonInput}
                  name={'moversToUnPackSeparate'}
                  value={(formData.commonValues.moversToUnPackSeparate) ? formData.commonValues.moversToUnPackSeparate : ''}
                  onChange={(evt) => {
                    resetLastTarget();
                    formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
                  }}
                  disabled={disabled}
                >
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                </select>
              </label>


              {formData.commonValues.shuttle &&
                <div className={styles.groupInput}>
                  <label className={styles.shuttleOnDayOne} style={{ marginTop: "5px" }}>
                    Shuttle on Unpacking Day
                    <input
                      type='checkbox'
                      name={'shuttleOnUnpacking'}
                      value={(formData.commonValues.shuttleOnUnpacking) ? formData.commonValues.shuttleOnUnpacking : false}
                      checked={(formData.commonValues.shuttleOnUnpacking) ? formData.commonValues.shuttleOnUnpacking : false}
                      onChange={sepCheckboxValueChange}
                      disabled={disabled}
                    />
                  </label>
                  {formData.commonValues.shuttleOnUnpacking &&
                    <label className={styles.inputContainer}>
                      Qty:
                      <input
                        type='number'
                        name={'quantityUnpacking'}
                        value={formData.commonValues.quantityUnpacking}
                        placeholder={'ex: 2'}
                        onChange={(evt) => {
                          resetLastTarget();
                          formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
                        }}
                        onWheel={(e) => e.target.blur()}
                        disabled={disabled}
                      />
                    </label>
                  }
                </div>
              }

            </div>

            {formData.department && formData.department.truckFee && formData.department.truckFee.calcMethod === "different" &&
              <fieldset className={styles.groupInput}>
                <label className={styles.inputContainer1} style={{ width: "400px", marginLeft: '60px' }}>
                  Truck fee:
                  <input
                    onWheel={(e) => e.target.blur()}
                    type='number'
                    name={'truckFeeSeparate'}
                    value={(formData._id) ? (formData.longOneDayPacking) ? formData.longOneDayPacking.truckFeeSeparate : '' : getTruckFeeMin(formData.department.truckFee.diffTruckReservations)}
                    placeholder={'ex: 40'}
                    onChange={onValueChange}
                    disabled={disabled}
                  />
                </label>
              </fieldset>
            }
          </div>
        </div>
      </div>}

    </section>
  );
};