import { createSlice } from '@reduxjs/toolkit';
import {
  loadCalculation,
  saveCalculation,
  updateCalculation,
  removeCalculation,
  cloneCalculation,
  cloneToSubCalculation,
  removeCalculationHard,
  restoreCalculation
} from './calculation-async-thunk';


const initialState = {
  savingStatus: 'idle',
  loadingStatus: 'idle',
  restoringStatus: 'idle',
  removingStatus: 'idle',
  hardRemovingStatus: 'idle',
  cloningStatus: 'idle',
  subCalcCloningStatus: 'idle',
  calculation: {},
  countOfCalculations: 0,
  errors: null,
};

const calculationSlice = createSlice({
  name: 'calculation',
  initialState,
  reducers: {
    setCalculationSavingStatus(state, action) {
      const { status } = action.payload;
      state.savingStatus = status;
    },
    setCalculationLoadingStatus(state, action) {
      const { status } = action.payload;
      state.loadingStatus = status;
    },
    setCalculationRemovingStatus(state, action) {
      const { status } = action.payload;
      state.removingStatus = status;
    },
    setCalculationHardRemovingStatus(state, action) {
      const { status } = action.payload;
      state.hardRemovingStatus = status;
    },
    setCalculationCloningStatus(state, action) {
      const { status } = action.payload;
      state.cloningStatus = status;
    },
    setCalculationSubCalcCloningStatus(state, action) {
      const { status } = action.payload;
      state.subCalcCloningStatus = status;
    },
    setCalculationRestoringStatus(state, action) {
      const { status } = action.payload;
      state.restoringStatus = status;
    },
  },
  extraReducers: {
    // saveCalculation extra reducer
    [saveCalculation.pending]: (state, action) => {
      state.savingStatus = 'loading';
    },
    [saveCalculation.rejected]: (state, action) => {
      state.savingStatus = 'failed';
      state.errors = action.payload.message;
    },
    [saveCalculation.fulfilled]: (state, action) => {
      state.savingStatus = 'successed';
      state.calculation = action.payload.data;
    },

    // updateCalculation extra reducer
    [updateCalculation.pending]: (state, action) => {
      state.savingStatus = 'loading';
    },
    [updateCalculation.rejected]: (state, action) => {
      state.savingStatus = 'failed';
      state.errors = action.payload.message;
    },
    [updateCalculation.fulfilled]: (state, action) => {
      state.savingStatus = 'successed';
      state.calculation = action.payload.data;
    },

    // loadCalculation extra reducer
    [loadCalculation.pending]: (state, action) => {
      state.loadingStatus = 'loading';
    },
    [loadCalculation.rejected]: (state, action) => {
      state.loadingStatus = 'failed';
      state.errors = action.error.message;
    },
    [loadCalculation.fulfilled]: (state, action) => {
      state.loadingStatus = 'successed';
      state.calculation = action.payload.data;
    },

    //removeCalculation extra reducer
    [removeCalculation.pending]: (state, action) => {
      state.removingStatus = 'loading';
    },
    [removeCalculation.rejected]: (state, action) => {
      state.removingStatus = 'failed';
      state.errors = action.error.message;
    },
    [removeCalculation.fulfilled]: (state, action) => {
      state.removingStatus = 'successed';
      state.calculation = action.payload.data;
    },

    //removeCalculationHard extra reducer
    [removeCalculationHard.pending]: (state, action) => {
      state.hardRemovingStatus = 'loading';
    },
    [removeCalculationHard.rejected]: (state, action) => {
      state.hardRemovingStatus = 'failed';
      state.errors = action.error.message;
    },
    [removeCalculationHard.fulfilled]: (state, action) => {
      state.hardRemovingStatus = 'successed';
      state.calculation = action.payload.data;
    },

    //cloneCalculation extra reducer
    [cloneCalculation.pending]: (state, action) => {
      state.cloningStatus = 'loading';
    },
    [cloneCalculation.rejected]: (state, action) => {
      state.cloningStatus = 'failed';
      state.errors = action.error.message;
    },
    [cloneCalculation.fulfilled]: (state, action) => {
      state.cloningStatus = 'successed';
      state.calculation = action.payload.data;
    },

    //cloneChildCalculation extra reducer
    [cloneToSubCalculation.pending]: (state, action) => {
      state.subCalcCloningStatus = 'loading';
    },
    [cloneToSubCalculation.rejected]: (state, action) => {
      state.subCalcCloningStatus = 'failed';
      state.errors = action.error.message;
    },
    [cloneToSubCalculation.fulfilled]: (state, action) => {
      state.subCalcCloningStatus = 'successed';
      state.calculation = action.payload.data;
    },

    //restoreCalculation extra reducer
    [restoreCalculation.pending]: (state, action) => {
      state.restoringStatus = 'loading';
    },
    [restoreCalculation.rejected]: (state, action) => {
      state.restoringStatus = 'failed';
      state.errors = action.error.message;
    },
    [restoreCalculation.fulfilled]: (state, action) => {
      state.restoringStatus = 'successed';
      state.calculation = action.payload.data;
    },
  },
});

export const {
  setCalculationSavingStatus,
  setCalculationLoadingStatus,
  setCalculationRemovingStatus,
  setCalculationCloningStatus,
  setCalculationSubCalcCloningStatus,
  setCalculationHardRemovingStatus,
  setCalculationRestoringStatus
} = calculationSlice.actions;

export default calculationSlice.reducer;

export const getCalculationSavingStatus = (state) => state.calculation.savingStatus;

export const getCalculationLoadingStatus = (state) => state.calculation.loadingStatus;

export const getCalculationRemovingStatus = (state) => state.calculation.removingStatus;

export const getCalculationRestoringStatus = (state) => state.calculation.restoringStatus;

export const getCalculationHardRemovingStatus = (state) => state.calculation.hardRemovingStatus;

export const getCalculationCloningStatus = (state) => state.calculation.cloningStatus;

export const getCalculationSubCalcCloningStatus = (state) => state.calculation.subCalcCloningStatus;

export const getCalculation = (state) => state.calculation.calculation;

export const getCalculationSavingError = (state) => state.calculation.errors;
