import React, { useState } from 'react';
import CreatableSelect from 'react-select/creatable';
// Styles
import styles from './FurnitureNameInput.module.css';
import { hasRole } from '../../util/utils';
import { Role } from '../../util/const';

const FurnitureNameInput = ({ onFormValueChange, selectedRoomIndex, furniture, inventory,
  itemIndex, furnitureType, reference, cuFtRef, setLastModifiedIndex, currentUserRole }) => {

  const customStyles = {
    container: (provided) => ({
      ...provided,
      minWidth: '200px',
      maxWidth: '200px',
      '@media (max-width: 1200px)': {
        minWidth: '90px',
        maxWidth: '90px',
      },
      minHeight: '0',
    }),
    control: (provided) => ({
      ...provided,
      border: 'none',
      outline: 'none',
      backgroundColor: 'transparent',
      boxShadow: 'none', // Remove the default box shadow
      borderBottom: '1px solid #000',
      borderRadius: '0px',
      minHeight: '0',
      padding: '0px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0px',
      paddingLeft: '12px',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '14px', // Make selected text size bigger
      fontWeight: 'bold',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '14px', // Make placeholder text size bigger
      fontWeight: 'bold', // Make placeholder text bold
    }),
    dropdownIndicator: () => ({
      display: 'none', // Remove the dropdown indicator
    }),
    indicatorSeparator: () => ({
      display: 'none', // Remove the vertical line
    }),
    input: (provided) => ({
      ...provided,
      fontSize: '14px', // Make selected text size bigger
      fontWeight: 'bold',
      padding: '0px',
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999, // Ensure the portal has a high zIndex
    }),
  };



  const handleSelectChange = (newValue, actionMeta) => {
    if (newValue) {
      let newRooms = inventory.rooms.map((room, idx) => {
        if (idx === selectedRoomIndex) {
          return {
            ...room, [furnitureType]: room[`${furnitureType}`].map((item, index) => {
              if (index === itemIndex) {
                let furnitureItemIndex = furniture.items.findIndex((furnitureItem, fili) => furnitureItem[`itemName${fili}`] === newValue.value);
                const furnitureItem = furniture.items.find(furnitureItem => furnitureItem[`itemName${furniture.items.indexOf(furnitureItem)}`] === newValue.value);
                if (furnitureItemIndex !== -1) {
                  return { ...item, name: newValue.value, cuft: Number(furnitureItem[`mostCommonCuFt${furnitureItemIndex}`]) }
                } else {
                  return { ...item, name: newValue.value, cuft: 0 }
                }
              }
              return item
            })
          }
        }
        return room
      })
      onFormValueChange(`inventory.rooms`, 'set', newRooms);
      setLastModifiedIndex(itemIndex);
    }
  };


  const furnitureOptions = furniture.items.map((furnitureItem, index) => {
    return {
      value: furnitureItem[`itemName${index}`],
      label: furnitureItem[`itemName${index}`],
    }
  });

  const uniqueKey = `${selectedRoomIndex}-${furnitureType}-${itemIndex}-${inventory.rooms[selectedRoomIndex][`${furnitureType}`][itemIndex].name}`;

  const handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      // Do nothing if the Tab key is pressed
      event.stopPropagation();
    }
  };
  return (
    <CreatableSelect
      key={uniqueKey}
      ref={reference}
      styles={customStyles}
      options={furnitureOptions}
      value={{ value: inventory.rooms[selectedRoomIndex][`${furnitureType}`][itemIndex].name, label: inventory.rooms[selectedRoomIndex][`${furnitureType}`][itemIndex].name }}
      onChange={handleSelectChange}
      menuPortalTarget={document.body}
      onKeyDown={handleKeyDown}
      isDisabled={!hasRole(currentUserRole, [Role.SUPERUSER, Role.COMPANYUSER, Role.ADMIN, Role.MODERATOR,Role.VIEWERPLUS])}
    />
  );
};

export default FurnitureNameInput;