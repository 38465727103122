import useCollapse from 'react-collapsed';
import styles from './AdditionalServicesValues.module.css';
import { nanoid } from 'nanoid';

export const AdditionalServicesValues = (props) => {
  const {
    additionalServices,
    selectedAdditionalServices,
    additionalServicesCheckboxValueChange,
    additionalServicesPlaceType,
    aSQuantityChange,
    formChange,
    disabled
  } = props;
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  return (
    <>

      {(additionalServices.services.length) ?
        <>
          <span style={{ color: '#4db6ac' }}>ADDITIONAL SERVICES</span>
          <button {...getToggleProps()} className={styles.btnSubmit}>
            <i className={isExpanded ? styles.up : styles.down}></i>
          </button>
          <section {...getCollapseProps()} >


            <h3 style={{ color: 'rgb(77, 182, 172)' }}>Type of place services:</h3>
            {additionalServices.services.map((additionalService, index) => {
              if (additionalService.serviceType === 'factor') {
                return (
                  <div key={nanoid(7)}>
                    <label className={styles.checkLabel} key={nanoid(7)}>
                      <span style={{ textTransform: 'uppercase' }}>{additionalService.serviceName}</span>
                      <input
                        className={styles.depcheckbox}
                        type='checkbox'
                        name={additionalService.serviceName}
                        value={(selectedAdditionalServices) ? (selectedAdditionalServices.find(item => item.key === additionalService.serviceName)) ? true : false : false}
                        onChange={additionalServicesCheckboxValueChange}
                        checked={(selectedAdditionalServices) ? (selectedAdditionalServices.find(item => item.key === additionalService.serviceName)) ? true : false : false}
                        disabled={additionalServicesPlaceType === '' || additionalServicesPlaceType === 'None' || disabled}
                      />
                    </label>
                    {(additionalService.isCountable && selectedAdditionalServices && selectedAdditionalServices.find(item => item.key === additionalService.serviceName)) &&
                      <label className={styles.inputContainer}>
                        Qty:
                        <input
                          type='number'
                          className={styles.input}
                          name={additionalService.serviceName}
                          value={selectedAdditionalServices.find(item => item.key === additionalService.serviceName).quantity}
                          placeholder={'ex: 2'}
                          onChange={aSQuantityChange}
                          onWheel={(e) => e.target.blur()}
                          disabled={disabled}
                        />
                      </label>
                    }
                  </div>
                )
              }
            })}
            <h3 style={{ color: 'rgb(77, 182, 172)' }}>Cu ft services:</h3>
            {additionalServices && additionalServices.services.map((additionalService, index) => {
              if (additionalService.serviceType === 'cuft') {
                return (
                  <div key={nanoid(7)}>
                    <label className={styles.checkLabel} key={nanoid(7)}>
                      <span style={{ textTransform: 'uppercase' }}>{additionalService.serviceName}</span>
                      <input
                        className={styles.depcheckbox}
                        type='checkbox'
                        name={additionalService.serviceName}
                        value={(selectedAdditionalServices) ? (selectedAdditionalServices.find(item => item.key === additionalService.serviceName)) ? true : false : false}
                        onChange={additionalServicesCheckboxValueChange}
                        checked={(selectedAdditionalServices) ? (selectedAdditionalServices.find(item => item.key === additionalService.serviceName)) ? true : false : false}
                        disabled={additionalServicesPlaceType === '' || additionalServicesPlaceType === 'None' || disabled}
                      />
                    </label>
                    {(additionalService.isCountable && selectedAdditionalServices && selectedAdditionalServices.find(item => item.key === additionalService.serviceName)) &&
                      <label className={styles.inputContainer}>
                        Qty:
                        <input
                          className={styles.input}
                          type='number'
                          name={additionalService.serviceName}
                          value={selectedAdditionalServices.find(item => item.key === additionalService.serviceName).quantity}
                          placeholder={'ex: 2'}
                          onChange={aSQuantityChange}
                          onWheel={(e) => e.target.blur()}
                          disabled={disabled}
                        />
                      </label>
                    }
                  </div>
                )
              }
            })}
            <div>
            </div>
          </section>
        </>
        : ''}
    </>
  );
}

