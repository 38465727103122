// Components
import { CalculationTwoDays150Params } from '../calculation-two-days-150-params/Calculation-two-days-150-params';
import { BtnApplyNewRate } from '../btn-apply-new-rate/Btn-apply-new-rate';
import { CalculationTwoDaysPayment } from '../calculation-two-days-payment/Calculation-two-days-payment';

// import { boxesKey } from '../../util/const';
// Styles
import styles from './CalculationTwoDays150Result.module.css';
import { BtnApplyNewTemplate } from '../btn-apply-new-template/Btn-apply-new-template';

export const CalculationTwoDays150Result = (props) => {
  const { calculationData, sameDepartmentSettings, applyNewRates, formChange, resetLastTarget, checkboxValueChange, editorState,
    tags,
    setPreviewText, setOptionsPreviewText,
    popUpShown, oldTemplateWasUsed, applyNewTemplate, setShowGenerateEmail, furniture, roomsAreas, setTagsValuesDefault, customTagsValues, showCustomTags } = props;
  const commonValues = { ...calculationData.commonValues };
  const twoDays150Miles = { ...calculationData.twoDays150Miles };
  const department = { ...calculationData.department };
  const selectedMiscCheckMarks = calculationData.selectedMiscCheckMarks

  return (
    <section className={styles.container}>
      <h2 className={styles.title}>Calculation Two days - 150 miles</h2>
      {!sameDepartmentSettings && <div className={styles.oldApplyNew}><h2 className={styles.title} style={{ color: "red" }}>Old department settings were used</h2>
        <BtnApplyNewRate
          isActive={true}
          action={applyNewRates}
          name={'Apply new rates'}
        />
      </div>}
      {oldTemplateWasUsed && <div className={styles.oldApplyNew}><h2 className={styles.title} style={{ color: "#0000ff" }}>Old template settings were used</h2>
        <BtnApplyNewTemplate
          isActive={true}
          action={applyNewTemplate}
          name={'Apply new Template'}
        />
      </div>}
      {calculationData &&
        <div className={styles.selectedInfo}>
          {calculationData.calculationNumber && <p className={styles.calculationNumber}>{calculationData.calculationNumber}</p>}
          {calculationData.department && <p className={styles.departmentName}>{calculationData.department.departmentName}</p>}
          {calculationData.twoDays150Miles.firstDayRate && <p className={styles.rateName}>{calculationData.twoDays150Miles.firstDayRate}</p>}
          {calculationData.twoDays150Miles.secondDayRate && <p className={styles.secondRateName}>{calculationData.twoDays150Miles.secondDayRate}</p>}
        </div>
      }

      <div className={styles.selectedInfo}>
        {twoDays150Miles.firstDayDate && <p className={styles.calculationDate} style={{ marginRight: '4px' }}>{new Date(twoDays150Miles.firstDayDate).toLocaleDateString('en-us', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' })}</p>}
        {twoDays150Miles.secondDayDate && <p className={styles.calculationDate}>{twoDays150Miles.firstDayDate && '& '} {new Date(twoDays150Miles.secondDayDate).toLocaleDateString('en-us', { weekday: 'short', year: 'numeric', month: 'numeric', day: 'numeric', timeZone: 'UTC' })}</p>}
      </div>

      <CalculationTwoDays150Params
        commonValues={commonValues}
        twoDays150Miles={twoDays150Miles}
        department={department}
        selectedMiscCheckMarks={selectedMiscCheckMarks}
        formChange={formChange}
        resetLastTarget={resetLastTarget}
        checkboxValueChange={checkboxValueChange}
        editorState={editorState}
        calculationData={calculationData}
        tags={tags}
        popUpShown={popUpShown}
        setPreviewText={setPreviewText}
        setOptionsPreviewText={setOptionsPreviewText}
        setShowGenerateEmail={setShowGenerateEmail}
        furniture={furniture}
        roomsAreas={roomsAreas}
        setTagsValuesDefault={setTagsValuesDefault}
        customTagsValues={customTagsValues}
        showCustomTags={showCustomTags}
      />
      {/*       <CalculationTwoDaysPayment calculationData={calculationData} /> */}
    </section>
  );
};
