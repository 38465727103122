// Third party libraries
import PropTypes from 'prop-types';
// Components
import { NumberInputEC } from '../number-input-ec/Number-input-ec';
// Styles
import styles from './CalculationCommonLabel.module.css';


export const CalculationCommonLabel = (props) => {
  const {
    title,
    value,
    placeholder,
    inputName,
    changeValue,
    isFocused = false,
    cursorPosition,
    isMilesFin,
    titleStyle = {},
    index,
    disabled = false,
  } = props;
  let lCName = styles.commonLabel;
  if (inputName === 'driveTime') {
    lCName = styles.commonLabelB
  }
  else if (isMilesFin && inputName === 'miles') {
    lCName = styles.commonLabelB
  }
  else {
    lCName = styles.commonLabel
  }

  if (index !== undefined) {
    if (inputName === `driveTime${index}`) {
      lCName = styles.commonLabelB
    }
    else if (isMilesFin && inputName === `miles${index}`) {
      lCName = styles.commonLabelB
    }
    else {
      lCName = styles.commonLabel
    }
  }
  return (
    <label className={lCName} >
      <span className={styles.commonTxt} style={titleStyle}>{title}</span>
      <NumberInputEC
        className={styles.commonInput}
        type='text'
        placeholder={placeholder}
        name={inputName}
        value={value}
        onChange={changeValue}
        autoFocus={isFocused}
        required
        onWheel={(e) => e.target.blur()}
        cursorPosition={cursorPosition}
        disabled={disabled}
      />
    </label>
  );
};

CalculationCommonLabel.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
};
