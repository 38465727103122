// Third party libraries
import { nanoid } from 'nanoid';
import useCollapse from 'react-collapsed';
import { getTruckFeeMin } from '../../util/utils';
// Styles
import styles from './CalculationTabOOSOneDayValues.module.css';
import { ExtraOptionsValues } from '../extra-options-values/Extra-options-values';
import { MiscChechMarksValues } from '../misc-checkmarks-values/Misc-checkmarks-values';
import { useState } from 'react';
import clockIcon from './../../img/clock.png';
import AdjustTime from '../adjust-time/Adjust-time';

export const CalculationTabOOSOneDayValues = ({ rates, factors, formData, formChange, resetLastTarget, renderOOSOptions, onChangeOutOfStateSelect, sepCheckboxValueChange, departments, unpRates, onChangeUnpDepartmentSelect, unpDepartment, setLastTarget, lastTarget, checkMarkCheckboxValueChange, disabled }) => {
  const [showInputs, setShowInputs] = useState(false);
  const [showInputsSecond, setShowInputsSecond] = useState(false);
  const [showInputsThird, setShowInputsThird] = useState(false);
  const [showInputsUnpacking, setShowInputsUnpacking] = useState(false);

  const onValueChange = (evt) => {
    resetLastTarget();
    formChange(`oos1day.${evt.target.name}`, 'set', evt.target.value)
  }

  const checkboxValueChange = (evt) => {
    resetLastTarget();

    switch (evt.target.value) {
      case 'true':
        return formChange(`oos1day.${evt.target.name}`, 'set', false);
      case 'false':
        return formChange(`oos1day.${evt.target.name}`, 'set', true);
      default:
        break;
    }
  };

  const chargeCheckboxValueChangeDayOne = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedChargesNames = [...formData.oos1day.selectedChargesNamesDay1];
        var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedChargesNames.splice(index, 1);
          return formChange(`oos1day.selectedChargesNamesDay1`, 'set', newSelectedChargesNames);
        }
        break;
      case 'false':
        return formChange(`oos1day.selectedChargesNamesDay1`, 'push', { key: evt.target.name, quantity: 1 });
      default:
        break;
    }
  };

  const chargeCheckboxValueChangeDayTwo = (evt) => {
    resetLastTarget();
    switch (evt.target.value) {
      case 'true':
        var newSelectedChargesNames = [...formData.oos1day.selectedChargesNamesDay2];
        var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
        if (index !== -1) {
          newSelectedChargesNames.splice(index, 1);
          return formChange(`oos1day.selectedChargesNamesDay2`, 'set', newSelectedChargesNames);
        }
        break;
      case 'false':
        return formChange(`oos1day.selectedChargesNamesDay2`, 'push', { key: evt.target.name, quantity: 1 });
      default:
        break;
    }
  };

  const chargeQuantityChangeDayOne = (evt) => {
    resetLastTarget();
    setLastTarget(evt.target.name)
    var newSelectedChargesNames = JSON.parse(JSON.stringify(formData.oos1day.selectedChargesNamesDay1));
    var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedChargesNames[index].quantity = Number(evt.target.value)
      return formChange(`oos1day.selectedChargesNamesDay1`, 'set', newSelectedChargesNames);
    }
  }

  const chargeQuantityChangeDayTwo = (evt) => {
    resetLastTarget();
    setLastTarget(evt.target.name)
    var newSelectedChargesNames = JSON.parse(JSON.stringify(formData.oos1day.selectedChargesNamesDay2));
    var index = newSelectedChargesNames.findIndex(item => item.key === evt.target.name);
    if (index !== -1) {
      newSelectedChargesNames[index].quantity = Number(evt.target.value)
      return formChange(`oos1day.selectedChargesNamesDay2`, 'set', newSelectedChargesNames);
    }
  }

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
  return (
    <section className={styles.container}>

      <div className={styles.groupInput}>
        <label className={styles.commonLabel}>
          1st date:
          <input
            className={styles.dateInput}
            type='date'
            name={'firstDayDate'}
            value={formData.oos1day.firstDayDate}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>
        <label className={styles.commonLabel} style={{ marginLeft: "20px" }}>
          2nd date:
          <input
            className={styles.dateInput}
            type='date'
            name={'secondDayDate'}
            value={formData.oos1day.secondDayDate}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>
      </div>
      <hr className={styles.hLine} style={{ marginLeft: '0px' }} />
      <fieldset className={styles.groupInput}>
        <label>
          Direction:
          <select
            className={styles.select}
            value={formData.outOfState ? formData.outOfState._id : 'None'}
            onChange={onChangeOutOfStateSelect}
            disabled={disabled}
          >
            <option value={undefined}>None</option>
            {renderOOSOptions()}
          </select>
        </label>

        <div className={styles.vl}></div>
        <label style={{ marginLeft: '40px', marginTop: "5px" }}>
          Additional amount:
          <input
            onWheel={(e) => e.target.blur()}
            style={{ width: "60px" }}
            type='number'
            name={'additionalAmount'}
            value={(formData.oos1day) ? formData.oos1day.additionalAmount : ''}
            placeholder={'ex: 2'}
            onChange={onValueChange}
            disabled={disabled}
          />
        </label>
      </fieldset>
      <hr className={styles.hLine} />
      <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", marginTop: '20px', width: '600px' }}>
        <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>DAY - 1 (Packing & Loading)</span>
            <img src={clockIcon} onClick={() => setShowInputs(!showInputs)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
          </div>
          {showInputs && (
            <fieldset className={styles.groupInput}>
              <div className={styles.decorateContainer}>
                <AdjustTime
                  name={'addTimeFirstDay'}
                  value={formData.commonValues.addTimeFirstDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Add time:'}
                  disabled={disabled}
                />
                <AdjustTime
                  name={'removeTimeFirstDay'}
                  value={formData.commonValues.removeTimeFirstDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Remove time:'}
                  disabled={disabled}
                />
              </div>
            </fieldset>
          )}
        </div>
        <div style={{ padding: '5px' }}>
          {rates.length === 0 && <p className={styles.warning} >Select Department!</p>}
          {rates.length > 0 &&
            <label style={{ marginTop: '10px' }}>
              Rate:
              <select
                className={styles.select}
                value={formData.oos1day.firstDayRate}
                onChange={(evt) => {
                  resetLastTarget();
                  formChange('oos1day.firstDayRate', 'set', evt.target.value);
                }}
                disabled={disabled}
              >
                <option value={''} key='none'>None</option>
                {
                  rates.map((rate, index) => {
                    return (
                      <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                    );
                  })
                }
              </select>
            </label>
          }
          <label style={{ marginLeft: "20px" }}>
            Factor:
            <select
              className={styles.select}
              value={formData.oos1day.factor}
              onChange={(evt) => {
                resetLastTarget();
                formChange('oos1day.factor', 'set', evt.target.value);
              }}
              disabled={disabled}
            >
              <option value={''} key='none'>None</option>
              {
                factors.map((factor, index) => {
                  return (
                    <option key={nanoid(factor.length)} value={factor[`pName${index}`]}>{factor[`pName${index}`]}</option>
                  );
                })
              }
            </select>
          </label>




          <fieldset className={styles.groupInput}>
            <label className={styles.commonLabel} style={{ marginTop: '10px' }}>
              <span>Add Movers:</span>
              <select
                className={styles.commonInput}
                name={'addMoversDay1'}
                value={formData.oos1day.addMoversDay1}
                onChange={onValueChange}
                disabled={disabled}
              >
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>
            </label>

            <label className={styles.commonLabel} style={{ marginLeft: "20px", marginTop: '10px' }}>
              <span>Remove Movers:</span>
              <select
                className={styles.commonInput}
                name={'removeMoversDay1'}
                value={formData.oos1day.removeMoversDay1}
                onChange={onValueChange}
                disabled={disabled}
              >
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>
            </label>

            {formData.commonValues.shuttle &&
              <div style={{ marginTop: '10px' }}>
                <label className={styles.shuttleOnDayOne} style={{ marginLeft: "20px", marginTop: '10px' }}>
                  Shuttle on Day 1
                  <input
                    type='checkbox'
                    name={'shuttleOnDay1'}
                    value={(formData.oos1day) ? formData.oos1day.shuttleOnDay1 : false}
                    checked={(formData.oos1day) ? formData.oos1day.shuttleOnDay1 : false}
                    onChange={checkboxValueChange}
                    disabled={disabled}
                  />
                </label>
                {(formData.department.extraOptions.shuttle.isCountable && formData.oos1day.shuttleOnDay1) &&
                  <label className={styles.inputContainer}>
                    Qty:
                    <input
                      type='number'
                      name={'quantityDay1'}
                      value={(formData.oos1day.quantityDay1)}
                      placeholder={'ex: 2'}
                      onChange={onValueChange}
                      onWheel={(e) => e.target.blur()}
                      disabled={disabled}
                    />
                  </label>
                }
              </div>
            }

          </fieldset>

          {(formData.department && formData.department.extraOptions && formData.department.extraOptions.charges.length) ?
            <>
              <hr className={styles.hLine} />
              <ExtraOptionsValues
                charges={(formData.department && formData.department.extraOptions) ? formData.department.extraOptions.charges : []}
                chargeCheckboxValueChange={chargeCheckboxValueChangeDayOne}
                selectedChargesNames={formData.oos1day.selectedChargesNamesDay1}
                lastTarget={lastTarget}
                chargeQuantityChange={chargeQuantityChangeDayOne}
                disabled={disabled}
              />
            </>
            : ""
          }
        </div>
      </div>


      <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", marginTop: '20px', width: '600px' }}>
        <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span>DAY - 2 (Unloading)</span>
            <img src={clockIcon} onClick={() => setShowInputsSecond(!showInputsSecond)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
          </div>
          {showInputsSecond && (
            <fieldset className={styles.groupInput}>
              <div className={styles.decorateContainer}>
                <AdjustTime
                  name={'addTimeSecondDay'}
                  value={formData.commonValues.addTimeSecondDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Add time:'}
                  disabled={disabled}
                />
                <AdjustTime
                  name={'removeTimeSecondDay'}
                  value={formData.commonValues.removeTimeSecondDay}
                  onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                  labelText={'Remove time:'}
                  disabled={disabled}
                />
              </div>
            </fieldset>
          )}
        </div>
        <div style={{ padding: '5px' }}>

          <fieldset className={styles.groupInput}>

            <label className={styles.commonLabel} >
              <span>Add Movers:</span>
              <select
                className={styles.commonInput}
                name={'addMoversDay2'}
                value={formData.oos1day.addMoversDay2}
                onChange={onValueChange}
                disabled={disabled}
              >
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>
            </label>

            <label className={styles.commonLabel} style={{ marginLeft: '20px' }}>
              <span>Remove Movers:</span>
              <select
                className={styles.commonInput}
                name={'removeMoversDay2'}
                value={formData.oos1day.removeMoversDay2}
                onChange={onValueChange}
                disabled={disabled}
              >
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>
            </label>

            {formData.commonValues.shuttle &&
              <div>
                <label className={styles.shuttleOnDayOne} style={{ marginLeft: '20px' }}>
                  Shuttle on Day 2
                  <input
                    type='checkbox'
                    name={'shuttleOnDay2'}
                    value={(formData.oos1day) ? formData.oos1day.shuttleOnDay2 : false}
                    checked={(formData.oos1day) ? formData.oos1day.shuttleOnDay2 : false}
                    onChange={checkboxValueChange}
                    disabled={disabled}
                  />
                </label>
                {(formData.department.extraOptions.shuttle.isCountable && formData.oos1day.shuttleOnDay2) &&
                  <label className={styles.inputContainer} style={{ marginTop: '20px' }}>
                    Qty:
                    <input
                      type='number'
                      name={'quantityDay2'}
                      value={(formData.oos1day.quantityDay2)}
                      placeholder={'ex: 2'}
                      onChange={onValueChange}
                      onWheel={(e) => e.target.blur()}
                      disabled={disabled}
                    />
                  </label>
                }
              </div>
            }
          </fieldset>
          {(formData.department && formData.department.extraOptions && formData.department.extraOptions.charges.length) ?
            <>
              <hr className={styles.hLine} />
              <ExtraOptionsValues
                charges={(formData.department && formData.department.extraOptions) ? formData.department.extraOptions.charges : []}
                chargeCheckboxValueChange={chargeCheckboxValueChangeDayTwo}
                selectedChargesNames={formData.oos1day.selectedChargesNamesDay2}
                lastTarget={lastTarget}
                chargeQuantityChange={chargeQuantityChangeDayTwo}
                disabled={disabled}
              />
              <hr className={styles.hLine} />
            </>
            : <hr className={styles.hLine} />
          }

          <MiscChechMarksValues
            miscCheckMarks={(formData.department && formData.department.miscCheckMarks && formData.department.miscCheckMarks.miscCheckMarks.length) ? formData.department.miscCheckMarks.miscCheckMarks : []}
            selectedMiscCheckMarks={formData.selectedMiscCheckMarks}
            checkMarkCheckboxValueChange={checkMarkCheckboxValueChange}
            disabled={disabled}
          />
        </div>
      </div>


      {(formData.commonValues.unpacking === 'Same Day') &&
        <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", marginTop: '20px', width: '600px' }}>
          <div style={{ background: '#e1e1e1', height: '30px', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
            <span>UNPACKING ON SAME DAY</span>
          </div>
          <div className={styles.unpSepCont} style={{ marginTop: '10px', padding: '5px' }}>

            <label className={styles.label}>
              Department
              <select
                className={styles.select}
                value={unpDepartment !== undefined ? unpDepartment._id : 'None'}
                onChange={onChangeUnpDepartmentSelect}
                onFocus={resetLastTarget}
                disabled={disabled}
              >
                <option value={undefined}>None</option>
                {
                  departments && departments.map((department) => {
                    return (
                      <option key={nanoid(departments.length)} value={department._id}>{department.departmentName}</option>
                    );
                  })
                }
              </select>
            </label>

            {unpRates.length === 0 && <p className={styles.warning}>Select Separate day Department!</p>}
            {unpRates.length > 0 &&
              <label style={{ marginLeft: "20px" }}>
                Rate:
                <select
                  className={styles.select}
                  value={formData.commonValues.unpackingRate}
                  onChange={(evt) => {
                    resetLastTarget();
                    formChange('commonValues.unpackingRate', 'set', evt.target.value);
                  }}
                  disabled={disabled}
                >
                  <option value={''} key='none'>None</option>
                  {
                    unpRates.map((rate, index) => {
                      return (
                        <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                      );
                    })
                  }
                </select>
              </label>

            }

            {formData.department && formData.department.truckFee && formData.department.truckFee.calcMethod === "different" &&
              <fieldset className={styles.groupInput}>
                <label className={styles.inputContainer} style={{ display: 'inline-block', marginLeft: '20px', marginTop: '10px' }}>
                  Truck fee:
                  <input
                    onWheel={(e) => e.target.blur()}
                    type='number'
                    name={'truckFeeSeparate'}
                    value={(formData._id) ? (formData.oos1day) ? formData.oos1day.truckFeeSeparate : '' : getTruckFeeMin(formData.department.truckFee.diffTruckReservations)}
                    placeholder={'ex: 40'}
                    onChange={onValueChange}
                    disabled={disabled}
                  />
                </label>
              </fieldset>
            }

          </div>
        </div>
      }

      {(formData.commonValues.unpacking === 'Separate Day') &&
        <div style={{ border: "1px solid #4db6ac", borderRadius: "10px", marginTop: '20px', width: '600px' }}>
          <div style={{ background: '#e1e1e1', borderTopLeftRadius: "10px", borderTopRightRadius: "10px", padding: '5px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>UNPACKING ON SEPARATE DAY</span>
              <img src={clockIcon} onClick={() => setShowInputsUnpacking(!showInputsUnpacking)} alt="Mail" className={styles.default} style={{ height: '20px' }} />
            </div>
            {showInputsUnpacking && (
              <fieldset className={styles.groupInput}>
                <div className={styles.decorateContainer}>
                  <AdjustTime
                    name={'addTimeUnp'}
                    value={formData.commonValues.addTimeUnp}
                    onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                    labelText={'Add time:'}
                    disabled={disabled}
                  />
                  <AdjustTime
                    name={'removeTimeUnp'}
                    value={formData.commonValues.removeTimeUnp}
                    onChange={(evt) => { formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value) }}
                    labelText={'Remove time:'}
                    disabled={disabled}
                  />
                </div>
              </fieldset>
            )}
          </div>
          <div className={styles.unpSepCont} style={{ marginTop: '10px', padding: '5px' }}>

            <label className={styles.label}>
              Department
              <select
                className={styles.select}
                value={unpDepartment !== undefined ? unpDepartment._id : 'None'}
                onChange={onChangeUnpDepartmentSelect}
                onFocus={resetLastTarget}
                disabled={disabled}
              >
                <option value={undefined}>None</option>
                {
                  departments.map((department) => {
                    return (
                      <option key={nanoid(departments.length)} value={department._id}>{department.departmentName}</option>
                    );
                  })
                }
              </select>
            </label>

            {unpRates.length === 0 && <p className={styles.warning}>Select Separate day Department!</p>}
            {unpRates.length > 0 &&
              <label style={{ marginLeft: "20px" }}>
                Rate:
                <select
                  className={styles.select}
                  value={formData.commonValues.unpackingRate}
                  onChange={(evt) => {
                    resetLastTarget();
                    formChange('commonValues.unpackingRate', 'set', evt.target.value);
                  }}
                  disabled={disabled}
                >
                  <option value={''} key='none'>None</option>
                  {
                    unpRates.map((rate, index) => {
                      return (
                        <option key={nanoid(rates.length)} value={rate[`rateName${index}`]}>{rate[`rateName${index}`]}</option>
                      );
                    })
                  }
                </select>
              </label>
            }

            <label className={styles.checkLabel} style={{ margin: '0', marginTop: '10px', marginLeft: '20px' }} >
              <span >Movers to Unpack:</span>
              <select
                className={styles.commonInput}
                name={'moversToUnPackSeparate'}
                value={(formData.commonValues.moversToUnPackSeparate) ? formData.commonValues.moversToUnPackSeparate : ''}
                onChange={(evt) => {
                  resetLastTarget();
                  formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
                }}
                disabled={disabled}
              >
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
              </select>
            </label>


            {formData.commonValues.shuttle &&
              <div style={{ display: 'flex' }} className={styles.groupInput}>
                <label className={styles.shuttleOnDayOne} style={{ marginLeft: "0px", display: 'block', marginTop: '10px' }}>
                  Shuttle on Unpacking Day
                  <input
                    type='checkbox'
                    name={'shuttleOnUnpacking'}
                    value={(formData.commonValues.shuttleOnUnpacking) ? formData.commonValues.shuttleOnUnpacking : false}
                    checked={(formData.commonValues.shuttleOnUnpacking) ? formData.commonValues.shuttleOnUnpacking : false}
                    onChange={sepCheckboxValueChange}
                    disabled={disabled}
                  />
                </label>
                {formData.commonValues.shuttleOnUnpacking &&
                  <label className={styles.inputContainer} style={{ marginTop: '10px' }}>
                    Qty:
                    <input
                      type='number'
                      name={'quantityUnpacking'}
                      value={formData.commonValues.quantityUnpacking}
                      placeholder={'ex: 2'}
                      onChange={(evt) => {
                        resetLastTarget();
                        formChange(`commonValues.${evt.target.name}`, 'set', evt.target.value)
                      }}
                      onWheel={(e) => e.target.blur()}
                      disabled={disabled}
                    />
                  </label>
                }
              </div>
            }

            {formData.department && formData.department.truckFee && formData.department.truckFee.calcMethod === "different" &&
              <fieldset className={styles.groupInput}>
                <label className={styles.inputContainer} style={{ display: 'inline-block', marginLeft: '20px', marginTop: '10px' }}>
                  Truck fee:
                  <input
                    onWheel={(e) => e.target.blur()}
                    type='number'
                    name={'truckFeeSeparate'}
                    value={(formData._id) ? (formData.oos1day) ? formData.oos1day.truckFeeSeparate : '' : getTruckFeeMin(formData.department.truckFee.diffTruckReservations)}
                    placeholder={'ex: 40'}
                    onChange={onValueChange}
                    disabled={disabled}
                  />
                </label>
              </fieldset>
            }
          </div>
        </div>
      }
    </section>
  );
};