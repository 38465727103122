export const companyDataModel = {
  companyName: '',
  version: 0,
  disabled: false,
  extraOptCharges: [{ 'id0': "" }],
  additProcedures: [{ 'id0': "" }],
  miscCheckMarks: [{ 'id0': "" }],
  additionalServices: {},
  isLongDistanceDisabled: false,
  isOOSDisabled: false,
  departmentRateType: 'calculated',//calculated, custom
  departmentCalcMethod: 'discount',//discount, deduct, 
  longDistanceDirectionType: 'expences',//expences, roomDistance
  longDistanceCalcMethod: 'discount',//discount, deduct
  OOSCalcMethod: 'discount',//discount, deduct
  furnitureId: null,
  roomsAreasId: null,
};
