// Third party libraries
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { nanoid } from 'nanoid';
// Components
import { ErrorMessage } from '../error-msg/Error-msg';
// State functions
import { getCompany } from '../../clientStore/companySlice/company-slice';
import { getCompanies } from '../../clientStore/companiesSlice/companies-slice'
import {
  loadCompany,
} from '../../clientStore/companySlice/company-async-thunk';
import { loadCompanies } from '../../clientStore/companiesSlice/companies-async-thunk';
import { getCompanyId, getUserCreatePermission, getUserRole } from '../../clientStore/authSlice/auth-slice';
import { setUsersLoadingProcess } from '../../clientStore/usersSlice/user-slice';
// Util functions
import { onChangeFormValue, fetchedData } from '../../util/utils';
import { HttpCode, Role } from '../../util/const';
// Styles
import styles from './CreateUserForm.module.css';


export const CreateUserForm = ({ login = '', role = 'moderator', allowedCreateUser = true, disabled = false }) => {
  const [isFetchError, setFetchError] = useState({
    isError: false,
    message: '',
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUserRole = useSelector(getUserRole);
  const allowCreateUser = useSelector(getUserCreatePermission);
  const companyId = useSelector(getCompanyId);
  const companies = useSelector(getCompanies);

  useEffect(() => {
    dispatch(loadCompany(companyId));
    dispatch(loadCompanies());
  }, [dispatch, companyId]);

  const companyName = useSelector(getCompany).companyName;

  const [validateUserForm, setValidateUserForm] = useState({
    loginValidate: 'idle',
    passwordValidate: 'idle',
  });

  const [user, setUserForm] = useState({
    login,
    password: '',
    role,
    allowedCreateUser,
    newCompanyName: '',
    disabled,
    companyId
  });


  const renderNotDisabledCompaniesOptions = (companies) => {
    if (companies.length > 0) {
      return (
        companies.map((company) => {
          return (
            <option value={company._id} key={nanoid(companies.length)}>
              {company.companyName}
            </option>
          );
        })
      );
    }
    return ('');
  };

  const onSaveUserBtnClick = async (evt, userData) => {
    evt.preventDefault();

    if (!user.login || !user.password) {
      setFetchError({
        isError: true,
        message: 'Please input all fields'
      });
    }

    if (user.role === Role.COMPANYUSER && !user.newCompanyName) {
      setFetchError({
        isError: true,
        message: 'Please input all fields'
      });
    }
    if (!isFetchError.isError) {
      const savedUser = await fetchedData('user/registration', 'POST', { ...userData });

      switch (savedUser.status) {
        case HttpCode.FAILED:
          setFetchError({
            isError: true,
            message: savedUser.data.status,
          });
          break;
        case HttpCode.OK:
          setUserForm({
            login: '',
            password: '',
            role: 'moderator',
            allowedCreateUser: false,
            companyId
          });
          dispatch(setUsersLoadingProcess({ status: 'idle' }))
          history.replace('/users');
          break;
        default:
          setFetchError({
            isError: true,
            message: 'Not good thing happenes. Sorry',
          });
          break;
      }
    }
  };

  return (
    (!allowCreateUser) ? <h2 className={styles.title}>You are not allowed to create users</h2> :
      <form className={styles.form}>
        <h2 className={styles.title}>Create new user</h2>
        <label className='visually-hidden' htmlFor='user_name'>
          User name
        </label>
        <input
          type='text'
          id='user_name'
          className={validateUserForm.loginValidate === 'error' ? styles.errorField : styles.field}
          name='login'
          value={user.login}
          placeholder={validateUserForm.loginValidate === 'error' ? 'Please enter login' : 'User login'}
          onChange={(evt) => {
            onChangeFormValue(evt, user, setUserForm); setFetchError({
              isError: false,
              message: ''
            });
          }}
          onBlur={() => {
            if (!user.login) {
              setValidateUserForm({ ...validateUserForm, loginValidate: 'error' });
            } else {
              setValidateUserForm({ ...validateUserForm, loginValidate: 'success' });
            }
          }}
          autoComplete="off"
          required
        />
        <label className='visually-hidden' htmlFor='user_password'>
          User password
        </label>
        <input
          type='password'
          id='user_password'
          className={validateUserForm.passwordValidate === 'error' ? styles.errorField : styles.field}
          name='password'
          value={user.password}
          placeholder={validateUserForm.passwordValidate === 'error' ? 'Please enter password' : 'Password'}
          onChange={(evt) => onChangeFormValue(evt, user, setUserForm)}
          onBlur={() => {
            if (!user.password) {
              setValidateUserForm({ ...validateUserForm, passwordValidate: 'error' });
            } else {
              setValidateUserForm({ ...validateUserForm, passwordValidate: 'success' });
            }
          }}
          required
        />
        <label className="label">
          <span className={styles.labelTxt}>Choose user role</span>
          <select
            className={styles.select}
            value={user.role}
            name='role'
            required
            onChange={(evt) => onChangeFormValue(evt, user, setUserForm)}
          >
            {currentUserRole === Role.SUPERUSER && <option>{Role.COMPANYUSER}</option>}
            {((currentUserRole === Role.SUPERUSER) || (currentUserRole === Role.COMPANYUSER)) && <option>{Role.ADMIN}</option>}
            <option>{Role.MODERATOR}</option>
            <option value='viewerplus'>Viewer Plus</option>
            <option value='viewer'>Viewer</option>
          </select>
        </label>
        {(currentUserRole === Role.SUPERUSER && user.role === Role.ADMIN) &&
          <div>
            <label>
              <input type='checkbox'
                checked={user.allowedCreateUser}
                name='allowedCreateUser'
                onChange={(evt) => onChangeFormValue({ target: { value: evt.target.checked, name: evt.target.name } }, user, setUserForm)}
              />
              Allow create second level user
            </label>
          </div>
        }

        {(currentUserRole === Role.SUPERUSER && user.role !== Role.COMPANYUSER) ?
          <label className="label">
            <span className={styles.labelTxt}>Choose company</span>
            <select
              className={styles.select}
              value={user.companyId}
              name='companyId'
              required
              onChange={(evt) => onChangeFormValue(evt, user, setUserForm)}
            >
              {renderNotDisabledCompaniesOptions(companies)}
            </select>
          </label>
          :
          (user.role === Role.COMPANYUSER) ?
            <label htmlFor="new_company_select" className={styles.label}>
              <span className={styles.labelTxt}>Enter new company name</span>
              <input
                id='new_company_select'
                className={styles.field}
                type='text'
                name='newCompanyName'
                value={user.newCompanyName}
                required
                onChange={(evt) => onChangeFormValue(evt, user, setUserForm)}
              />
            </label>
            :
            <label htmlFor="company_input" className={styles.label}>
              <span className={styles.labelTxt}>Company</span>
              <input
                id='company_input'
                className={styles.select}
                type='text'
                name='company'
                defaultValue={companyName}
                disabled
                required
              />
            </label>
        }
        {isFetchError.isError && <ErrorMessage errMessage={isFetchError.message} />}
        <button
          className={styles.btnSubmit}
          onClick={(evt) => onSaveUserBtnClick(evt, user)}
          disabled={!user.login || !user.password}
        >
          Save
        </button>
      </form>
  );
};
